import React, { Component } from "react";

//import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
// import ListImg from "../assets/listimage.jpg";
// import ListImg1 from "../assets/listimage-1.png";

import { calculateAge } from "../common/util";
import SelectDropDown from "../common/SelectDropDown/SelectDropDown";
import KeyboardDatePickerExample from "../common/DatePicker/KeyboardDatePickerExample";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MaskedInput from "react-text-mask";
import { CurrencyInputSymbol } from "../common/CurrencyInputSymbol/CurrencyInputSymbol";
import Link from '@material-ui/core/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

export class BorrowerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetList: [],
      page: 0,
      rowsPerPage: 10,
      subHeaderDetails: ["Welcome"],
      checkedA: true,
      checkedB: true,
      isSelect: 1,
      borrowerAge: 0,
    };
  }

  handleChange = (event) => {
      this.props.handleBorrowerChange(event, this.props.info);
  };

  handleDateChangeInForm(date,fieldName) {
    //let year = date != null ? new Date(date).getFullYear() : "";
    let age = date != null  ? calculateAge(date) : "";
    if(date!=='NaN-NaN-NaN'){
      this.setState({
        borrowerAge: age,
      });
    }
    this.props.handleDateInForm(date, this.props.info,fieldName,age);
  }

  onQuestionhandleChange = (event) => {
    this.setState({ isSelect: event.target.value });
    console.log(event.target.value);
  };

  displayBorrower = () => {
    this.props.displayBorrower2();
  };

  displayDeleteButton = () => {
    this.props.displayDeleteAction();
  };

  setSelectedValue = (e, text) => {
    if (text === "martialType") {
      let marId = e.target.value;
      let selectedRecord = this.props.martialStatusList.find(
        (x) => x.Id === marId
      );
      this.props.handleDateInForm(selectedRecord, this.props.info,text,''); 
    }
  };

  render() {
    const { firstName, middleName, lastName, email, dateOfBirth, homePhoneNumber, cellPhone, ssn, employer, grossMonthlyIncome, maritalStatus} = this.props.borrowerInfo;
    return (
      
      <ValidatorForm ref="form" onSubmit={()=>{}}>
      {/* <Fragment> */}
        <div>
          <Grid fullWidth container>
            <div className="detail-section loan-pg">
              <div class="heading-section">
                <div class="heading-title">
                  <Typography variant="h5" gutterBottom>
                    {this.props.heading}
                  </Typography>
                  {this.props.displayBorrowerButton && !this.props.isEditMode ? (
                    <div>
                      <Fab size="small" color="primary" aria-label="add"  disabled={this.props.isEditMode}>
                        <AddIcon onClick={this.displayBorrower}/>
                      </Fab>
                      <Link
                        disabled={this.props.isEditMode}
                        component="button"
                        variant="body2"
                        onClick={this.displayBorrower}
                      >
                        Add Co-Borrower
                      </Link>
                    </div>
                  ) : this.props.displayDeleteButton && !this.props.isEditMode ? (
                    <div>
                      <Tooltip title="Delete" arrow>
                      <Fab size="small" color="primary" aria-label="delete" disabled={this.props.isEditMode}>
                        <DeleteIcon disabled={this.props.isEditMode} onClick={this.displayDeleteButton} />
                      </Fab>
                      </Tooltip>
                    </div>
                  ) : null}
                </div>
              </div>
              <Grid fullWidth container class="loan-pg-sec">
                <Paper fullWidth elevation={2}>
                  <Grid container spacing={8}>
                    <Grid item xs={4}>
                      <TextField
                        id="firstName"
                        value={this.props.isEditMode?this.props.editBorrowerInfo.firstName:firstName}
                        onChange={(e) => this.handleChange(e,'firstName')}
                        label="First Name"
                        variant="standard"
                        fullWidth
                        required={this.props.info==="borrower"}
                        disabled={ 
                          (this.props.info==="borrower" && this.props.isEditMode) || 
                          (this.props.info==="coborrower" && this.props.isEditMode && (firstName!=='' && firstName!==null))
                        }
                      />
                      {!((this.props.info==="coborrower" || !this.props.isFormTouched) || (firstName !== "" && this.props.isFormTouched)) ? <Grid item xs={12}>
                      <Typography gutterBottom>
                        <span className="redTxt">First name is required</span>
                      </Typography>
                    </Grid> : null}
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="middleName"
                        value={this.props.isEditMode?this.props.editBorrowerInfo.middleName:middleName}
                        onChange={(e) => this.handleChange(e,'middleName')}
                        fullWidth
                        label="Middle Name"
                        variant="standard"
                        disabled={this.props.isEditMode && (middleName!=='' && middleName!==null)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="lastName"
                        value={this.props.isEditMode?this.props.editBorrowerInfo.lastName:lastName}
                        onChange={this.handleChange}
                        fullWidth
                        required={this.props.info==="borrower"}
                        label="Last Name"
                        variant="standard"
                        disabled={
                          (this.props.info==="borrower" && this.props.isEditMode) || 
                          (this.props.info==="coborrower" && this.props.isEditMode && (lastName!=='' && lastName!==null))
                        }
                      />
                      {!((this.props.info==="coborrower" || !this.props.isFormTouched) || (lastName !== "" && this.props.isFormTouched)) ? <Grid item xs={12}>
                      <Typography gutterBottom>
                        <span className="redTxt">Last name is required</span>
                      </Typography>
                    </Grid> : null}
                    </Grid>
                  </Grid>
                  <Grid container spacing={8}>
                    <Grid item xs={4}>
                    <TextValidator
                        id="email"
                        label="Email"
                        type="email"
                        value={this.props.isEditMode?this.props.editBorrowerInfo.email:email}
                        onChange={(e) => this.handleChange(e)}
                        validators={['isEmail']}
                        errorMessages={['Email is not valid']}
                        variant="standard"
                        fullWidth
                        required={this.props.info==="borrower"}
                        disabled={
                          (this.props.info==="borrower" && this.props.isEditMode) || 
                          (this.props.info==="coborrower" && this.props.isEditMode && (email!=='' && email!==null))
                        }
                    />

                      {/* <TextField
                        fullWidth
                        required={this.props.info==="borrower"}
                        id="email"
                        value={this.props.isEditMode?this.props.editBorrowerInfo.email:email}
                        onChange={this.handleChange}
                        label="Email"
                        variant="standard"
                        disabled={
                          (this.props.info==="borrower" && this.props.isEditMode) || 
                          (this.props.info==="coborrower" && this.props.isEditMode && (email!=='' && email!==null))
                        }
                      /> */}
                      {!((this.props.info==="coborrower" || !this.props.isFormTouched) || (email !== "" && this.props.isFormTouched)) ? <Grid item xs={12}>
                      <Typography gutterBottom>
                        <span className="redTxt">Email is required</span>
                      </Typography>
                    </Grid> : null}
                    </Grid>
                    <Grid item xs={4}>
                      <KeyboardDatePickerExample
                        label="DOB"
                        info={this.props.info}
                        selectedDate={this.props.isEditMode?this.props.editBorrowerInfo.dateOfBirth:dateOfBirth}
                        //selectedDate={dateOfBirth!==null?dateOfBirth:null} 
                        requireValidation={true}
                        disableFuture={true}
                        disabledDate={
                          (this.props.info==="borrower" && this.props.isEditMode) || 
                          (this.props.info==="coborrower" && this.props.isEditMode && dateOfBirth!==null)
                        }
                        handleDateChangeInForm={(date) =>
                          this.handleDateChangeInForm(date,"dob")
                        }
                      />
                      {!((this.props.info==="coborrower" || !this.props.isFormTouched) || (this.props.borrowerInfo.dateOfBirth !== null && this.props.isFormTouched)) ? <Grid item xs={12}>
                          <Typography gutterBottom>
                            <span className="redTxt">Date of Birth is required</span>
                          </Typography>
                      </Grid> : null}
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        fullWidth
                        label="Age"
                        className="no-bottom-line"
                        variant="standard"
                        value={
                          this.props.isEditMode && this.props.editBorrowerInfo.age!=='NaN'? this.props.editBorrowerInfo.age : 
                          this.state.borrowerAge!=='NaN'?this.state.borrowerAge:0
                        }
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      {
                        this.props.isEditMode && this.props.info==="borrower" ?
                        <TextField
                          fullWidth
                          label="Marital Status"
                          className="no-bottom-line"
                          variant="standard"
                          value={maritalStatus}
                          disabled={true}
                        /> :
                        <SelectDropDown
                          id="maritalStatusId"
                          label="Marital Status"
                          dropDownDataList={this.props.martialStatusList}
                          fieldName="maritalStatusId"
                          isRequired={(this.props.info==="borrower")?true:false}
                          initialValue={''}
                          component="borrowerDetail"
                          setSelectedValue={(e) =>
                            this.setSelectedValue(e, "martialType")
                          }
                        />
                      }
                      {!((this.props.info==="coborrower" || !this.props.isFormTouched) || (this.props.borrowerInfo.maritalStatusId !== 0 && this.props.isFormTouched)) ? <Grid item xs={12}>
                          <Typography gutterBottom>
                            <span className="redTxt">Marital Status is required</span>
                          </Typography>
                      </Grid> : null}
                    </Grid>
                  </Grid>
                  <Grid container spacing={8}>
                    <Grid item xs={4}>
                        <InputLabel htmlFor="homePhoneNumber">
                            Home Phone Number
                            {
                              (this.props.info==="borrower") ? <span className="redTxt"> *</span> : null
                            }
                        </InputLabel>
                        <Input
                            id="homePhoneNumber"
                            fullWidth
                            value={this.props.isEditMode?this.props.editBorrowerInfo.homePhoneNumber:homePhoneNumber}
                            onChange={(e) => this.handleChange(e,'homePhoneNumber')}
                            inputComponent={ContactNumberMask}
                            required={this.props.info==="borrower"}
                            disabled={
                              (this.props.info==="borrower" && this.props.isEditMode) || 
                              (this.props.info==="coborrower" && this.props.isEditMode && (homePhoneNumber!=='' && homePhoneNumber!==null))
                            }
                        />
                        {!((this.props.info==="coborrower" || !this.props.isFormTouched) || (homePhoneNumber !== "" && this.props.isFormTouched)) ? <Grid item xs={12}>
                          <Typography gutterBottom>
                            <span className="redTxt">Home Phone is required</span>
                          </Typography>
                        </Grid> : null}
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel htmlFor="cellPhone">
                            Cell Phone Number
                            {
                              (this.props.info==="borrower") ? <span className="redTxt"> *</span> : null
                            }
                        </InputLabel>
                        <Input
                            fullWidth
                            id="cellPhone"
                            value={this.props.isEditMode?this.props.editBorrowerInfo.cellPhone:cellPhone}
                            onChange={(e) => this.handleChange(e,'cellPhone')}
                            inputComponent={ContactNumberMask}
                            required={this.props.info==="borrower"}
                            disabled={
                              (this.props.info==="borrower" && this.props.isEditMode) || 
                              (this.props.info==="coborrower" && this.props.isEditMode && (cellPhone!=='' && cellPhone!==null))
                            }
                        />
                        {!((this.props.info==="coborrower" || !this.props.isFormTouched) || (cellPhone !== "" && this.props.isFormTouched)) ? <Grid item xs={12}>
                          <Typography gutterBottom>
                            <span className="redTxt">Cell Phone is required</span>
                          </Typography>
                        </Grid> : null}
                    </Grid>
                    <Grid item xs={4}>
                      <InputLabel htmlFor="ssn">Social Security No.</InputLabel>
                      <Input
                        id="ssn"
                        fullWidth
                        value={this.props.isEditMode?this.props.editBorrowerInfo.ssn:ssn}
                        onChange={(e) => this.handleChange(e,'ssn')}
                        inputComponent={SSNMaskCustom}
                        disabled={this.props.isEditMode &&  ssn!==null && ssn!==''}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={8}>
                    <Grid item xs={4}>
                        <TextField 
                            fullWidth
                            id="grossMonthlyIncome" 
                            label="Gross Monthly Income"
                            value={this.props.isEditMode?this.props.editBorrowerInfo.grossMonthlyIncome:grossMonthlyIncome}
                            onChange={(e) => this.handleChange(e,'grossMonthlyIncome')}
                            inputProps={{ maxLength: 40 }} 
                            InputProps={{ inputComponent: CurrencyInputSymbol }}
                            disabled={this.props.isEditMode && grossMonthlyIncome!==null}
                        />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        label="Employer"
                        variant="standard"
                        id="employer"
                        value={this.props.isEditMode?this.props.editBorrowerInfo.employer:employer}
                        onChange={(e) => this.handleChange(e,'employer')}
                        disabled={this.props.isEditMode && employer!==null && employer!==''}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </div>
          </Grid>
        </div>
        </ValidatorForm>
      

    );
  }
}

function SSNMaskCustom(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[1-9]/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]} //"123-22-3235"
      placeholderChar={"_"}
    />
  );
}

function ContactNumberMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]} //"02-2323234"
      placeholderChar={"_"}
    />
  );
}

export default BorrowerDetails;
