import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Dropzone from "react-dropzone";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Grid from "@material-ui/core/Grid";
import {
  isValidFile,
  createFileFromUrl
} from "./helpers";
import SnackbarContentWrapper from "./SnackbarContentWrapper";
import UploadedPreviewList from "./UploadedPreviewList";
import classNames from "classnames";
//import UploadLoader from './UploadLoader';
import _ from 'lodash';
import { documentAPIName} from "../../common/util";
import axios from 'axios';
import FileSaver from "file-saver";
import DeleteDialogs from './DeleteDialogs';

const styles = {
  "@keyframes progress": {
    "0%": {
      backgroundPosition: "0 0"
    },
    "100%": {
      backgroundPosition: "-70px 0"
    }
  },
  dropZone: {
    position: "relative",
    width: "100%",
    minHeight: "36px",
    height: "36px",
    margin: "10px 0",
    backgroundColor: "#F9F6F5",
    border: "1px solid #815739",
    borderRadius: 4,
    cursor: "pointer",
    boxSizing: "border-box",
    display: "flex"
  },
  stripes: {
    border: "2px solid #DBDBDB",
    backgroundImage:
      "repeating-linear-gradient(-45deg, #F0F0F0, #F0F0F0 25px, #C8C8C8 25px, #C8C8C8 50px)",
    animation: "progress 2s linear infinite !important",
    backgroundSize: "150% 100%"
  },
  rejectStripes: {
    border: "2px solid #DBDBDB",
    backgroundImage:
      "repeating-linear-gradient(-45deg, #fc8785, #fc8785 25px, #f4231f 25px, #f4231f 50px)",
    animation: "progress 2s linear infinite !important",
    backgroundSize: "150% 100%"
  },
  dropzoneTextStyle: {
    textAlign: "center",
    fontSize: 12,
    color: "#815739",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row"
  },
  uploadIconSize: {
    width: 20,
    height: 20,
    color: "#815739",
    transform: "rotate(45deg)"
  },
  dropzoneParagraph: {
    fontSize: 13,
    margin: 0,
    padding: "0 10px",
    lineHeight: 1.2,
    fontWeight: "600"
  }
};

let isAttachingFileFlag = false;
let modifiedFileName = '';
class UploadzoneArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileObjects: [],
      files: [],
      openSnackBar: false,
      snackbarMessage: "",
      snackbarVariant: "success",
      dropzoneText: props.dropzoneText,
      downLoadFile: '',
      fileNme: '',
      //getDocumentLoader : false,
      imageDetails: this.props.isEditMode?this.props.loanDocumentList:[],
      shouldDeleteImage: false,
      uploadFileToDelete: {},
      fileIndex: 0
    };
    this.filesUrl = '';
    this.fileName = '';
  }

  async filesArray(urls) {
    try {
      let url = null;
      for (url of urls) {
        const file = await createFileFromUrl(url);
        const reader = new FileReader();
        // reader.onload = event => {
        //   this.setState({
        //     fileObjects: this.state.fileObjects.concat({
        //       file: file,
        //       data: event.target.result
        //     })
        //   });
        // };
        reader.readAsDataURL(file);
      }
    } catch (e) {
      console.log(e);
    }
  }

  componentDidMount() {
    this.filesArray(this.props.initialFiles);
      if (this.props.file && this.props.file.length > 0 && this.props.fileId && this.props.fileId.length > 0) {
          let filesArray = [];
          this.props.file && this.props.file.map(item =>{
            filesArray.push({file : item });
            return item;
          })
          this.setState({
              fileObjects: filesArray,
              downLoadFile: this.props.fileId
          }, () =>{
            if(!isAttachingFileFlag && this.props.file && this.props.file.length > 0)
            {
               this.props.getFilesCallback(this.state.fileObjects);
            }
          });
      }
  }

  componentWillUnmount() {
    if (this.props.clearOnUnmount) {
      this.setState({
        fileObjects: []
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.dropzoneText !== prevProps.dropzoneText) {
      this.setState({
        dropzoneText: this.props.dropzoneText
      });
    }
    //for edit mode
    if(this.props.loanDocumentList !== prevProps.loanDocumentList){
      this.props.fetchUploadDetails(this.props.loanId).then(responseData => {
        let uploadedDocumentList=responseData.data.documents.map(obj=> ({ ...obj, alreadyUploadedDocument: true }))
        this.setState({
            fileObjects:uploadedDocumentList,
            imageDetails: uploadedDocumentList
        });
      });
    }
  }

  onDrop(files) {
    //this.setState({ getDocumentLoader : true});
    this.props.loaderCallBackFunc(true);
    this.props.enableUploadButton(true);
    isAttachingFileFlag = true;
    files = files && files.filter(file => isValidFile(file));
    if(files.length === 0) {
      //this.setState({ getDocumentLoader : false});
      this.props.loaderCallBackFunc(false);
      this.setState({
        openSnackBar: true,
        snackbarMessage: this.props.getDropRejectMessage(),
        snackbarVariant: "error"
      });
    }
    let totSize = 0;
    files.forEach(ele => {
      totSize = totSize + ele.size;
    })
    if (totSize > 200000000) {
      this.setState({
        openSnackBar: true,
        snackbarMessage: this.props.getFileLimitExceedMessage(
          'Total size greater than 200MB'
        ),
        snackbarVariant: "error"
      });
    } else {
      let count = 0;
      let message = "";
      let uploadedFilesLength = files.length;
      files.forEach(file => {
       this.props.isFileUploadedFun(true);
        const reader = new FileReader();
        reader.onload = event => {
          modifiedFileName = documentAPIName(file.name);
           this.props.getFilesCallback(
            this.state.fileObjects.concat({
              file: file,
              fileName : modifiedFileName,
              data: event.target.result,
            })
           );
          this.setState({
            fileObjects: this.state.fileObjects.concat({
              file: file,
              fileName : modifiedFileName,
              data: event.target.result
            })
          }, () => {
            if (this.props.onChange) {
              this.props.onChange(
                this.state.fileObjects.map(fileObject => fileObject.file)
              );
            }
            if (this.props.onDrop) {
              this.props.onDrop(file);
            }
            const { fetchUploadDocumentUrl } = this.props;
            let reqJson = {
              "loanId": this.props.loanId,
              "document": [
                {
                  "uploadedFileName": modifiedFileName,
                  "uploadType": "document"
                }
              ]
            }
            fetchUploadDocumentUrl(reqJson)
              .then(async res => {
                const url = res.data[0].url;
                const nextUploadFile = file;
                this.props.updateUploadedFiles(url, file);
                //upload to s3
                try {
                  const response = await fetch(url, {
                    method: 'PUT',
                    body: nextUploadFile,
                    headers: new Headers({
                      'Content-Type': ''
                    })
                  })
                  if (response.status === 200) {
                      message += this.props.getFileAddedMessage(file.name);
                      count ++;
                      if (count === files.length) {
                        this.setState({
                            openSnackBar: true,
                            snackbarMessage: message,
                            snackbarVariant: 'success'
                        });
                      }
                      if(count === uploadedFilesLength){
                        isAttachingFileFlag = false;
                        this.props.enableUploadButton(false);
                        //this.setState({ getDocumentLoader : false});
                        this.props.loaderCallBackFunc(false);
                        this.props.setSnackbar('success', 'Files uploaded Successfully!');

                        this.props.fetchUploadDetails(this.props.loanId).then(async responseData => {
                          let results1 = responseData.data.documents.filter(({ fileName: id1 }) => !this.props.loanDocumentList.some(({ fileName: id2 }) => id2 === id1));
                          let results2 = responseData.data.documents.filter(({ fileName: id1 }) => this.props.loanDocumentList.some(({ fileName: id2 }) => id2 === id1));
                          let setFlagInList1=results1.map(obj=> ({ ...obj, alreadyUploadedDocument: false }))
                          let setFlagInList2=results2.map(obj=> ({ ...obj, alreadyUploadedDocument: true }))
                          let updatedUploadedDocumentList=[...setFlagInList1,...setFlagInList2]
                          this.setState({
                              imageDetails: updatedUploadedDocumentList
                          });
                      });

                      }
                  }
                } catch (e) {
                  this.props.setSnackbar('error', `${modifiedFileName} upload failed!`)
                }
              });
            
          }
          );
        };
        reader.readAsDataURL(file);
      });
    }
  }

  handleRemove = (fileIndex, fileToDelete) => event => {
    event.stopPropagation();
    //this.setState({ getDocumentLoader : false});
    //this.props.loaderCallBackFunc(false);
    this.setState({ shouldDeleteImage: true, fileIndex: fileIndex, uploadFileToDelete: fileToDelete, message: "Do you want to delete this file ?" });
    return true;
  };

  triggerCommonChange = (fileIndex) => {
    //this.props.loaderCallBackFunc(false);
    const { fileObjects } = this.state;
    const file = fileObjects.filter((fileObject, i) => {
      return i === fileIndex;
    })[0].file;
    fileObjects.splice(fileIndex, 1);
    this.setState(fileObjects, () => {
      if (this.props.onDelete) {
        this.props.onDelete(file);
      }
      if (this.props.onChange) {
        this.props.onChange(
          this.state.fileObjects.map(fileObject => fileObject.file)
        );
      }
      //let fileNames = documentUIName(file.name);
      // this.props.setSnackbar('success', 'File deleted successfully!');
      // this.setState({
      //   openSnackBar: true,
      //   snackbarMessage: 'File deleted successfully!',
      //   snackbarVariant: "success"
      // });
      this.props.getFilesCallback(this.state.fileObjects);
      // this.props.getDocuments();
      this.props.isFileUploadedFun(false);
    });
  }

  handleDropRejected(rejectedFiles, evt) {
    var message = "";
    rejectedFiles.forEach(rejectedFile => {
      message = this.props.getDropRejectMessage(
        rejectedFile,
        this.props.acceptedFiles,
        this.props.maxFileSize
      );
    });
    if (this.props.onDropRejected) {
      this.props.onDropRejected(rejectedFiles, evt);
    }
    this.setState({
      openSnackBar: true,
      snackbarMessage: message,
      snackbarVariant: "error"
    });
    return true;
  }

  onCloseSnackbar = () => {
    this.setState({
      openSnackBar: false
    });
  };

  downloadFile = (file) => {
    this.props.loaderCallBackFunc(true);
    axios.get(file.documentUrl, {
      responseType: 'blob',
    }).then(response => {
      if(response && response.data) {
        this.props.loaderCallBackFunc(false); 
        FileSaver.saveAs(response.data, file.fileName);
      }
    });
    return true;
  }

  callbackDeleteDialog = () => {
    //this.setState({ getDocumentLoader : true});
    this.props.loaderCallBackFunc(true);
    let fileToDelete = this.state.uploadFileToDelete;
    let reqJson = {
      "loanId": this.props.loanId,
      "document": [
        {
          "uploadedFileName": fileToDelete.fileName,
          "uploadType": "document"
        }
      ]
    }
    
    //this.props.loaderCallBackFunc(true);
    this.loanId = window.location.pathname.split("/")[
      window.location.pathname.split("/").length - 1
    ];
    // let objectToDelete = {
    //   'uploadId': '',
    //   "fileName": "",
    //   "userId": ""
    // }
    // let userId = JSON.parse(sessionStorage.getItem('currentUser')) ?
    //   JSON.parse(sessionStorage.getItem('currentUser')).userId : '';
    // if (this.props.editableMode) {
    //   this.props.uploadFiles && this.props.uploadFiles.length > 0 &&
    //     this.props.uploadFiles.map(elem => {
    //       if (elem.fileName === fileToDelete.file.name) {
    //         objectToDelete['uploadId'] = elem.uploadId;
    //         objectToDelete['fileName'] = fileToDelete.file.name;
    //         objectToDelete['userId'] = userId;
    //       }
    //       return elem;
    //     })
    // }
    // if (objectToDelete.fileName !== '' && objectToDelete.uploadId !== '') {
    //   // documentFiles.push(objectToDelete);
        this.props.deleteUploadedDocument(reqJson).then( res => {
          this.props.fetchUploadDetails(this.props.loanId).then(async responseData => {
            let results1 = responseData.data.documents.filter(({ fileName: id1 }) => !this.props.loanDocumentList.some(({ fileName: id2 }) => id2 === id1));
            let results2 = responseData.data.documents.filter(({ fileName: id1 }) => !this.props.loanDocumentList.some(({ fileName: id2 }) => id2 !== id1));
            let setFlagInList1=results1.map(obj=> ({ ...obj, alreadyUploadedDocument: false }))
            let setFlagInList2=results2.map(obj=> ({ ...obj, alreadyUploadedDocument: true }))
            let updatedUploadedDocumentList=[...setFlagInList1,...setFlagInList2];
            this.setState({
              imageDetails: updatedUploadedDocumentList,
              openSnackBar: true,
              snackbarMessage: 'File deleted successfully!',
              snackbarVariant: "success"
            });
            this.props.loaderCallBackFunc(false);
          });
          //this.setState({ getDocumentLoader : false});
        });
    //   } 
      //event.stopPropagation();
       this.triggerCommonChange(this.state.fileIndex);
    this.setState({ shouldDeleteImage: false })
  }

  handleNoCallBack = () => {
    this.setState({ shouldDeleteImage: false, uploadFileToDelete: {} });
  }

  render() {
    const { classes } = this.props;
    const showPreviews = this.props.showPreviews && this.state.fileObjects.length > 0;
    return (
      <Fragment>
        {this.state.shouldDeleteImage === true ?
          <DeleteDialogs {...this.props} deleteCallBack={this.callbackDeleteDialog} close={this.handleNoCallBack} fileId={this.state.uploadFileToDelete.Id} open={this.state.shouldDeleteImage} msg={this.state.message} deletedMsg={this.state.deletedMsg} /> : ''
        }
        <div className="GridStyle">
          <Grid item sm={3}>
            <Dropzone
              accept={this.props.acceptedFiles.join(",")}
              onDrop={this.onDrop.bind(this)}
              onDropRejected={this.handleDropRejected.bind(this)}
              className={classNames(this.props.dropzoneClass, classes.dropZone)}
              acceptClassName={classes.stripes}
              rejectClassName={classes.rejectStripes}
              maxSize={this.props.maxFileSize}
            >
              <div className={'newDropzoneTextStyle ' + classes.dropzoneTextStyle}>
                <AttachFileIcon className={classes.uploadIconSize} />
                <p
                  className={classNames(
                    classes.dropzoneParagraph,
                    this.props.dropzoneParagraphClass
                  )}
                >
                  {this.state.dropzoneText}
                </p>
              </div>
            </Dropzone>
          </Grid>
        </div>
        {/* {this.state.getDocumentLoader  && (<UploadLoader />)} */}
        {showPreviews && (
          <Fragment>
            <Grid item sm={12} container className="GridStyle GridMargin newPreviewStyle">
              <UploadedPreviewList className="dropzone-chip-wrapper"
               fileObjects={_.uniqBy(this.state.imageDetails, function (e) {
                return e.fileName
                })}
                downloadFile={this.downloadFile}
                handleRemove={this.handleRemove.bind(this)}
                showFileNames={this.props.showFileNamesInPreview}
                {...this.props}
              />
            </Grid>
          </Fragment>
        )}
        {this.props.showAlerts && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            id="onCloseId"
            open={this.state.openSnackBar}
            autoHideDuration={6000}
            onClose={this.onCloseSnackbar}
          >
            <SnackbarContentWrapper
              onClose={this.onCloseSnackbar}
              variant={this.state.snackbarVariant}
              message={this.state.snackbarMessage}
            />
          </Snackbar>
        )}
      </Fragment>
    );
  }
}

UploadzoneArea.defaultProps = {
  acceptedFiles: ["image/jpg", "image/jpeg", "image/png", "image/bmp", "image/gif", "video/*", ".docx", ".doc", "application/pdf", ".xlsx", ".xls", ".csv", "text/*"],
  //acceptedFiles: ["image/*", "video/*", "application/*", "text/*"],
  //filesLimit: '20',
  maxFileSize: 50000000,
  dropzoneText: "ATTACH FILE",
  showPreviews: true, // By default previews show up under in the dialog and inside in the standalone
  showPreviewsInDropzone: true,
  showFileNamesInPreview: true,
  showAlerts: true,
  clearOnUnmount: true,
  initialFiles: [],
  getFileLimitExceedMessage: filesLimit =>
    `Maximum allowed number of files exceeded. Only ${filesLimit} allowed`,
  getFileAddedMessage: fileName => `File ${fileName} successfully added.\n\r`,
  getFileRemovedMessage: fileName => `File successfully removed.`,
  getDropRejectMessage: (rejectedFile, acceptedFiles, maxFileSize) => {
    let message = '';
    if(rejectedFile === undefined) {
      message = `File type not supported. `;
    }
    else {
      message = `File ${rejectedFile.name} was rejected. `;
      if (!acceptedFiles.includes(rejectedFile.type)) {
        message += "\nFile type not supported. ";
      }
      if (rejectedFile.size > maxFileSize) {
        message +=
          "File is too big. Size limit is 50 megabytes. ";
      }
    }
    return message;
  },
  onChange: () => { },
  onDrop: () => { },
  onDropRejected: () => { },
  onDelete: () => { }
};
UploadzoneArea.propTypes = {
  acceptedFiles: PropTypes.array,
  filesLimit: PropTypes.number,
  maxFileSize: PropTypes.number,
  dropzoneText: PropTypes.string,
  dropzoneClass: PropTypes.string,
  showPreviews: PropTypes.bool,
  showPreviewsInDropzone: PropTypes.bool,
  showFileNamesInPreview: PropTypes.bool,
  showAlerts: PropTypes.bool,
  clearOnUnmount: PropTypes.bool,
  initialFiles: PropTypes.arrayOf(PropTypes.string),
  getFileLimitExceedMessage: PropTypes.func,
  getFileAddedMessage: PropTypes.func,
  getFileRemovedMessage: PropTypes.func,
  getDropRejectMessage: PropTypes.func,
  onChange: PropTypes.func,
  onDrop: PropTypes.func,
  onDropRejected: PropTypes.func,
  onDelete: PropTypes.func
};

export default withStyles(styles)(UploadzoneArea);