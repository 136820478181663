import { doActionPostWithCaptcha } from '../services/httpRequest'
import { loginLoanPortalAPI } from '../services/apiUrls';
import APIConfig from '../services/appConfig';

export const UPDATE_LOAN_DETAILS = 'UPDATE_LOAN_DETAILS';
export const LOGIN_LOAN_PORTAL = 'LOGIN_LOAN_PORTAL';
export const LOGIN_LOAN_PORTAL_SUCCESS = 'LOGIN_LOAN_PORTAL_SUCCESS';
export const LOGIN_LOAN_PORTAL_FAILURE = 'LOGIN_LOAN_PORTAL_FAILURE';
export const UPDATE_APP_MODE = 'UPDATE_APP_MODE';

export const updateLoanDetails = (data) => {
	return {
		type: UPDATE_LOAN_DETAILS,
		payload: data
	}
}

export const loginLoanPortal = (reqObj, captchaText, captchaData) => {
	const request = doActionPostWithCaptcha({ url: loginLoanPortalAPI, data: reqObj }, APIConfig.ROOT_URL, captchaText, captchaData);
	return { type: LOGIN_LOAN_PORTAL,
		payload: request
	}
}

export const loginLoanPortalSuccess = (data) => {
	return { type: LOGIN_LOAN_PORTAL_SUCCESS,
		payload: data }
}

export const loginLoanPortalFailure = (error) => {
	return { type: LOGIN_LOAN_PORTAL_FAILURE,	
		payload: error }
}

export const updateAppMode = (data) => {
	return {
		type: UPDATE_APP_MODE,
		payload: data
	}
}