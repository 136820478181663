//create data for dropdown
export function createDataForDropdown(list,field1,value1,field2,value2,field3, value3){
	var updatedList = [];
    for(let i=0;i<list.length;i++){
        let record={};
        record[field1]=list[i][value1];
        record[field2]=list[i][value2];
        record[field3]=list[i][value3];
        updatedList.push(record);
    }
	return updatedList;
}

export const getFormattedDateYMDHMS = (date) => { 
	date = date !== undefined && date !== null && date !== "" 
			? new Date(date) 
			: new Date();
	return date.getFullYear() + "-" + 
		   (date.getMonth() + 1) + "-" + 
		   date.getDate() + " " +  
		   date.getHours() + ":" + 
		   date.getMinutes() + ":" + 
		   date.getSeconds()
}

export const getFormattedDateYMD = (date) => { // this is used for KeyboardDatePickerExample only
	date = date !== undefined && date !== null && date !== "" 
			? new Date(date) 
			: new Date();
	return date.getFullYear() + "-" + 
		   (date.getMonth() + 1) + "-" + 
		   date.getDate()
}

export const getFormattedDate = (fileStartDate) => {
	const newDate = new Date(fileStartDate)
	return (
	  String(newDate.getMonth() + 1).padStart(2, '0') +
	  '-' +
	  String(newDate.getDate()).padStart(2, '0') +
	  '-' +
	  newDate.getFullYear()
	)
}

export const calculateAge = (dateString) => {
	var today = new Date();
	var birthDate = new Date(dateString);
	if (birthDate.getFullYear() < today.getFullYear()) {
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
	}
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}
	let newAge;
	if (age) {
		newAge = age;
	} else {
		newAge = "";
	}
	return newAge;
}

export const documentAPIName = (name) => {
	let extension = getExtn(name);
	let newFilename = name.split('.').slice(0, -1).join('.')
	let val = new Date().getTime();
	return newFilename + '-' + val + '.' + extension;
  }

  export const documentImageName = (fileName, id) => {
	if(fileName !== null && fileName !== ''){
	  let extension = getExtn(fileName);let newFilename = fileName.split('.').slice(0, -1).join('.')
	  if(newFilename.indexOf('-') > -1){let originalName = newFilename.split('-').slice(0, -1).join('-')
		  return id + '_' +originalName + '.' + extension;
	  }else{return id + '_' +fileName;}
	}
  }
  
export const documentUIName = (name) => {
	if(name !== null && name !== ''){
	  let extension = getExtn(name);
	  let newFilename = name.split('.').slice(0, -1).join('.')
	  if(newFilename.indexOf('-') > -1){
		let originalName = newFilename.split('-').slice(0, -1).join('-')
		  return originalName + '.' + extension;
	  }else{
		return name;
	  }
	}
  }

  export function getShortStr(str, maxLength) {
	if (Array.isArray(str)){//} || str instanceof Array) {
	  if (str.length > 0) {
		str = str.join(", ");
	  } else {
		str = "";
	  }
	} else {
	  str = str ? str.toLocaleString() : "";
	}
  
	let output = str.toLocaleString();
	if (output !== null && output !== "") {
	  if (str.length > maxLength) {
		output = str.substr(0, maxLength - 3) + "...";
	  } else {
		if (output.trim().endsWith(","))
		  output = output.substring(0, output.length - 2);
	  }
	}
	return output;
  }

  export const getExtn = (filename) => {
	if(filename){
	  return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
	}
  }