import { v4 as uuidv4 } from 'uuid';

export let borrowerRequestJson= [
    {
        firstName: "", middleName:"", maritalStatus: "",
        lastName: "", email: "", maritalStatusId: 0,
        entityName: "",ownerId: "", dateOfBirth: null,
        cellPhone: "", homePhoneNumber: "", officePhone: "",
        aka: "", ssn: "", taxId: "", order:0,
        grossMonthlyIncome: null, employer: "", age:0, //extra field
        mailingAddressStreet: "", mailingAddressStateName: "",
        mailingAddressStateId: 0, mailingAddressCityName: "",
        mailingAddressCityId: 0, mailingAddressCountyName: "",
        mailingAddressCountyId: 0, mailingAddressZip: "",mailingAddressStateCode:"",
        address: "", stateName: "", stateId: 0,
        cityName: "", cityId: 0, countyName: "", countyId: 0, zip: "",
    },
    {
        firstName: "",  officePhone: "", aka: "", ssn: "",
        middleName:"", lastName: "", entityName: "",ownerId: "",
        email: "", dateOfBirth: null, maritalStatus: "",maritalStatusId: 0,
        age:0, //extra code
        cellPhone: "", homePhoneNumber: "",taxId: "",
        grossMonthlyIncome: null,employer: "", order:0
    }
]

export let currentAddressRequestJson= {
    current:{
        address: "", stateName: "",
        stateId: 0, cityName: "",
        cityId: 0, countyName: "",
        countyId: 0,stateCode:"",
        zip: "",
    },
    mailing:{
        address: "",stateName: "",countyName: "",
        cityId: 0, stateId: 0, cityName: "",
        countyId: 0,stateCode:"",
        zip: "",  
    }
}

export let subjectPropertyRequestJson=[
    {
        propertyId: uuidv4(),
        propertyImages: [
          // {
          //   imageId: "",
          //   imagePath: "",
          //   imageUrl: "",
          //   imageData: "",
          //   fileName: "",
          //   imageType: ""
          // }
        ],
        address: "",
        stateName: "",
        stateId: 0,
        cityName: "",
        cityId: 0,
        countyName: "",
        countyId: 0,
        zip: "",
        stateCode:"", //extra field
        order: 0,
        zipCodeErrMsg:"",//extra field
        showFields:false
    }
]

export let loanDocumentListRequestJson = [
  {
    userId: "", id: "",
    loanNumber: "", loanId: "",
    fileName: "", uploadId: "",
    documentTitle: "", uploadDate: "2021-12-07T08:37:53.796Z",
    documentDescription: "", isRequiredDocument: null,
    path: ""
  }
]

export let otherInfoRequestJson = {
    propertyTypeId:0,propertyType: "",
    isMortgageProperty: null,
    language: null,languageId: 0,
    isPriorCustomer: null,
    priorLoanNumber: "",
    comments: "",
}

export const loanSummaryRequestJson = {
    "loanId": "",
    "propertyTypeId":0,
    "propertyType": "",
    "isMortgageProperty": null,
    "language": "",
    "languageId": 0,
    "loanNumber":"",
    "isPriorCustomer": null,
    "priorLoanNumber": "",
    "comments": "",
    "ownerList": [
      {
        "firstName": "",
        "lastName": "",
        "entityName": "",
        "ownerId": "",
        "email": "",
        "dateOfBirth": "",
        "maritalStatus": "",
        "maritalStatusId": 0,
        "cellPhone": "",
        "homePhoneNumber": "",
        "officePhone": "",
        "aka": "",
        "ssn": "",
        "taxId": "",
        "grossMonthlyIncome": null,
        "employer": "",
        "mailingAddressStreet": "",
        "mailingAddressStateName": "",
        "mailingAddressState": 0,
        "mailingAddressCityName": "",
        "mailingAddressCityId": 0,
        "mailingAddressCountyName": "",
        "mailingAddressCounty": 0,
        "mailingAddressZip": "",
        "address": "",
        "stateName": "",
        "stateId": 0,
        "cityName": "",
        "cityId": 0,
        "countyName": "",
        "countyId": 0,
        "zip": "",
        "order": 0
      }
    ],
    "propertyList": [
      {
        "propertyId": uuidv4(),
        "propertyImages": [
          // {
          //   "imageId": "",
          //   "imagePath": "",
          //   "imageUrl": "",
          //   "imageData": "",
          //   "fileName": "",
          //   "imageType": "",
          //   "bucketName": "",
          //   "isDeleted": false,
          // }
        ],
        "address": "",
        "stateName": "",
        "stateId": 0,
        "cityName": "",
        "cityId": 0,
        "countyName": "",
        "countyId": 0,
        "zip": "",
        "order": 0
      }
    ],
    "propertyQuestions": [
      {
        "question": "",
        "questionId": 0,
        "answer": ""
      }
    ],
    "loanDocumentList": [
      {
        "userId": "",
        "id": "",
        "loanNumber": "",
        "loanId": "",
        "fileName": "",
        "uploadId": "",
        "documentTitle": "",
        "uploadDate": "2021-12-07T08:37:53.796Z",
        "documentDescription": "",
        "isRequiredDocument": false,
        "path": ""
      }
    ]
  }

  export let editableBorrowerRequestJson= [
    {
        firstName: "",
        middleName:"",lastName: "",
        entityName: "",ownerId: "",
        email: "",
        dateOfBirth: null,
        maritalStatus: "",
        maritalStatusId: 0,
        cellPhone: "",
        homePhoneNumber: "",
        officePhone: "",
        aka: "", ssn: "", taxId: "",
        grossMonthlyIncome: null, employer: "",
        order:0, mailingAddressStreet: "",mailingAddressCountyId: 0,
        mailingAddressStateName: "", mailingAddressStateId: 0,mailingAddressZip: "",
        mailingAddressCityName: "", mailingAddressCityId: 0,mailingAddressCountyName: "",
        address: "", stateName: "", stateId: 0, cityName: "", cityId: 0,
        countyName: "",countyId: 0,zip: "",
    },
    {
        firstName: "",
        middleName:"",
        lastName: "",
        entityName: "",ownerId: "",
        email: "",dateOfBirth: null,
        maritalStatus: "",
        maritalStatusId: 0,
        cellPhone: "",homePhoneNumber: "",
        officePhone: "",
        aka: "",ssn: "",
        taxId: "",
        grossMonthlyIncome: null,
        employer: "",
        order:0
    }
]