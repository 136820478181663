import { doActionGet, doActionPost,doActionPostWithCaptcha, doActionDelete, doActionPutWithCaptcha } from '../services/httpRequest'
import { getAllPortalDataAPI, getCaptchaDataAPI, postLoanSummaryAPI, getUploadDocumentUrlAPI, 
	deleteDocumentUrlAPI, downloadDocumentUrlAPI, getUploadDetailsAPI, postImageDetailsAPI } from '../services/apiUrls';
import APIConfig from '../services/appConfig';

export const UPDATE_ADDRESS_REQUEST_JSON = 'UPDATE_ADDRESS_REQUEST_JSON';
export const UPDATE_INITIAL_BORROWER_REQUEST_JSON = 'UPDATE_INITIAL_BORROWER_REQUEST_JSON';

export const UPDATE_SUBJECT_PROPERTY_ADDRESS_REQUEST_JSON ='UPDATE_SUBJECT_PROPERTY_ADDRESS_REQUEST_JSON';
export const UPDATE_LOAN_SUMMARY_REQUEST_JSON ='UPDATE_LOAN_SUMMARY_REQUEST_JSON';

export const FETCH_MARTIAL_STATUS_LIST = 'FETCH_MARTIAL_STATUS_LIST';
export const FETCH_MARTIAL_STATUS_LIST_SUCCESS = 'FETCH_MARTIAL_STATUS_LIST_SUCCESS';
export const FETCH_MARTIAL_STATUS_LIST_FAILURE = 'FETCH_MARTIAL_STATUS_LIST_FAILURE';

export const FETCH_CITY = 'FETCH_CITY';
export const FETCH_CITY_SUCCESS = 'FETCH_CITY_SUCCESS';
export const FETCH_CITY_FAILURE = 'FETCH_CITY_FAILURE';

export const FETCH_STATE = 'FETCH_STATE';
export const FETCH_STATE_SUCCESS = 'FETCH_STATE_SUCCESS';
export const FETCH_STATE_FAILURE = 'FETCH_STATE_FAILURE';

export const FETCH_COUNTY_LIST = 'FETCH_COUNTY_LIST';
export const FETCH_COUNTY_LIST_SUCCESS = 'FETCH_COUNTY_LIST_SUCCESS';
export const FETCH_COUNTY_LIST_FAILURE = 'FETCH_COUNTY_LIST_FAILURE';

export const FETCH_ALL_DATA = 'FETCH_ALL_DATA';
export const FETCH_ALL_DATA_SUCCESS = 'FETCH_ALL_DATA_SUCCESS';
export const FETCH_ALL_DATA_FAILURE = 'FETCH_ALL_DATA_FAILURE';

export const UPDATE_MARTIAL_STATUS_LIST = 'UPDATE_MARTIAL_STATUS_LIST';
export const UPDATE_STATE_LIST = 'UPDATE_STATE_LIST';
export const UPDATE_CITY_LIST = 'UPDATE_CITY_LIST';
export const UPDATE_COUNTY_LIST = 'UPDATE_COUNTY_LIST';
export const UPDATE_LANGUAGE_LIST = 'UPDATE_LANGUAGE_LIST';
export const UPDATE_MAILING_CITY_LIST = 'UPDATE_MAILING_CITY_LIST';
export const UPDATE_MAILING_COUNTY_LIST = 'UPDATE_MAILING_COUNTY_LIST';
export const UPDATE_OTHER_INFO_REQUEST_JSON = 'UPDATE_OTHER_INFO_REQUEST_JSON';
export const TOGGLE_TOUCHED = 'TOGGLE_TOUCHED';
export const FETCH_CAPTCHA_IMAGE = 'FETCH_CAPTCHA_IMAGE';
export const FETCH_CAPTCHA_IMAGE_SUCCESS = 'FETCH_CAPTCHA_IMAGE_SUCCESS';
export const FETCH_CAPTCHA_IMAGE_FAILURE = 'FETCH_CAPTCHA_IMAGE_FAILURE';
export const UPDATE_CAPTCHA_TEXT = 'UPDATE_CAPTCHA_TEXT';
export const MANAGE_LOAN_SUMMARY = 'MANAGE_LOAN_SUMMARY';
export const MANAGE_LOAN_SUMMARY_SUCCESS = 'MANAGE_LOAN_SUMMARY_SUCCESS';
export const MANAGE_LOAN_SUMMARY_FAILURE = 'MANAGE_LOAN_SUMMARY_FAILURE';
 
export const FETCH_UPLOAD_DOCUMENT_URL = 'FETCH_UPLOAD_DOCUMENT_URL';
export const FETCH_UPLOAD_DOCUMENT_URL_SUCCESS = 'FETCH_UPLOAD_DOCUMENT_URL_SUCCESS';
export const FETCH_UPLOAD_DOCUMENT_URL_FAILURE = 'FETCH_UPLOAD_DOCUMENT_URL_FAILURE';
export const FETCH_DOWNLOAD_DOCUMENT_URL = 'FETCH_DOWNLOAD_DOCUMENT_URL';
export const FETCH_DOWNLOAD_DOCUMENT_URL_SUCCESS = 'FETCH_DOWNLOAD_DOCUMENT_URL_SUCCESS';
export const FETCH_DOWNLOAD_DOCUMENT_URL_FAILURE = 'FETCH_DOWNLOAD_DOCUMENT_URL_FAILURE';
export const DELETE_UPLOADED_DOCUMENT = 'DELETE_UPLOADED_DOCUMENT';
export const DELETE_UPLOADED_DOCUMENT_SUCCESS = 'DELETE_UPLOADED_DOCUMENT_SUCCESS';
export const DELETE_UPLOADED_DOCUMENT_FAILURE = 'DELETE_UPLOADED_DOCUMENT_FAILURE';
export const FETCH_UPLOAD_DETAILS = 'FETCH_UPLOAD_DETAILS';
export const FETCH_UPLOAD_DETAILS_SUCCESS = 'FETCH_UPLOAD_DETAILS_SUCCESS';
export const FETCH_UPLOAD_DETAILS_FAILURE = 'FETCH_UPLOAD_DETAILS_FAILURE';
export const POST_UPLOAD_DETAILS = 'POST_UPLOAD_DETAILS';
export const POST_UPLOAD_DETAILS_SUCCESS = 'POST_UPLOAD_DETAILS_SUCCESS';
export const POST_UPLOAD_DETAILS_FAILURE = 'POST_UPLOAD_DETAILS_FAILURE';

export const UPDATE_EDITABLE_BORROWER_REQUEST_JSON = 'UPDATE_EDITABLE_BORROWER_REQUEST_JSON';
export const UPDATE_LOAN_DOCUMENT_LIST_REQUEST_JSON = 'UPDATE_LOAN_DOCUMENT_LIST_REQUEST_JSON';

export const updateAddressRequestJson = (data) => {
	return {
		type: UPDATE_ADDRESS_REQUEST_JSON,
		payload: data
	}
}

export const updateSubjectPropertyRequestJson = (data) => {
	return {
		type: UPDATE_SUBJECT_PROPERTY_ADDRESS_REQUEST_JSON,
		payload: data
	}
}

export const updateOtherInfoRequestJson = (data) => {
	return {
		type: UPDATE_OTHER_INFO_REQUEST_JSON,
		payload: data
	}
}

export const updateBorrowerRequestJson = (data) => {
	return {
		type: UPDATE_INITIAL_BORROWER_REQUEST_JSON,
		payload: data
	}
}

export const updateLoanSummaryRequestJson = (data) => {
	return {
		type: UPDATE_LOAN_SUMMARY_REQUEST_JSON,
		payload: data
	}
}

export const updateMartialStatusList = (data) => {
	return {
		type: UPDATE_MARTIAL_STATUS_LIST,
		payload: data
	}
}

export const updateStateList = (data) => {
	return {
		type: UPDATE_STATE_LIST,
		payload: data
	}
}

export const updateLanguageList = (data) => {
	return {
		type: UPDATE_LANGUAGE_LIST,
		payload: data
	}
}

export const updateCityList = (data) => {
	return {
		type: UPDATE_CITY_LIST,
		payload: data
	}
}

export const updateCountyList = (data) => {
	return {
		type: UPDATE_COUNTY_LIST,
		payload: data
	}
}

export const updateMailingCityList = (data) => {
	return {
		type: UPDATE_MAILING_CITY_LIST,
		payload: data
	}
}

export const updateMailingCountyList = (data) => {
	return {
		type: UPDATE_MAILING_COUNTY_LIST,
		payload: data
	}
}


export const fetchUploadDocumentUrl = (reqObj) => {
	const request = doActionPost({ url: getUploadDocumentUrlAPI, data: reqObj }, APIConfig.ROOT_URL);

	return {
		type: FETCH_UPLOAD_DOCUMENT_URL,
		payload: request
	}
}
export const fetchUploadDocumentUrlSuccess = (status) => {
	return {
		type: FETCH_UPLOAD_DOCUMENT_URL_SUCCESS,
		payload: status
	}
}
export const fetchUploadDocumentUrlFailure = error => {
	return {
		type: FETCH_UPLOAD_DOCUMENT_URL_FAILURE,
		payload: error
	}
}

export const fetchDownloadDocumentUrl= (fileName, path) => {
	let finalUrl = '';
	if(path !== '' && path !== null && path !== undefined ){
		finalUrl = downloadDocumentUrlAPI + '?fileName=' + fileName + '&filePath=' + path
	}else{
		finalUrl = downloadDocumentUrlAPI + '?fileName=' + fileName
	}
	const request = doActionGet({url: finalUrl}, APIConfig.ROOT_URL)
	return {
		type: FETCH_DOWNLOAD_DOCUMENT_URL,
		payload: request
	}
}
export const fetchDownloadDocumentUrlSuccess= status => {
	return {
		type: FETCH_DOWNLOAD_DOCUMENT_URL_SUCCESS,
		payload: status
	}
}
export const fetchDownloadDocumentUrlFailure= error => {
	return {
		type: FETCH_DOWNLOAD_DOCUMENT_URL_FAILURE,
		payload: error
	}
}

export const deleteUploadedDocument= (reqJson) => {
	const request = doActionDelete({url: deleteDocumentUrlAPI, data : reqJson}, APIConfig.ROOT_URL)
	return {
		type: DELETE_UPLOADED_DOCUMENT,
		payload: request
	}
}
export const deleteUploadedDocumentSuccess= status => {
	return {
		type: DELETE_UPLOADED_DOCUMENT_SUCCESS,
		payload: status
	}
}
export const deleteUploadedDocumentFailure= error => {
	return {
		type: DELETE_UPLOADED_DOCUMENT_FAILURE,
		payload: error
	}
}

export const fetchUploadDetails= (loanId) => {
	const request = doActionGet({url: getUploadDetailsAPI + '?loanId=' + loanId}, APIConfig.ROOT_URL)
	return {
		type: FETCH_UPLOAD_DETAILS,
		payload: request
	}
}
export const fetchUploadDetailsSuccess= status => {
	return {
		type: FETCH_UPLOAD_DETAILS_SUCCESS,
		payload: status
	}
}
export const fetchUploadDetailsFailure= error => {
	return {
		type: FETCH_UPLOAD_DETAILS_FAILURE,
		payload: error
	}
}

export const getAllData = (reqObj) => {
	const request = doActionPost({ url: getAllPortalDataAPI, data: reqObj },APIConfig.ROOT_URL);
	return { type: FETCH_ALL_DATA,
		payload: request
	}
}

export const getAllDataSuccess = (data) => {
	return { type: FETCH_ALL_DATA_SUCCESS,
		payload: data }
}

export const getAllDataFailure = (error) => {
	return { type: FETCH_ALL_DATA_FAILURE,	
		payload: error }
}

export const toggleTouched = (data) => {
	return {
		type: TOGGLE_TOUCHED,
		payload: data
	}
}

//fetch captcha image from API
export const getCaptchaImage = () => {
	const response = doActionGet({ url: getCaptchaDataAPI }, APIConfig.ROOT_URL);
	return {
		type: FETCH_CAPTCHA_IMAGE,
		payload: response
	}
}

//method call when captcha image successfully fetched
export const getCaptchaImageSuccess = (data) => {
	return {
		type: FETCH_CAPTCHA_IMAGE_SUCCESS,
		payload: data
	}
}

//method call when captcha image fail to access
export const getCaptchaImageFailure = (error) => {
	return {
		type: FETCH_CAPTCHA_IMAGE_FAILURE,
		payload: error
	}
}

//method call to update the captcha text
export const updateCaptchaText = (data) => {
	return {
		type: UPDATE_CAPTCHA_TEXT,
		payload: data
	}
}

export const manageLoanSummary = (request,captchaText,captchaData, isEditMode) => {
	let response =''
	if(isEditMode){
		response=doActionPutWithCaptcha({ url: postLoanSummaryAPI, data: request },APIConfig.ROOT_URL,captchaText,captchaData);
	}
	else{
		response=doActionPostWithCaptcha({ url: postLoanSummaryAPI, data: request },APIConfig.ROOT_URL,captchaText,captchaData);
	}	
    return {
		type: MANAGE_LOAN_SUMMARY,
		payload: response
	}
}

//method call when payment plan added successfully
export const manageLoanSummarySuccess = (data) => {
	return {
		type: MANAGE_LOAN_SUMMARY_SUCCESS,
		payload: data
	}
}

//method call when payment plan  fail
export const manageLoanSummaryFailure = (error) => {
	return {
		type: MANAGE_LOAN_SUMMARY_FAILURE,
		payload: error
	}
}

export const postImageDetails = (reqObj) => {
	const request = doActionPost({ url: postImageDetailsAPI, data: reqObj },APIConfig.ROOT_URL);
	return { type: POST_UPLOAD_DETAILS,
		payload: request
	}
}

export const postImageDetailsSuccess = (data) => {
	return { type: POST_UPLOAD_DETAILS_SUCCESS,
		payload: data }
}

export const postImageDetailsFailure = (error) => {
	return { type: POST_UPLOAD_DETAILS_FAILURE,	
		payload: error }
}

export const updateEditableBorrowerRequestJson = (data) => {
	return {
		type: UPDATE_EDITABLE_BORROWER_REQUEST_JSON,
		payload: data
	}
}

export const updateLoanDocumentListRequestJson = (data) => {
	return {
		type: UPDATE_LOAN_DOCUMENT_LIST_REQUEST_JSON,
		payload: data
	}
}

