import { 
    UPDATE_LOAN_DETAILS,
    LOGIN_LOAN_PORTAL,
    LOGIN_LOAN_PORTAL_SUCCESS,
    LOGIN_LOAN_PORTAL_FAILURE,
    UPDATE_APP_MODE
 } from '../actions/dashboardAction';

import { loanDetailsJson } from '../const/loanDetailsConst';

const INITIAL_STATE = {
   loanDetails: loanDetailsJson,
   modeInfo: { isEditMode: false, loanId: "" },
   applicationDetails: {},
   success: null,
   error: null,
   loading: false,
}

export default function dashboardReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case UPDATE_LOAN_DETAILS:
            return { ...state, loanDetails: action.payload, error: null, loading: false };
        case LOGIN_LOAN_PORTAL: 
            return { ...state, applicationDetails: {}, error: null, loading: true };
        case LOGIN_LOAN_PORTAL_SUCCESS: 
            return { ...state, applicationDetails: action.payload, error: null, loading: false };
        case LOGIN_LOAN_PORTAL_FAILURE:
            return { ...state, applicationDetails: {}, error: null, loading: false };
        case UPDATE_APP_MODE:
            return { ...state, modeInfo: action.payload, error: null, loading: false };
        default:
            return state;
    }
}