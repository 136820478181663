import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import LogoSvg from "../assets/logo.svg";
import { withRouter } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import LoaderComponent from "../common/loaderComponent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import RightSecImg from "../assets/loan-first-pg-img.jpg";
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '../assets/icons/refresh.svg';
import CustomSnackBarContainer from "../containers/customSnackBarContainer";

export class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPropertyPrimaryResidence: "",
      propertyType: "",
      propertyOwnedBy: "",
      isPrimaryResidenceOptions: [],
      propertyTypeOptions: [],
      propertyOwnerOptions: [],
      showValidationMsg: false,
      loanNumber: '', //TX21120313CC
      captchaText: '',
      isLoanNumberTouched: false,
      isCaptchaTouched: false,
      showLoader: false
    };
  }

  componentDidMount() {
    this.setIsPrimaryResidenceOptions();
    this.setPropertyTypeOptions();
    this.setPropertyOwnerOptions();
    const { id } = this.props.match.params;
    if(id !== undefined) {
      let modeInfo = {
        isEditMode: true,
        loanId: id
      }
      this.props.getCaptchaImage();
      this.props.updateAppMode(modeInfo);
    }
  }

  // componentDidUpdate(prevProps, prevState){
  //  // if(prevProps.captchaImageData !== undefined) {
  //     if(prevProps.captchaText !== this.props.captchaText) //||
  //       //(prevProps.captchaImageData.newCaptchaImage !== this.props.captchaImageData.newCaptchaImage)){
  //        this.getCaptcha();
  //        this.setState({captchaText: ""});
  //     }
  //   //}
  // }

  setIsPrimaryResidenceOptions = () => {
    let data = [
      { masterDataCode: "Yes", extraField: "", masterDataDesc: true },
      { masterDataCode: "No", extraField: "", masterDataDesc: false },
    ];
    this.setState({ isPrimaryResidenceOptions: data });
  };

  setPropertyTypeOptions = () => {
    let data = [
      { masterDataCode: "Residential", extraField: "", masterDataDesc: true },
      { masterDataCode: "Commercial", extraField: "", masterDataDesc: false },
    ];
    this.setState({ propertyTypeOptions: data });
  };

  setPropertyOwnerOptions = () => {
    let data = [
      { masterDataCode: "Company", extraField: "", masterDataDesc: true },
      { masterDataCode: "Individual", extraField: "", masterDataDesc: false },
    ];
    this.setState({ propertyOwnerOptions: data });
  };

  handleDropdownChange = (event, dropdown) => {
    this.setState({
      showValidationMsg: false
    });
    let loanDetailsJson = {...this.props.loanDetails};
    if (dropdown === "isPropertyPrimaryResidence") {
      loanDetailsJson.isPropertyPrimaryResidence = event.target.value;
      this.setState(
        {
          isPropertyPrimaryResidence: event.target.value,
        }
      )
      if(event.target.value === "Yes") {
        loanDetailsJson.propertyType = "";
        loanDetailsJson.propertyOwnedBy = "";
        this.setState(
          {
            propertyType: "",
            propertyOwnedBy: "",
          }
        )
      }
      ;
    } else if (dropdown === "propertyType") {
      loanDetailsJson.propertyType = event.target.value;
      this.setState(
        {
          propertyType: event.target.value,
        }
      );
      if(event.target.value === "Residential") {
        loanDetailsJson.propertyOwnedBy = "";
        this.setState(
          {
            propertyOwnedBy: "",
          }
        );
      }
      
      
    } else if (dropdown === "propertyOwnedBy") {
      loanDetailsJson.propertyOwnedBy = event.target.value;
      this.setState(
        {
          propertyOwnedBy: event.target.value,
        }
      );
    }
    this.props.updateLoanDetails(loanDetailsJson);
    localStorage.setItem('loanDetails', JSON.stringify(loanDetailsJson));
  };

  handleSubmit = () => {
    if((this.state.isPropertyPrimaryResidence === "Yes") ||
    (this.state.isPropertyPrimaryResidence === "No" && this.state.propertyType === "Residential") ||
    (this.state.isPropertyPrimaryResidence === "No" && this.state.propertyType === "Commercial" && this.state.propertyOwnedBy !== "")) {
      this.props.history.push({
        pathname: "/taxease/application-details",
      });
    }
    else {
      this.setState({
        showValidationMsg: true
      });
    }
  };

  handleLogin = () => {
    if(this.state.loanId !== "" && this.state.loanNumber !== "" && this.state.captchaText !== "") {
      this.setState({showLoader: true});
      let reqJson = {
        "loanId": this.props.modeInfo.loanId,
        "loanNumber": this.state.loanNumber
      }
      //this.setState({captchaText: ''});
      this.getCaptcha();
      this.props.loginLoanPortal(reqJson, this.state.captchaText, this.props.captchaImageData.captchaData).then(response => {
        if(response) {
          this.setState({showLoader: false});
          this.props.history.push({
            pathname: "/taxease/application-details",
          });
        }
      })
    }
    else {
      this.setState((prevState, props) => ({
        isLoanNumberTouched: prevState.loanNumber === "" ? true : false,
        isCaptchaTouched: props.captchaText === "" ? true : false,
        captchaText: '',
        showLoader: false
      }));
      this.props.updateCaptchaText("")
      this.getCaptcha();
    }
  }

  handleChange = (e) => {
    //TODO
    this.setState({
      loanNumber: e.target.value,
      isFormTouched: false
    });
  }

  getCaptcha = () => {
    this.props.getCaptchaImage();
  }

  setCaptchaText(e) {
    this.setState({
      captchaText:e.target.value,
      isFormTouched: false
    })
    this.props.updateCaptchaText(e.target.value);
  }

  render() {
    return (
      <Fragment>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className="main-container"
        >
          {!this.props.modeInfo.isEditMode ? <Grid item className="dashboard-container loan-pg-wrapper">
            <Paper className="custom-paper dashboard-paper" elevation={3}>
              <div className="logo-box loan-pg">
                <img alt="Logo" src={LogoSvg} />
              </div>
              
              <Grid container class="loan-first-pg">
                <Grid item xs={12} className="grid-row">
                  <Typography variant="h6" gutterBottom>
                    <strong>Welcome!</strong> Please provide your loan
                    application details:
                  </Typography>
                </Grid>
                <Grid container>
                  <Grid item xs={7}>
                      
                    <Grid item xs={12} className="grid-row">
                      <FormControl>
                        <InputLabel id="first-select-label">
                          Is the property your primary residence? <span className="redTxt">*</span>
                        </InputLabel>
                        <Select
                          className="first-select"
                          labelId="first-select-label"
                          id="first-select"
                          name="first-select"
                          value={this.state.isPropertyPrimaryResidence}
                          onChange={(e) =>
                            this.handleDropdownChange(e, "isPropertyPrimaryResidence")
                          }
                        >
                          {this.state.isPrimaryResidenceOptions.map((option) => (
                            <MenuItem
                              key={option.masterDataCode}
                              value={option.masterDataCode}
                            >
                              {option.masterDataCode}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Box className="icon-box"></Box>
                    </Grid>
                    {this.state.isPropertyPrimaryResidence === "No" ? 
                    <Grid item xs={12} className="grid-row">
                      <FormControl>
                        <InputLabel id="second-select-label">
                          Is the property residential or commercial? <span className="redTxt">*</span>
                        </InputLabel>
                        <Select
                          className="second-select"
                          labelId="second-select-label"
                          id="second-select"
                          name="second-select"
                          value={this.state.propertyType}
                          onChange={(e) =>
                            this.handleDropdownChange(e, "propertyType")
                          }
                        >
                          {this.state.propertyTypeOptions.map((option) => (
                            <MenuItem
                              key={option.masterDataCode}
                              value={option.masterDataCode}
                            >
                              {option.masterDataCode}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Box className="icon-box"></Box>
                    </Grid> : null }
                    {this.state.propertyType === "Commercial" ? 
                    <Grid item xs={12} className="grid-row">
                      <FormControl>
                        <InputLabel id="third-select-label">
                          Is the property owned by a company or an individual? <span className="redTxt">*</span>
                        </InputLabel>
                        <Select
                          className="third-select"
                          labelId="third-select-label"
                          id="third-select"
                          name="third-select"
                          value={this.state.propertyOwnedBy}
                          onChange={(e) =>
                            this.handleDropdownChange(e, "propertyOwnedBy")
                          }
                        >
                          {this.state.propertyOwnerOptions.map((option) => (
                            <MenuItem
                              key={option.masterDataCode}
                              value={option.masterDataCode}
                            >
                              {option.masterDataCode}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Box className="icon-box"></Box>
                    </Grid> : null }
                    {this.state.showValidationMsg ? <Grid item xs={12}>
                      <Typography gutterBottom>
                        <span className="redTxt">Please fill the mandatory detail(s)</span>
                      </Typography>
                    </Grid> : null}
                    <Grid item xs={12} className="">
                      <Button variant="contained" color="primary" onClick={this.handleSubmit}>
                        SUBMIT
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={5} className="right-img-sec">
                    <img alt="Right Section" src={RightSecImg} />
                  </Grid>
                </Grid>
              </Grid>
              </Paper>
              </Grid> :
              <Grid item className="dashboard-container loan-login-pg-parent-sec">
            <Paper className="custom-paper dashboard-paper" elevation={3}>
              <div className="logo-box loan-pg">
                <img alt="Logo" src={LogoSvg} />
              </div>
              <Grid container className="loan-login-pg">
                {/* <Grid item xs={12} className="grid-row col-sec">
                <Grid item xs={12}>
                  <TextField name="loanId" label="Loan ID" disabled required
                    value={this.props.modeInfo.loanId}
                  />
                  </Grid>
                </Grid> */}
                <Grid item xs={12} className="grid-row col-sec">
                <Grid item xs={12}>
                  <TextField fullWidth name="loanNo" label={"Loan Number"} required
                    value={this.state.loanNumber} onChange={(e) => this.handleChange(e)} 
                  />
                  </Grid>
                  {this.state.isLoanNumberTouched && this.state.loanNumber === "" ? <Grid item xs={12}>
                  <div className="loan-num-msg-disp">
                    <span className="redTxt">Loan number is required</span>
                  </div>
                </Grid> : null}
                </Grid>
                
                <Grid item xs={12} className="grid-row cus-captcha-cls">
                
                  <TextField 
                    className="captcha-field" 
                    id="captcha" 
                    required
                    name="captcha" error={this.props.error}
                    label="Enter Captcha" 
                    value={this.state.captchaText}
                    onChange={(e) => this.setCaptchaText(e)}
                  />
                  {this.state.isCaptchaTouched && this.state.captchaText === "" ? <Grid item xs={12}>
                    <div className="captcha-msg-disp">
                      <span className="redTxt">Captcha is required</span>
                    </div>
                  </Grid> : null }
                  <FormControl className='captcha-box'>
                    <img alt='' src={this.props.captchaImageData.newCaptchaImage} ></img>
                  </FormControl>
                  <Box className='icon-box'>
                    <Tooltip enterTouchDelay={10} leaveTouchDelay={2000} title="Refresh Captcha" arrow>
                      <img alt='' src={RefreshIcon} onClick={this.getCaptcha}></img>
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item xs={12} className="">
                  <Button variant="contained" color="primary" onClick={this.handleLogin}>
                    LOGIN
                  </Button>
                </Grid>       
              </Grid>
            </Paper>
          </Grid>}
        </Grid>
        <LoaderComponent loading={this.props.loading && this.state.showLoader} />
        <CustomSnackBarContainer />
      </Fragment>
    );
  }
}

export default withRouter(Dashboard);
