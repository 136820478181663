export function isImage(file) {
  if (file) {
    const fileName = file.name || file.path;
    const suffix = fileName.substr(fileName.indexOf('.') + 1).toLowerCase();
    if (suffix === 'jpg' || suffix === 'jpeg' || suffix === 'bmp' || suffix === 'png') {
      return true;
    }
  }
}
export function convertBytesToMbsOrKbs(filesize) {
  var size = '';
  // I know, not technically correct...
  if (filesize >= 1000000) {
    size = (filesize / 1000000) + ' megabytes';
  } else if (filesize >= 1000) {
    size = (filesize / 1000) + ' kilobytes';
  } else {
    size = filesize + ' bytes'
  }
  return size
}

export async function createFileFromUrl(url) {
  const response = await fetch(url);
  const data = await response.blob();
  const metadata = { type: data.type };
  const filename = url.replace(/\?.+/, '').split('/').pop();
  const ext = data.type.split('/').pop();
  return new File([data], `${filename}.${ext}`, metadata);
}

export function isValidFile(file) {
  const fileName = file.name || file.path;
  let arr = fileName.split('.');

  //const suffix = fileName.slice(fileName.indexOf(".") + 1).toLowerCase();
  const suffix = arr[arr.length - 1];
  if (
    suffix === "txt" ||
    suffix === "docx" ||
    suffix === "doc" ||
    suffix === "excel" ||
    suffix === "xlsx" ||
    suffix === "xls" ||
    suffix === "pdf" ||
    suffix === "csv" ||
    suffix === "jpg" ||
    suffix === "jpeg" ||
    suffix === "bmp" ||
    suffix === "gif" ||
    suffix === "png" ||
    suffix === 'flv' ||
    suffix === 'mp4'
  ) {
    return true;
  }
  return false;
}