import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import previewImg from "../../assets/previewImg.svg";
import { documentUIName, getShortStr } from "../../common/util";

const styles = {
  removeBtn: {
    transition: ".5s ease",
    position: "absolute",
    top: 10,
    right: 15,
    width: 20,
    height: 20,
    minHeight: 20,
    margin: "auto",
    color: "#999999",
    cursor: "pointer"
  },
  removeBtnIcon: {
    width: 20,
    height: 20,
    opacity: 0.7,
    "&:hover": {
      opacity: 1
    }
  },
  smallPreviewImg: {
    height: "26px",
    width: "26px",
    maxWidth: "26px",
    boxSizing: "border-box",
    border: "1px solid #f5f5f5",
    borderRadius: "50%",
    zIndex: 5,
    opacity: 1
  },
  imageContainer: {
    position: "relative",
    zIndex: 10,
    width: "100%",
    minHeight: "40px",
    height: "40px",
    padding: "3px 10px",
    borderRadius: 4,
    border: "1px solid #f5f5f5",
    boxShadow: "0 2px 4px rgba(0,0,0,0.29)",
    display: "flex",
    alignItems: "center"
  },
  uploadedFileName: {
    fontSize: 12,
    margin: 0,
    padding: "0 30px 0 10px",
    lineHeight: 1.2,
    fontWeight: "300",
    color: "#707070",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontFamily: "Roboto"
  },
  iconContainer: {
    display: "flex",
    backgroundColor: "#3f51b5",
    borderRadius: "50%",
    height: "26px",
    width: "26px",
    maxWidth: "26px",
    boxSizing: "border-box",
    alignItems: "center",
    justifyContent: "center"
  }
};

function PreviewList(props) {
  const { fileObjects, handleRemove, showFileNames, classes, downloadFile} = props;
  return (
    <Fragment>
      {fileObjects.map((fileObject, i) => {
        return (
          <Grid item sm={6} key={i}>
            <div className={classes.imageContainer}>
              <span className={classes.iconContainer}>
                <img alt = '' className={classes.icon} src={previewImg} />
              </span>
              {showFileNames && (
                <p className={classes.uploadedFileName}>
                  <span onClick={() => downloadFile(fileObject)}>
                  {
                    // fileObject.file.name.length > 20
                    // ? `${fileObject.file.name.slice(0, 20)}...`
                    // : 
                    // editableMode === true ?  getShortStr(documentUIName(fileObject.file.name), 20) : fileObject.file.name 
                     getShortStr(documentUIName(fileObject.fileName, 20))

                 }
                  </span>
                </p>
              )}
              {
                !fileObject.alreadyUploadedDocument ? (<span onClick={handleRemove(i, fileObject )} className={classes.removeBtn}>
                <CloseIcon className={classes.removeBtnIcon} />
                </span>):null
              }

              
            </div>
          </Grid>
        );
      })}
    </Fragment>
  );
}

export default withStyles(styles)(PreviewList);