import React, { Component, Fragment } from "react";
import Header from "../common/header/header";
import LoaderComponent from "../common/loaderComponent";
import CustomSnackBarContainer from "../containers/customSnackBarContainer";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ThankYouImg from "../assets/thankuimg.jpg";

export class Thankyou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetList: [],
      page: 0,
      rowsPerPage: 10,
      subHeaderDetails: ["Welcome"],
    };
  }
  redirect = () => {
    window.open("https://taxease.com/","_self");
  };

  render() {
    return (
      <Fragment>
        <Header></Header>
        {/* <SubHeader></SubHeader> */}
        <div className="application-detail-container thankyou-pg-inner-wrapper">
          <Grid fullWidth container>
            <div className="detail-section thankyou-pg">
              <Grid fullWidth container className="loan-pg-sec">
                
                  <Grid container spacing={8}>
                    <Grid item xs={12} className="txt-center">
                    <img alt="Thank You" src={ThankYouImg}  />
                    </Grid>
                    <Grid item xs={12} className="txt-center">
                      <Typography variant="h5" gutterBottom >
                        <strong>Thank you!</strong>
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        <i>Your submission has been sent.</i>
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        <i>After reviewing your application, we will come back to you in two (2) business days or less.</i>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className="txt-center">
                      <Button variant="contained" color="primary" onClick={this.redirect}>
                        Go To Home
                      </Button>
                    </Grid>
                  </Grid>
                
              </Grid>
            </div>
          </Grid>
        </div>
        <LoaderComponent {...this.props} />
        <CustomSnackBarContainer />
      </Fragment>
    );
  }
}

export default withRouter(Thankyou);
