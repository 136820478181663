import React, { Component, Fragment } from "react";

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';

export class SubjectPropertyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  componentDidMount(){
     
  }

  handleChange = (e) => {
    this.props.handleSubjectPropertyChange(e.target.name,e.target.value, this.props.propertyIndex);
  };

  handleSwitchChange = (e) => {
    this.props.handleSubjectPropertySwitch(e.target.name,e.target.checked, this.props.propertyIndex);
  }

  handleMailingSwitchChange = (e) => {
    this.props.handleMailingSwitchChange(e.target.name,e.target.checked, this.props.propertyIndex);
  }

  
  displayProperty = () => {
    this.props.displayProperty();
  }

  handleDeletePropertyButton = () => {
    this.props.handleDeletePropertyButton(this.props.propertyIndex);
  };

  render() {
    const {address,zip,stateCode,countyName,cityName,zipCodeErrMsg,showFields} = this.props.propertyInfo;
    return (
      <Fragment>
        <div className="detail-section loan-pg padd-10" key={`SubjectProperty_${this.props.propertyIndex}`}>
              <div class="heading-section">
                <div class="heading-title">
                  <Typography variant="h5" gutterBottom>
                    Subject Property Address 
                  </Typography>
                    {!this.props.isEditMode ? 
                      (this.props.propertyIndex!==0 ? ( 
                        <div>
                        <Tooltip title="Delete" arrow>
                          <Fab size="small" color="primary" aria-label="delete" disabled={this.props.isEditMode}>
                            <DeleteIcon onClick={this.handleDeletePropertyButton} />
                          </Fab>
                        </Tooltip> </div>) :(<div>
                          <Fab size="small" color="primary" aria-label="add" disabled={this.props.isEditMode}>
                            <AddIcon onClick={this.displayProperty} />
                          </Fab>
                          <Button variant="text" onClick={this.displayProperty} disabled={this.props.isEditMode}>Add Another Property</Button>
                          </div>
                        )) : null
                    }
                </div>
              </div>
              <Grid fullWidth container class="loan-pg-sec">
                <Paper fullWidth elevation={2}>
                  <Grid container spacing={8}>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        required
                        key={`address_${this.props.propertyIndex}`}
                        name="address"
                        value={address}
                        onChange={(e) => this.handleChange(e)}
                        label="Street"
                        variant="standard"
                        disabled={this.props.isEditMode}
                      />
                      {!(!this.props.isFormTouched || (address !== "" && this.props.isFormTouched)) ? <Grid item xs={12}>
                          <Typography gutterBottom>
                            <span className="redTxt">Address is required</span>
                          </Typography>
                      </Grid> : null}

                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        required
                        key={`zip_${this.props.propertyIndex}`}
                        name="zip"
                        value={zip}
                        onChange={(e) => this.handleChange(e)}
                        label="Zip"
                        variant="standard"
                        disabled={this.props.isEditMode}
                      />
                       {(zip === "" ) ? <Grid item xs={12}>
                          <Typography key={`InfoMsg_${this.props.propertyIndex}`} gutterBottom>
                            <span className="redTxt">Please enter the zip code of the property to populate the state, city, and county</span>
                          </Typography>
                      </Grid> : null}
                      {!(!this.props.isFormTouched || (zip !== "" && this.props.isFormTouched)) ? <Grid item xs={12}>
                          <Typography gutterBottom key={`ReqMsg_${this.props.propertyIndex}`}>
                            <span className="redTxt">Zip is required</span>
                          </Typography>
                      </Grid> : null}
                      {
                        zipCodeErrMsg!=="" && showFields!==true?
                        <Typography gutterBottom key={`ErrMsg_${this.props.propertyIndex}`}>
                          <span className="redTxt">{zipCodeErrMsg}</span>
                        </Typography>:null
                      }
                    </Grid>
                    {
                      showFields ?
                      <Grid item xs={4}>
                      <TextField
                        fullWidth
                        required
                        key={`state_${this.props.propertyIndex}`}
                        label="State"
                        variant="standard"
                        value={stateCode}
                        InputProps={{ readOnly: true}} 
                        disabled={this.props.isEditMode}
                      />
                    </Grid>
                      :null
                    }
                    
                  </Grid>
                  {showFields ? 
                    <Grid container spacing={8}>
                      <Grid item xs={4}>
                          <TextField
                            fullWidth
                            required
                            name="cityName"
                            key={`city_${this.props.propertyIndex}`}
                            label="City"
                            value={cityName}
                            variant="standard"
                            onChange={(e) => this.handleChange(e)}
                            disabled={this.props.isEditMode}
                          />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          required
                          label="County"
                          key={`county_${this.props.propertyIndex}`}
                          value={countyName}
                          variant="standard"
                          InputProps={{ readOnly: true}} 
                          disabled={this.props.isEditMode}
                        />
                      </Grid>
                  </Grid>
                  :null}
                  {(showFields && this.props.propertyIndex === 0 && !this.props.isEditMode)  ? 
                      <Grid container spacing={8}>
                        <Grid item xs={4}>
                          <FormControlLabel
                            control={
                              <Switch
                                key={`currentSwitch_${this.props.propertyIndex}`}
                                checked={this.state.checkedB}
                                onChange={this.handleSwitchChange}
                                name="checkedB"
                                color="primary"
                                disabled={this.props.isEditMode}
                              />
                            }
                            label="Current Address same as Subject Property Address"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControlLabel
                            control={
                              <Switch
                                key={`mailingSwitch_${this.props.propertyIndex}`}
                                checked={this.state.checkedA}
                                onChange={this.handleMailingSwitchChange}
                                name="checkedA"
                                color="primary"
                                disabled={this.props.isEditMode}
                              />
                            }
                            label="Mailing Address same as Subject Property Address"
                          />
                        </Grid>
                      </Grid>
                  :null}
                </Paper>
              </Grid>
            </div>
        
      </Fragment>
    );
  }
}

export default SubjectPropertyDetails;
