import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Dropzone from 'react-dropzone';
import { createFileFromUrl, isImage } from './helpers'
import SnackbarContentWrapper from './SnackbarContentWrapper';
import DeleteDialogs from './DeleteDialogs';
import CustomSnackbar from "./CustomSnackbar";
import FileSaver from "file-saver";
import axios from 'axios';
//import UploadLoader from './UploadLoader';
import CustomLightbox from './CustomLightbox';
//import ArrowUpSVG from '../../assets/arrow-pointing-to-up.svg';
import './ImageDropzone.css';
import UploadImgIcon from '../../assets/upload-img-icon.png'

//import { currentUserId, currentUserName } from '../../../../Const/CommonConst';
import {documentUIName, documentImageName} from "../../common/util";
//let isAttachingFileFlag = false;
import { Guid } from 'guid-typescript';
class ImageDropzone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileObjects: [],
            openSnackBar: false,
            snackbarMessage: '',
            snackbarVariant: 'success',
            dropzoneText: props.dropzoneText,
            shouldDeleteImage: false,
            uploadFiles: [],
            flagFetch: false,
            message: '',
            deletedMsg: '',
            stickyMsg: '',
            isOpen: false,
            showImageUrl: '',
            //deleteImageLoader : false,
            //getImageLoader : false,
            selectedImageId: 0,
            disabledPrev: false,
            disabledNext: false,
            imageDetails:{}
        }
        this.uploadData = { "propertyImageUploadList": [] };
        this.propertyId = 0;
        this.fileObject = [];
        this.fileName = '';
        this.filesUrl = '';
        this.userId = JSON.parse(sessionStorage.getItem('currentUser')) ? JSON.parse(sessionStorage.getItem('currentUser')).userId : "42bf9ca3-e9a9-4517-8059-9eaf4f8e919d";
        this.file = '';
        this.getImageId = '';
        this.deleteImage = {};
        this.userId = JSON.parse(sessionStorage.getItem('currentUser')) ? JSON.parse(sessionStorage.getItem('currentUser')).userId : "42bf9ca3-e9a9-4517-8059-9eaf4f8e919d";
    }
    componentWillMount() {
        this.propertyId = window.location.search.split('=')[1];
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.propertyImagesDetail !== undefined) {
            if (this.props.propertyImagesDetail !== nextProps.propertyImagesDetail) {
                if (nextProps.propertyImagesDetail !== null) {
                    if (nextProps.propertyImagesDetail) {
                        this.fileObject = nextProps.propertyImagesDetail;
                        this.setState({ uploadFiles: this.fileObject });
                        this.props.loaderCallBackFunc(true);
                    }
                }
            }
        }

        if (nextProps.addPropertyBtn !== undefined) {
            if (nextProps.addPropertyBtn) {
                //this.setState({ uploadFiles: [], flagFetch: true });
            }
        }

        if (nextProps.cancelBtnClick !== undefined) {
            if (nextProps.cancelBtnClick) {
                if (this.state.flagFetch === false) {
                    this.props.resetCancelBtnClick(false);
                } else {
                    this.setState({ uploadFiles: [], flagFetch: false })
                    this.props.resetCancelBtnClick(false);
                }
            }
        }

    }

    async filesArray(urls) {
        try {
            for (const url of urls) {
                const file = await createFileFromUrl(url);
                const reader = new FileReader();
                // Comment for Update only.
                // reader.onload = (event) => {
                //     this.setState({
                //         fileObjects: this.state.fileObjects.concat({ file: file, data: event.target.result })
                //     });
                // };
                reader.readAsDataURL(file);
            }
        } catch (e) {
            console.log(e)
        }
    }
    componentDidMount() {
        this.filesArray(this.props.initialFiles);
    }
    componentWillUnmount() {
        if (this.props.clearOnUnmount) {
            // this.setState({
            //     fileObjects: []
            // })
        }
    }
    componentDidUpdate(prevProps) {
        //edit mode
        if(this.props.imageList !== prevProps.imageList){
            this.props.fetchUploadDetails(this.props.loanId).then(responseData => {
                let uploadedImageList=responseData.data.images.map((listItem,index)=>{
                    listItem['id'] = index
                    listItem['alreadyUploadedImage'] = true
                    return listItem;
                })
                this.setState({
                    imageDetails: uploadedImageList
                });
            });
        }
        if (this.props.dropzoneText !== prevProps.dropzoneText) {
            this.setState({
                dropzoneText: this.props.dropzoneText
            });
        }
    }
    splitString(str, num) {
        var fileSplittedDataList = [];
        for (var i = 0; i < str.length; i += Math.round(str.length/num)) {
          fileSplittedDataList.push(str.substring(i, i + Math.round(str.length/num)));
        }
        return fileSplittedDataList;
    }
    onDrop(files) {
        //this.setState({ getImageLoader : true});
        this.props.loaderCallBackFunc(true);
        const _this = this;
        files = files && files.filter(file => isImage(file));

        files = files && files.length ? files : [];

        files = files.filter((item) => {
            return isImage(item)
        })
        if (this.state.fileObjects.length + files.length > this.props.filesLimit) {
            this.setState({
                openSnackBar: true,
                snackbarMessage: this.props.getFileLimitExceedMessage(this.props.filesLimit),
                snackbarVariant: 'error'
            });
        }
        else if (files && files[0]) {
            var count = 0;
            var message = '';
            this.fileName = files[0].name;
            let totalFileSize = 0;
            files.forEach((file) => { totalFileSize = totalFileSize + file.size; });
            let uploadedFilesLength = files.length;
            if(totalFileSize <= 500000000) {
                files.forEach((file) => {
                    let modifiedFileName = documentImageName(file.name, Guid.create());
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        this.filesUrl = event.target.result;
                        _this.setState({
                            fileObjects: _this.state.fileObjects.concat({ file: file, data: event.target.result })
                        }, () => {
                            if (this.props.onChange) {
                                this.props.onChange(_this.state.fileObjects.map(fileObject => fileObject.file));
                            }
                            if (this.props.onDrop) {
                                this.props.onDrop(file)
                            }
                        });
                        
                        let reqJson = {
                          "loanId": this.props.loanId,
                          "document": [
                            {
                              "uploadedFileName": modifiedFileName,
                              "uploadType": "image"
                            }
                          ]
                        }

                        this.props.fetchUploadDocumentUrl(reqJson)
                        .then(async res => {
                            const url = res.data[0].url;
                            const nextUploadFile = file;
                            this.props.updateUploadedImages(url, file);
                            try {
                            const response = await fetch(url, {
                                method: 'PUT',
                                body: nextUploadFile,
                                headers: new Headers({
                                'Content-Type': ''
                                })
                            })
                            if (response.status === 200) {
                                this.props.postImageDetails(reqJson).then(resData=>{
                                    message += this.props.getFileAddedMessage(file.name);
                                    count++;
                                    if (count === files.length) {
    
                                        this.setState({
                                            openSnackBar: true,
                                            snackbarMessage: message,
                                            snackbarVariant: 'success'
                                        });
                                    }
                                    if(count === uploadedFilesLength){
                                        this.props.fetchUploadDetails(this.props.loanId).then(responseData => {
                                            let updatedUploadedImageList=[]
                                            // if(this.props.imageList[0].fileName!==""){
                                            if(this.props.imageList.length>0){
                                                let results1 = responseData.data.images.filter(({ fileName: id1 }) => !this.props.imageList.some(({ fileName: id2 }) => id2 === id1));
                                                let results2 = responseData.data.images.filter(({ fileName: id1 }) => this.props.imageList.some(({ fileName: id2 }) => id2 === id1));
                                                let setFlagInImageList1=results1.map(obj=> ({ ...obj, alreadyUploadedImage: false }))
                                                let setFlagInImageList2=results2.map(obj=> ({ ...obj, alreadyUploadedImage: true }))
                                                updatedUploadedImageList=[...setFlagInImageList1,...setFlagInImageList2]
                                            }else{
                                                updatedUploadedImageList=[...responseData.data.images]
                                            }
                                            //ask reasons to Deepa mam why add id and not use in imagelist in state
                                            let uploadedImageList=updatedUploadedImageList.map((obj,index)=> ({ ...obj, id: index }));

                                            // let imagesList = responseData.data.images;
                                            // for(let i=0;i<imagesList.length;i++){
                                            //     imagesList[i]["id"]=i;
                                            // }
                                            
                                            this.setState({
                                                imageDetails: uploadedImageList
                                            });
                                            //this.setState({ getImageLoader : false});
                                            this.props.loaderCallBackFunc(false);
                                        });
                                        this.props.setSnackbar('success', 'Images uploaded Successfully!');
                                    }
                                });
                               
                            }
                            } catch (e) {
                            this.props.setSnackbar('error', `${modifiedFileName} upload failed!`)
                            }
                        });
    
                        //this.propertyId = 0; //this.props.propertyInformation.propertyId;
                        // if (this.propertyId !== '0') {
                        //     this.uploadData = [
                        //         {
                        //           "uploadedFileName": this.fileName,
                        //           "createdBy": '',
                        //           "createdByUserInitial": '',
                        //           "isValidated": true,
                        //           "imageData": ""
                        //         }
                        //     ];
    
                        //     this.setState({showLoader : true});
                            //POST
                            // this.props.uploadImage(this.uploadData).then(res => {
                            //     if (res.status === 200) {
                            //         message += this.props.getFileAddedMessage(file.name);
                            //         count++;
                            //         if (count === files.length) {
                            //             this.setState({
                            //                 openSnackBar: true,
                            //                 snackbarMessage: message,
                            //                 snackbarVariant: 'success',
                            //                 //showLoader : false,
                            //             });
                            //         }
                            //         let uploadedFileId = res.data[0].uploadedFileId;
                            //         let uploadedFileName = res.data[0].uploadedFileName;
                            //         //PUT
                            //         let data=this.props.uploadImageByUrl({ uploadUrl: res.data[0].url, file: file }, res.headers['x-correlation-id']).then((response)=>{
                            //             if(data){
                            //                 let obj = [
                            //                     {
                            //                       "uploadedFileId": uploadedFileId,
                            //                       "uploadedFileName": uploadedFileName,
                            //                       "createdBy": '',
                            //                       "createdByUserInitial": '',
                            //                       "isValidated": true,
                            //                       "imageData": binaryStr
                            //                     }
                            //                 ];
                            //                 this.props.saveImage(obj, this.props.propertyInformation.propertyId, res.headers['x-correlation-id']).then((resp)=>{
                            //                     //this.props.refreshPropertyList();
                            //                     this.props.getPropertyImagesDetails(this.props.propertyInformation.propertyId).then((responseProperty)=>{
                            //                         this.setState({
                            //                             showLoader : false,
                            //                         });
                            //                     });
                            //                 })
                            //             }
                            //         })
    
                            //     }
                            //     this.props.getPropertyImagesDetails(this.propertyId).then(resData => {
                            //         if (resData.data)
                            //             this.setState({ uploadFiles: resData.data.propertyImages })
                            //         else
                            //             this.setState({ uploadFiles: [] })
                            //         //this.props.imageUploadCallBackFunction(false);
                            //     });
    
                            // })
    
    
                        //} 
                        // else {
                        //     this.props.addProperty(addPropertyObj).then(res => {
                        //         this.propertyId = res.data.propertyDetailsResult;
                        //         if (this.props.history) {
                        //             this.props.history.push({
                        //                 search: '?propertyId=' + res.data.propertyDetailsResult
                        //             })
                        //         }
                        //         if (this.propertyId) {
                        //             this.uploadImageObj = {
                        //                 "propertyImageUploadList": [{
                        //                     "propertyId": this.propertyId,
                        //                     "imageData": this.filesUrl,
                        //                     "fileName": this.fileName,
                        //                     "userId": this.userId,
                        //                 }]
                        //             };
                        //             this.props.uploadImage(this.uploadImageObj).then(uploadRes => {
                        //                 if (uploadRes.status === 200) {
                        //                     message += this.props.getFileAddedMessage(file.name);
                        //                     count++;
                        //                     if (count === files.length) {
    
                        //                         this.setState({
                        //                             openSnackBar: true,
                        //                             snackbarMessage: message,
                        //                             snackbarVariant: 'success'
                        //                         });
                        //                     }
                        //                 }
                        //                 this.props.getPropertyImagesDetails(this.propertyId).then(imageRes => {
                        //                     if (imageRes.data)
                        //                         this.setState({ uploadFiles: imageRes.data })
                        //                     else
                        //                         this.setState({ uploadFiles: [] })
                        //                     //this.props.imageUploadCallBackFunction(false);
                        //                 });
                        //             })
    
                        //         }
                        //     })
                        // }
                    };
                    reader.readAsDataURL(file);
                })
            }
            else {
                //this.setState({ getImageLoader : false});
                this.props.loaderCallBackFunc(false);
                //this.setState({ stickyMsg: "Total size limit is 500 megabytes", deleteImageLoader : false, snackbarVariant: 'error' });
                this.setState({
                    openSnackBar: true,
                    snackbarMessage: "Total size limit is 500 megabytes",
                    snackbarVariant: "error"
                });
            }
        }
    }
    handleRemove = (fileId, fileName) => {
        //this.setState({ getImageLoader : true});
        //this.props.loaderCallBackFunc(true);
        let req = {
            "uploadedFileId": fileId,
            "uploadedFileName": fileName,
            "createdBy": '',
            "createdByUserInitial": '',
            "isValidated": true,
            "imageData": ""
          }
        this.setState({ shouldDeleteImage: true, deleteImage: req, message: "Do you want to delete this image ?" });
    }

    callbackDeleteDialog = () => {
        //this.setState({deleteImageLoader : true});
        this.props.loaderCallBackFunc(true);
        let reqJson = {
            "loanId": this.props.loanId,
            "document": [
              {
                "uploadedFileName": this.state.deleteImage.uploadedFileName,
                "uploadType": "image"
              }
            ]
          }
        this.props.deleteUploadedDocument(reqJson).then(res => {
            if (res.status === 200 || res.status === 204) {
                let fileNames = documentUIName(this.state.deleteImage.uploadedFileName);
                //this.props.setSnackbar('success', fileNames + ' deleted successfully !');
                this.setState({
                  openSnackBar: true,
                  snackbarMessage: this.props.getFileRemovedMessage(fileNames),
                  snackbarVariant: "success"
                });
            }
            this.props.fetchUploadDetails(this.props.loanId).then(async responseData => {
                let newUploadedList=[]
                if(this.props.imageList.length>0){ let data1 = responseData.data.images.filter(({ fileName: id1 }) => !this.props.imageList.some(({ fileName: id2 }) => id2 === id1));
                    let data2 = responseData.data.images.filter(({ fileName: id1 }) => this.props.imageList.some(({ fileName: id2 }) => id2 === id1));
                    let imageListSetFlag1=data1.map(obj=> ({ ...obj, alreadyUploadedImage: false }))
                    let imageListSetFlag2=data2.map(obj=> ({ ...obj, alreadyUploadedImage: true }))
                    newUploadedList=[...imageListSetFlag1,...imageListSetFlag2]
                }else{newUploadedList=[...responseData.data.images]}
                let uploadedImageList=newUploadedList.map((obj,index)=> ({ ...obj, id: index }));

                this.setState({imageDetails: uploadedImageList});

                // this.setState({
                //     imageDetails: responseData.data.images
                // });
                //this.setState({ getImageLoader : false});
                this.props.loaderCallBackFunc(false);
            });
        })
        this.setState({ shouldDeleteImage: false })
    }

    callbackFunction = childData => {
        this.setState({ open: false, stickyMsg: '' });
    };

    handleNoCallBack = () => {
        this.setState({ shouldDeleteImage: false, deleteImage: {} });
    }

    handleDropRejected(rejectedFiles, evt) {
        var message = '';
        rejectedFiles.forEach((rejectedFile) => {
            message = this.props.getDropRejectMessage(
                rejectedFile,
                this.props.acceptedFiles,
                this.props.maxFileSize
            );
        });
        if (this.props.onDropRejected) {
            this.props.onDropRejected(rejectedFiles, evt);
        }
        this.setState({
            openSnackBar: true,
            snackbarMessage: message,
            snackbarVariant: 'error',
            //getImageLoader : false
        });
        this.props.loaderCallBackFunc(false);
    }
    onCloseSnackbar = () => {
        this.setState({
            openSnackBar: false,
        });
    };

    downLoadImage = (id, imageUrl) => { 
        //large image get method
        //let fileName =imageName.split('_')[1]; 
        //if(imageData === ""){
            // let data = imageUrl
            // this.downloadFile(id, fileName, data);
        // } else{
            let data = imageUrl;
            let disabledPrev = (id === 0) ? true : false;
            let disabledNext = id === (this.state.imageDetails.length - 1) ? true : false; 
            this.setState({ isOpen: true, showImageUrl: data, selectedImageId: id, disabledPrev: disabledPrev, disabledNext: disabledNext  });
        // }
    }


    downloadFile = (fieId, fileName, url) => {
                axios.get(url, {
                  responseType: 'blob',
                }).then(response => {
                  if(response && response.data) {
                    FileSaver.saveAs(response.data,fileName);
                }
        });
    }


    handleCloseLightbox = () => {
        this.setState({ isOpen: false });
    }

    togglePrev = (e) => {
        this.setState({ showImageUrl: ''})
        let selectedImageId = this.state.selectedImageId - 1;
        let disabledPrev = (selectedImageId === 0);
        let selectedFile = this.state.imageDetails.find((item,index) => index === selectedImageId)
        this.setState({ showImageUrl: selectedFile.imageUrl, selectedImageId: selectedImageId, disabledPrev: disabledPrev, disabledNext: false })
      }
    
    toggleNext = (e) => {
        this.setState({ showImageUrl: ''})
        let selectedImageId = this.state.selectedImageId + 1;
        let disabledNext = selectedImageId === (this.state.imageDetails.length - 1);
        let selectedFile = this.state.imageDetails.find((item,index) => index === selectedImageId)
        // this.downLoadImage(selectedFile.imageData, selectedFile.imageUploadedFileName, selectedImageId, selectedFile.imagePath )
        // this.setState({ isOpen: true, showImageUrl: data, selectedImageId: id, disabledPrev: disabledPrev, disabledNext: disabledNext  });
        this.setState({ showImageUrl: selectedFile.imageUrl, selectedImageId: selectedImageId, disabledNext: disabledNext, disabledPrev: false })
    }

    render() {
        // const showPreviews = this.props.showPreviews && this.state.fileObjects.length > 0;
        // const showPreviewsInDropzone = this.props.showPreviewsInDropzone && this.state.fileObjects.length > 0;
        // const getImages = this.props.viewPropertyDetail.propertyImages
        return (
            <Fragment>
                {this.state.shouldDeleteImage === true ?
                    <DeleteDialogs {...this.props} deleteCallBack={this.callbackDeleteDialog} close={this.handleNoCallBack} fileId={this.state.deleteImage.uploadedFileId} open={this.state.shouldDeleteImage} msg={this.state.message} deletedMsg={this.state.deletedMsg} /> : ''
                }
                <CustomLightbox 
                 isOpen={this.state.isOpen}
                 showImageUrl={this.state.showImageUrl}
                 handleCloseLightbox={this.handleCloseLightbox}
                 uploadFiles={this.state.uploadFiles}
                 downLoadImage={this.downLoadImage}
                 disabledPrev={this.state.disabledPrev}
                 disabledNext={this.state.disabledNext}
                 togglePrev={this.togglePrev}
                 toggleNext={this.toggleNext}
                />
                <span className={window.location.search && window.location.search.split('=').length > 0 && window.location.search.split('=')[1] && window.location.search.split('=')[1] !== 0 ? "imagesContainer" : "imagesContainer disabledSectionHidden"}>
                    <Dropzone
                        disabled={this.props.readonly}
                        accept={this.props.acceptedFiles.join(',')}
                        onDrop={this.onDrop.bind(this)}
                        onDropRejected={this.handleDropRejected.bind(this)}
                        className={'image-dropzone-wrapper'}
                        acceptClassName={'image-dropzone-stripes'}
                        rejectClassName={'image-dropzone-reject'}
                        maxSize={this.props.maxFileSize}
                        
                    >
                    <div className={'image-dropzone-uploader'}>
                            <p className={'image-dropzone-text'}>
                                <img src={UploadImgIcon} alt='Upload' className={'image-dropzone-icon'} />
                            </p>
                            
                        </div> 
                   </Dropzone>
                   {/* {this.state.getImageLoader && ( */}
                    {/* {this.state.getImageLoader  && (<UploadLoader />)} */}
                         {this.state.imageDetails && this.state.imageDetails.length > 0 && Array.isArray(this.state.imageDetails)  && this.state.imageDetails.map((elem) => {
                                let id =  elem.id;
                                let imageSrcUrl =  'data:image/' + elem.fileName + ';base64,' + elem.imageData; //elem.imageUrl ;
                                return (<div style={{'height':'200px','width':'200px'}} className="singleImage" key={'row' + id}>
                                {/* // {!this.props.readonly && elem.alreadyUploadedImage? */}
                                    {!elem.alreadyUploadedImage?
                                                (<div className={"deleteButton"} onClick={(event) => { this.handleRemove(elem.imageId, elem.fileName) }}>
                                                    <span className='delete-icon'/>
                                                </div>) : null}
                                                <img style={{'max-height':'100%','max-width':'100%'}} className={'dropzone-img'} id={id} src={imageSrcUrl} alt='dropzone-img' onClick={(event) => { this.downLoadImage( id ,elem.imageUrl) }}/>
                                        </div>)
                                }) }
             {/* {this.state.showLoader ?
            <div className="uploadingProgress">
              <span className="uploadingText">Uploading</span>
              <span className="dummyLoader"><LoaderComponents /></span>
            </div>
            : null} */}
                
            </span>
                {this.props.showAlerts &&
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={this.state.openSnackBar}
                        autoHideDuration={6000}
                        onClose={this.onCloseSnackbar}
                        className={`${this.state.snackbarVariant}`}
                    >
                        <SnackbarContentWrapper
                            onClose={this.onCloseSnackbar}
                            variant={this.state.snackbarVariant}
                            message={this.state.snackbarMessage}
                        />
                    </Snackbar>
                }

                {this.state.stickyMsg !== "" ? (
                    <CustomSnackbar
                        message={this.state.stickyMsg}
                        parentCallback={this.callbackFunction}
                        snackbarVariant={this.state.snackbarVariant}
                    />
                ) : (
                        ""
                    )}
            </Fragment>
        )
    }
}

ImageDropzone.defaultProps = {
    acceptedFiles: ["image/jpg", "image/jpeg", "image/png", "image/bmp"],
    maxFileSize: 50000000,
    dropzoneText: 'UPLOAD PICTURE',
    showPreviews: false,
    showPreviewsInDropzone: true,
    showFileNamesInPreview: false,
    showAlerts: true,
    clearOnUnmount: true,
    initialFiles: [],
    getFileLimitExceedMessage: (filesLimit) => (`Maximum allowed number of files exceeded. Only ${filesLimit} allowed`),
    getFileAddedMessage: (fileName) => (`File "${fileName}" successfully added.\n\r`),
    getFileRemovedMessage: (fileName) => (`File successfully removed.`),
    getDropRejectMessage: (rejectedFile, acceptedFiles, maxFileSize) => {
        let message = `File ${rejectedFile.name} was rejected. `;
        if (rejectedFile.size > maxFileSize) {
            message += 'File is too big. Size limit is 50 megabytes. ';
        } else {
            if (!acceptedFiles.includes(rejectedFile.type)) {
                message += 'File type not supported. '
            }
        }
        return message;
    },
    onChange: () => { },
    onDrop: () => { },
    onDropRejected: () => { },
    onDelete: () => { }
};
ImageDropzone.propTypes = {acceptedFiles: PropTypes.array,
    filesLimit: PropTypes.number,maxFileSize: PropTypes.number,
    dropzoneText: PropTypes.string,dropzoneClass: PropTypes.string,
    showPreviews: PropTypes.bool,showPreviewsInDropzone: PropTypes.bool,
    showFileNamesInPreview: PropTypes.bool,showAlerts: PropTypes.bool,
    clearOnUnmount: PropTypes.bool,initialFiles: PropTypes.arrayOf(PropTypes.string),
    getFileLimitExceedMessage: PropTypes.func,getFileAddedMessage: PropTypes.func,
    getFileRemovedMessage: PropTypes.func,getDropRejectMessage: PropTypes.func,
    onChange: PropTypes.func,onDrop: PropTypes.func,
    onDropRejected: PropTypes.func,onDelete: PropTypes.func
};
export default ImageDropzone;
