// export const getApplicationDetailsApi = 'get'; //mock-api-url
// export const saveApplicationDetailsApi = 'post'; //mock-api-url

export const getMartialStatusListAPI = '';
export const getAllPortalDataAPI = 'masterDataList';
export const getCaptchaDataAPI = 'Get';
export const postLoanSummaryAPI = 'loansummary';
export const getUploadDocumentUrlAPI = 'Upload/GetUrl';
export const deleteDocumentUrlAPI = 'Upload/Delete';
export const downloadDocumentUrlAPI = 'Download/Get';
export const getUploadDetailsAPI = 'Upload/Get';
export const postImageDetailsAPI = 'Upload/Post';
export const getLoanSummaryByIdAPI = 'loansummarydata';
export const loginLoanPortalAPI = 'getloansummary';
