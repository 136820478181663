import { connect } from 'react-redux';
import Dashboard from  '../components/dashboard';

// import {
// 	showLoader,
// 	hideLoader
// } from '../actions/loaderAction';

import {
	showLoader,
	hideLoader
} from '../actions/loaderAction';

import {
	errorSnackbar
} from '../actions/customSnackBarAction';

import { snackBarMessages } from '../const/commonConst';

import { 
	updateLoanDetails,
	loginLoanPortal,
	loginLoanPortalSuccess,
	loginLoanPortalFailure,
	updateAppMode
 } from '../actions/dashboardAction';

import {
	getCaptchaImage,
	getCaptchaImageSuccess,
	getCaptchaImageFailure,
	updateCaptchaText,
} from '../actions/applicationDetailsAction';

export const mapStateToProps = (state) => {
	return {
		loanDetails: state.dashboard.loanDetails,
		captchaImageData: state.applicationDetails.captchaImageData,
		modeInfo: state.dashboard.modeInfo,
		applicationDetails: state.dashboard.applicationDetails,
		captchaText: state.applicationDetails.captchaText,
		error:state.applicationDetails.error,
		loading: state.loader.status,
	}
}

export const mapDispatchToProps = (dispatch) => {
	return {
        updateLoanDetails: (data) => {
			dispatch(updateLoanDetails(data));
		},
		getCaptchaImage: () => {
			return new Promise((resolve, reject) => {
				try {
					//dispatch(showLoader())
					dispatch(getCaptchaImage())
						.then((response) => {
							dispatch(getCaptchaImageSuccess(response.payload.data))
							resolve(response.payload.data)
							//dispatch(hideLoader())
						})
						.catch((error) => {
							dispatch(getCaptchaImageFailure(error))
							//dispatch(hideLoader())
						})
				} catch (error) {
					reject(error)
				}
			},
		)},
		loginLoanPortal: (reqJson, captchaText, captchaData) => {
			return new Promise((resolve, reject) => {
				try {
					dispatch(showLoader())
					dispatch(loginLoanPortal(reqJson, captchaText, captchaData))
						.then((response) => {
							dispatch(loginLoanPortalSuccess(response.payload.data))
							resolve(response.payload.data)
							dispatch(hideLoader())
						})
						.catch((error) => {
							dispatch(loginLoanPortalFailure(error))
							if(error==="invalidCaptcha"){
								// dispatch(getCaptchaImage()).then((response) => {
								// 	dispatch(updateCaptchaText(""));
								// 	resolve(response.payload.data)
								// 	//dispatch(hideLoader())
								// 	//dispatch(updateCaptchaText(""));
								// });
								
								dispatch(errorSnackbar(snackBarMessages.captchaError))
							}
							else if(error==="InValidLoanNumber") {
								dispatch(errorSnackbar(snackBarMessages.loanNoError))
							}else{
								//dispatch(errorSnackbar(snackBarMessages.error))
							}
							dispatch(hideLoader())
						})
				} catch (error) {
					reject(error)
				}
			},
		)},
		updateCaptchaText: (data) => {
			dispatch(updateCaptchaText(data));
		},
		updateAppMode: (modeInfo) => {
			dispatch(updateAppMode(modeInfo));
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
