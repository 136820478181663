//create root reducer, register all your reducer here.
import { combineReducers } from 'redux';
import loaderReducer from './loaderReducer';
import customSnackBarReducer from './customSnackBarReducer';
import applicationDetailsReducer from './applicationDetailsReducer';
import dashboardReducer from './dashboardReducer';

const rootReducer = combineReducers({
    loader: loaderReducer,
    snackBar: customSnackBarReducer,
    applicationDetails: applicationDetailsReducer,
    dashboard: dashboardReducer
   
});

export default rootReducer;