import { BrowserRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import { Provider } from 'react-redux';
import configureStore from './store/index';

import ApplicationDetailsContainer from './containers/applicationDetailsContainer';
import DashboardContainer from './containers/dashboardContainer';
import ThankyouContainer from './containers/thankyouContainer';
import PageNotFound from './common/pageNotFound';

const store = configureStore()

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route exact path="(/taxease/)" component={() => <div className="app-body"><DashboardContainer /></div>} />
          <Route exact path="/taxease/application-details" component={() => <div className="app-body inner-page"><ApplicationDetailsContainer /></div>} />
          <Route exact path="/taxease/thankyou" component={() => <div className="app-body inner-page"><ThankyouContainer /></div>} />
          <Route exact path="/taxease/:id" component={() => <div className="app-body"><DashboardContainer /></div>} />
          <Route path="*" component={() => <div className="app-body not-found"><PageNotFound /></div>} /> 
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
