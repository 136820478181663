import React from 'react';
import MaskedInput from "react-text-mask";
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const defaultMaskOptions = {
    prefix: '$ ',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 12, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false
  }

export function CurrencyInputSymbol(props) {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={createNumberMask({...defaultMaskOptions,})}
        placeholderChar={"_"}
      />
    );
}