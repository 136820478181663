import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PrevArrowSVG from '../../assets/backArrow.svg';
import NextArrowSVG from '../../assets/fordwardArrow.svg';


const CustomLightbox = (props) => {
    const { isOpen, showImageUrl, handleCloseLightbox, uploadFiles, downLoadImage, disabledPrev, disabledNext, togglePrev, toggleNext } = props;
    return (
        <Dialog className="custom-lightbox-wrapper" open={isOpen}>
            <div className='lightbox-content-wrapper'>
                <div className='lightbox-header'>
                    <IconButton aria-label="close" id='crossIcon' className="closeBtn" onClick={handleCloseLightbox}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className='lightbox-body'>
                    {showImageUrl && <img className="imageSize" src={showImageUrl} alt={'selected-img'} />}
                </div>
                <div className='lightbox-footer thumbnail-wrapper'>
                {
                    uploadFiles && uploadFiles.length > 0 && Array.isArray(uploadFiles)  && uploadFiles.map((elem, id) => {
                        let imageName = elem.imageUploadedFileName.split('.')[1];
                        let imageSrcUrl = 'data:image/' + imageName + ';base64,' + elem.imageData;
                        return (<div className="thumbnail-item" key={'row' + id}>
                                    <img className={'thumbnail-img'} id={id} src={imageSrcUrl} alt='thumbnail-img' onClick={(event) => { downLoadImage(elem.imageData,elem.imageUploadedFileName, id, elem.imagePath) }}/>
                                </div>)
                        })
                } 
                </div>
            </div>
            <div className='lightbox-controls-wrapper'>
               <div className='next-prev-section'>
                    <Prev toggle={togglePrev} active={disabledPrev} />
                    <Next toggle={toggleNext} active={disabledNext} />
               </div> 
            </div>
        </Dialog>
    )
}

export default CustomLightbox;


function Prev(props) {
    return (
      <Button onClick={props.toggle} variant="contained" disabled={props.active} className="back-arrow">
           <img src={PrevArrowSVG} alt="Prev Arrow" />
      </Button>
    );
}
  
function Next(props) {
    return (
        <Button onClick={props.toggle} variant="contained" disabled={props.active} className="fordward-arrow">
             <img src={NextArrowSVG} alt="Next Arrow" />
        </Button>
    );
}
  
  


