class APIConfig {
    static get ROOT_URL() { return localStorage.getItem('loanApplicationPortalApi'); }

    static loadAPIConfig() {
        if (window.appConfig) {
            localStorage.removeItem('loanApplicationPortalApi');
            var loanApplicationPortalApi = window.appConfig.loanApplicationPortalApi;
            localStorage.setItem('loanApplicationPortalApi', loanApplicationPortalApi);
        }
    }
}

export default APIConfig;
