import React, { Component, Fragment } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
export class PropertyAddressDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autoCompleteSearchValue: '',
      autoCompleteCitySearchValue:'',
      autoCompleteCountySearchValue:'',
      statelabel:'State',
      citylabel:'City',
      countylabel:'County'
    };
  }

  handleChange = (e) => {
    this.props.handleAddressChange(e.target.id,e.target.value,this.props.sectionName);
  };

  handleStateSearch = (searchValue) => {
    this.setState({ autoCompleteSearchValue: searchValue })
  }

  handleStateChange = (selectedValue) => {
    if(selectedValue !== null) {
        let selectedRecord = this.props.stateList.find((item) => item.Description === selectedValue);
        if(selectedRecord){
          this.props.handleStateChange('stateId',selectedRecord.Id,this.props.sectionName);
        }
    }
    else {
      this.props.handleStateChange('stateId',0,this.props.sectionName);
    }
  }

  handleCitySearch = (searchValue) => {
    this.setState({ autoCompleteCitySearchValue: searchValue })
  }

  handleCityChange = (selectedValue,fieldName) => {
    if(selectedValue !== null) {
        let selectedRecord = fieldName==="cityId" ?
          this.props.cityList.find((item) => item.Name === selectedValue):
          this.props.countyList.find((item) => item.Name === selectedValue);
        if(selectedRecord){
          this.props.handleAddressChange(fieldName,selectedRecord.Id,this.props.sectionName);
        }
    }
    else {
      this.props.handleAddressChange(fieldName,0,this.props.sectionName);
    }
  }

  handleCountyChange = (searchValue) => {
    this.setState({autoCompleteCountySearchValue:searchValue});
  }
  
  render() {
    const { address,  zip, stateCode, cityName, countyName} = this.props.addressInfo;
    return (
      <Fragment>
        <div className="detail-section loan-pg padd-10">
              <div class="heading-section">
                <div class="heading-title">
                  <Typography variant="h5" gutterBottom>
                    {this.props.heading} 
                  </Typography>
                </div>
              </div>
              <Grid fullWidth container class="loan-pg-sec">
                <Paper fullWidth elevation={2}>
                  <Grid container spacing={8}>
                    <Grid item xs={4}>
                      <TextField
                        id="address"
                        value={address}
                        key={`common_${this.props.sectionName+this.props.propertyIndex}`}
                        onChange={this.handleChange}
                        fieldName='address'
                        disabled={(this.props.isCurrentAddressReadOnly && this.props.sectionName==="curAddress")
                         || (this.props.isMailingAddressReadOnly && this.props.sectionName==="propAddress") ||
                         this.props.isEditMode }
                        fullWidth
                        required
                        label="Street"
                        variant="standard"
                      />
                      {!(!this.props.isFormTouched || (this.props.addressInfo.address !== "" && this.props.isFormTouched)) ? <Grid item xs={12}>
                          <Typography gutterBottom>
                            <span className="redTxt">Address is required</span>
                          </Typography>
                      </Grid> : null}
                    </Grid>
                    <Grid item xs={4}>
                    {
                      this.props.isEditMode || (this.props.isCurrentAddressReadOnly && this.props.sectionName==="curAddress")
                         || (this.props.isMailingAddressReadOnly && this.props.sectionName==="propAddress")?
                        <TextField
                          id="stateCode"
                          value={stateCode}
                          fieldName='address' disabled={true}
                          fullWidth required
                          label="State" variant="standard"
                        />:
                        <Autocomplete
                            id="stateId"
                            key={`commonState_${this.props.sectionName+this.props.propertyIndex}`}
                            options={this.props.stateList.map((item) => item.Description)}
                            value={stateCode}
                            onChange={(event, newInputValue)=> this.handleStateChange(newInputValue)}
                            inputValue={this.state.autoCompleteSearchValue}
                            onInputChange={(event, newInputValue)=> this.handleStateSearch(newInputValue)}
                            required
                            includeInputInList
                            popupIcon={<ExpandMoreIcon />}
                            renderInput={(params) => <TextField {...params} 
                            label={<div>{this.state.statelabel}{<span className="redTxt"> *</span>}</div>}/>}
                        />
                    }
                    {!(!this.props.isFormTouched || (this.props.addressInfo.stateId !== 0 && this.props.isFormTouched)) ? <Grid item xs={12}>
                          <Typography gutterBottom>
                            <span className="redTxt">State is required</span>
                          </Typography>
                      </Grid> : null}
                    </Grid>

                    <Grid item xs={4}>
                      {
                        this.props.isEditMode || (this.props.isCurrentAddressReadOnly && this.props.sectionName==="curAddress")
                              || (this.props.isMailingAddressReadOnly && this.props.sectionName==="propAddress") ?
                          <TextField
                            id="cityName"
                            value={cityName}
                            disabled={true}
                            fullWidth required
                            label="City"
                            variant="standard"
                          /> :
                          <Autocomplete
                            id="cityId"
                            key={`commonCity_${this.props.sectionName+this.props.propertyIndex}`}
                            options={this.props.cityList.map((item) => item.Name)}
                            value={cityName}
                            onChange={(event, newInputValue)=> this.handleCityChange(newInputValue,'cityId')}
                            inputValue={this.state.autoCompleteCitySearchValue}
                            onInputChange={(event, newInputValue)=> this.handleCitySearch(newInputValue)}
                            required
                            includeInputInList
                            popupIcon={<ExpandMoreIcon />}
                            renderInput={(params) => <TextField {...params} 
                            label={<div>{this.state.citylabel}{<span className="redTxt"> *</span>}</div>}/>}
                          />
                      }
                      {!(!this.props.isFormTouched || (this.props.addressInfo.cityId !== 0 && this.props.isFormTouched)) ? <Grid item xs={12}>
                              <Typography gutterBottom>
                                <span className="redTxt">City is required</span>
                              </Typography>
                          </Grid> : null}
                    </Grid>
                  </Grid>

                  <Grid container spacing={8}>
                    <Grid item xs={4}>
                    {
                      this.props.isEditMode || (this.props.isCurrentAddressReadOnly && this.props.sectionName==="curAddress")
                            || (this.props.isMailingAddressReadOnly && this.props.sectionName==="propAddress")?
                        <TextField
                            id="countyName"
                            value={countyName}
                            fieldName='countyName'
                            disabled={true} fullWidth
                            required label="City"
                            variant="standard"
                        /> :
                        <Autocomplete
                            id="countyId"
                            key={`commonCounty_${this.props.sectionName+this.props.propertyIndex}`}
                            options={this.props.countyList.map((item) => item.Name)}
                            value={countyName}
                            onChange={(event, newInputValue)=> this.handleCityChange(newInputValue,'countyId')}
                            inputValue={this.state.autoCompleteCountySearchValue}
                            onInputChange={(event, newInputValue)=> this.handleCountyChange(newInputValue)}
                            required
                            includeInputInList
                            popupIcon={<ExpandMoreIcon />}
                            renderInput={(params) => <TextField {...params} 
                            label={<div>{this.state.countylabel}{<span className="redTxt"> *</span>}</div>}/>}
                          />
                    }
                    {!(!this.props.isFormTouched || (this.props.addressInfo.countyId !== 0 && this.props.isFormTouched)) ? <Grid item xs={12}>
                          <Typography gutterBottom>
                            <span className="redTxt">County is required</span>
                          </Typography>
                      </Grid> : null}
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        id="zip"
                        value={zip}
                        onChange={this.handleChange}
                        disabled={(this.props.isCurrentAddressReadOnly && this.props.sectionName==="curAddress")
                         || (this.props.isMailingAddressReadOnly && this.props.sectionName==="propAddress")
                         ||this.props.isEditMode}
                        fullWidth
                        required
                        label="Zip"
                        variant="standard"
                      />
                      {!(!this.props.isFormTouched || (this.props.addressInfo.zip !== "" && this.props.isFormTouched)) ? <Grid item xs={12}>
                          <Typography gutterBottom>
                            <span className="redTxt">Zip is required</span>
                          </Typography>
                      </Grid> : null}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </div>
      </Fragment>
    );
  }
}

export default PropertyAddressDetails;
