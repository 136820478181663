import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './SelectDropDown.css';

export default function SelectDropDown(props) {
  let { id, label, initialValue, fieldName, dropDownDataList, component, isRequired, isDisabled } = props;
  const [values, setValues] = React.useState(initialValue);
  const handleChange = event => {
    if(component !== "manage-contact"){
      setValues(oldValues => ({
        ...oldValues,
        [event.target.name]: event.target.value,
      }));
    }
    if( label === "Marital Status" || label === "State" || label === "County" || label === "City"
        || label === "Is there a mortgage on the property?" || label === "Are you a prior customer?"
        ||label==="Language" ) {
      props.setSelectedValue(event);
    }

  };

  return (
    <FormControl required={isRequired} className="adv-srch-select-state" disabled={isDisabled}>
      <InputLabel htmlFor="age-native-simple">{label}</InputLabel>
      <Select
        className={props.isValid ? "valid" : "inValid"}
        value={ component === 'paymentPlan' ? initialValue[fieldName] : initialValue[fieldName] ? initialValue[fieldName] : values[fieldName] }
        onChange={handleChange}
        disabled={isDisabled}
        IconComponent={ExpandMoreIcon}
        inputProps={{
          name: fieldName,
          id: id,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right"
          },
          getContentAnchorEl: null,
        }}
      >
        {
          dropDownDataList ? dropDownDataList.map((item) => {
            const objProps = Object.keys(item);
            return (
                    <MenuItem key={item[objProps[2]]} value={item[objProps[2]]}>{item[objProps[0]]}</MenuItem>
            )
          }) : null
        }
      </Select>
    </FormControl>
  );
}

