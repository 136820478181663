import React, { useState, useEffect } from "react";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import CalernderSVG from '../../assets/calendar.svg';
import {MuiPickersUtilsProvider,KeyboardDatePicker,} from '@material-ui/pickers';
import './KeyboardDatePickerExample.css';
import { getFormattedDate } from '../util';

function KeyboardDatePickerExample(props) {
  const { label, disableFuture = false, disablePast = false, minDate, maxDate, disabledDate } = props;
  let DateInstance=new Date().toUTCString();
  if(label==="DOB"){
      DateInstance=null;
  }
  
  const [selectedDate, handleDateChange] = useState(DateInstance);

  useEffect(() => {
    if(selectedDate !== props.selectedDate){
      handleDateChange(props.selectedDate)
      //eslint-disable-next-line
    }
  }, [props.selectedDate, selectedDate]);


  // const handleChange = (date) => {
  //   let dateStr = date === null ? null : getFormattedDateYMD(date)
  //   props.handleDateChangeInForm(dateStr !== null ? getFormattedDateYMD(dateStr) : null);
  //   handleDateChange(date);
  // }

  const handleChange = (date) => { //only valid for DOB, in case if you handle any other date, please create other method
    const selectDate = date !== null ?  getFormattedDate(date.toLocaleString("en-US")) : '';
    props.handleDateChangeInForm(selectDate !== null ? selectDate : null);
    handleDateChange(selectDate);
  }

  return (
     <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          autoOk={true}
          className={(selectedDate !== null || props.requireValidation) ? 
            (props.component === 'insurance' || props.component === 'propertiesDetail' ? "date-picker valid" : "date-picker-wrapper date-picker valid") 
            : (props.component === 'insurance' || props.component === 'propertiesDetail' ? "date-picker inValid" : "date-picker-wrapper date-picker inValid")}
          disableToolbar
          disableFuture={disableFuture}
          disablePast={disablePast}
          variant="inline"
          value={selectedDate}
          minDate={minDate !== "" ? minDate : "1900-01-01"}
          maxDate={maxDate !== "" ? maxDate : new Date()}
          placeholder=""
          label={<div>{label}
          {
              (props.info==="borrower") ? <span className="redTxt"> *</span> : null
          }
          </div>}
          onChange={(date) => handleChange(date)}
          format="MM-dd-yyyy"
          keyboardIcon={<img src={CalernderSVG} className="calender-icon" alt="Date Picker" />}
          invalidLabel='unknown'
          disabled={disabledDate}
          // emptyLabel={props.emptyLabel}
        />
    </MuiPickersUtilsProvider>
  );
}

export default KeyboardDatePickerExample;