import {
	UPDATE_ADDRESS_REQUEST_JSON,
    UPDATE_INITIAL_BORROWER_REQUEST_JSON,
    // FETCH_MARTIAL_STATUS_LIST,
    // FETCH_MARTIAL_STATUS_LIST_SUCCESS,
    // FETCH_MARTIAL_STATUS_LIST_FAILURE,
    // FETCH_CITY,
    // FETCH_CITY_SUCCESS,
    // FETCH_CITY_FAILURE,
    // FETCH_STATE,
    // FETCH_STATE_SUCCESS,
    // FETCH_STATE_FAILURE,
    // FETCH_COUNTY_LIST,
    // FETCH_COUNTY_LIST_SUCCESS,
    // FETCH_COUNTY_LIST_FAILURE,
    UPDATE_SUBJECT_PROPERTY_ADDRESS_REQUEST_JSON,
    UPDATE_OTHER_INFO_REQUEST_JSON,
    UPDATE_LOAN_SUMMARY_REQUEST_JSON,
    FETCH_ALL_DATA,
    FETCH_ALL_DATA_SUCCESS,
    FETCH_ALL_DATA_FAILURE,
    UPDATE_MARTIAL_STATUS_LIST,
    UPDATE_STATE_LIST,
    UPDATE_LANGUAGE_LIST,
    UPDATE_CITY_LIST,
    UPDATE_COUNTY_LIST,
    UPDATE_MAILING_CITY_LIST,
    UPDATE_MAILING_COUNTY_LIST,
    TOGGLE_TOUCHED,
    FETCH_CAPTCHA_IMAGE,
    FETCH_CAPTCHA_IMAGE_SUCCESS,
    FETCH_CAPTCHA_IMAGE_FAILURE,
    UPDATE_CAPTCHA_TEXT,
    MANAGE_LOAN_SUMMARY,
    MANAGE_LOAN_SUMMARY_SUCCESS,
    MANAGE_LOAN_SUMMARY_FAILURE,
    
    FETCH_UPLOAD_DOCUMENT_URL,
    FETCH_UPLOAD_DOCUMENT_URL_SUCCESS,
    FETCH_UPLOAD_DOCUMENT_URL_FAILURE,
    FETCH_DOWNLOAD_DOCUMENT_URL,
    FETCH_DOWNLOAD_DOCUMENT_URL_SUCCESS,
    FETCH_DOWNLOAD_DOCUMENT_URL_FAILURE,
    FETCH_UPLOAD_DETAILS,
    FETCH_UPLOAD_DETAILS_SUCCESS,
    FETCH_UPLOAD_DETAILS_FAILURE,
    UPDATE_EDITABLE_BORROWER_REQUEST_JSON,
    UPDATE_LOAN_DOCUMENT_LIST_REQUEST_JSON
} from '../actions/applicationDetailsAction'

import { currentAddressRequestJson,borrowerRequestJson, subjectPropertyRequestJson, otherInfoRequestJson, 
    loanSummaryRequestJson,  editableBorrowerRequestJson, loanDocumentListRequestJson} from '../const/applicationDetailsConst';

const INITIAL_STATE = {
    currentAddressRequestJson: currentAddressRequestJson,
    borrowerRequestJson:borrowerRequestJson,
    subjectPropertyRequestJson:subjectPropertyRequestJson,
    otherInfoRequestJson:otherInfoRequestJson,
    editableBorrowerRequestJson:editableBorrowerRequestJson,
    loanDocumentListRequestJson:loanDocumentListRequestJson,
    loanSummaryRequestJson:loanSummaryRequestJson,
    martialStatusList:[],
    cityList:[],
    stateList:[],
    countyList:[],
    languageList:[],
    mailingCityList:[],
    mailingCountyList:[],
    allPortalData:{},
    isFormTouched: false,
    captchaImageData:{
        "newCaptchaImage": "",
        "captchaData": ""
      },
    captchaText:"",
    uploadDocumentUrl: "",
    downloadDocumentUrl: "",
    uploadDetails: {},
    success: null,
    error: null,
    message:'',
    loading: false,
    loanSummaryById:{},
    isEditMode:false,
}

export default function applicationDetailsReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {

        case UPDATE_ADDRESS_REQUEST_JSON:
			return { ...state, currentAddressRequestJson: action.payload, error: null, loading: false };
        
        case UPDATE_INITIAL_BORROWER_REQUEST_JSON:
            return { ...state, borrowerRequestJson: action.payload, error: null, loading: false };
        
        case UPDATE_SUBJECT_PROPERTY_ADDRESS_REQUEST_JSON:
            return { ...state, subjectPropertyRequestJson: action.payload, error: null, loading: false };
        
        case UPDATE_OTHER_INFO_REQUEST_JSON:
            return { ...state, otherInfoRequestJson: action.payload, error: null, loading: false };
        
        case UPDATE_LOAN_SUMMARY_REQUEST_JSON:
            return { ...state, loanSummaryRequestJson: action.payload, error: null, loading: false };
        
        case UPDATE_MARTIAL_STATUS_LIST:
            return { ...state, martialStatusList: action.payload, error: null, loading: false };
        
        case UPDATE_STATE_LIST:
            return { ...state, stateList: action.payload, error: null, loading: false };
        
        case UPDATE_LANGUAGE_LIST:
            return { ...state, languageList: action.payload, error: null, loading: false };
        
        case UPDATE_CITY_LIST:
            return { ...state, cityList: action.payload, error: null, loading: false };
        
        case UPDATE_COUNTY_LIST:
            return { ...state, countyList: action.payload, error: null, loading: false };
        
        case UPDATE_MAILING_CITY_LIST:
            return { ...state, mailingCityList: action.payload, error: null, loading: false };
        
        case UPDATE_MAILING_COUNTY_LIST:
            return { ...state, mailingCountyList: action.payload, error: null, loading: false };

        case TOGGLE_TOUCHED:
            return { ...state, isFormTouched: action.payload, error: action.payload.message, loading: false };

        case FETCH_CAPTCHA_IMAGE:
            return { ...state, captchaImageData: "", error: null, loading: true };
        case FETCH_CAPTCHA_IMAGE_SUCCESS:
            return { ...state, captchaImageData: action.payload, error: null, loading: false };
        case FETCH_CAPTCHA_IMAGE_FAILURE:
            return { ...state, captchaImageData: "", error: action.payload.message, loading: false };
        case UPDATE_CAPTCHA_TEXT:
            return { ...state, captchaText: action.payload, error: null, loading: false };

        case FETCH_UPLOAD_DOCUMENT_URL:
            return { ...state, uploadDocumentUrl: "", error: null, loading: true };
        case FETCH_UPLOAD_DOCUMENT_URL_SUCCESS:
            return { ...state, uploadDocumentUrl: action.payload, error: null, loading: false };
        case FETCH_UPLOAD_DOCUMENT_URL_FAILURE:
            return { ...state, uploadDocumentUrl: "", error: action.payload.message, loading: false };
        case FETCH_DOWNLOAD_DOCUMENT_URL:
            return { ...state, downloadDocumentUrl: "", error: null, loading: true };
        case FETCH_DOWNLOAD_DOCUMENT_URL_SUCCESS:
            return { ...state, downloadDocumentUrl: action.payload, error: null, loading: false };
        case FETCH_DOWNLOAD_DOCUMENT_URL_FAILURE:
            return { ...state, downloadDocumentUrl: "", error: action.payload.message, loading: false };
        case FETCH_UPLOAD_DETAILS:
            return { ...state, uploadDetails: "", error: null, loading: true };
        case FETCH_UPLOAD_DETAILS_SUCCESS:
            return { ...state, uploadDetails: action.payload, error: null, loading: false };
        case FETCH_UPLOAD_DETAILS_FAILURE:
            return { ...state, uploadDetails: "", error: action.payload.message, loading: false };
            default:
                return moreCases(state, action)
    }
}

function moreCases (state = INITIAL_STATE, action = {}) {
    let response  =  null;
    switch (action.type) {
        case FETCH_ALL_DATA: 
            return { ...state, allPortalData: {}, error: null, loading: true };
        case FETCH_ALL_DATA_SUCCESS: 
            return { ...state, allPortalData: action.payload, error: null, loading: false };
        case FETCH_ALL_DATA_FAILURE:
            return { ...state, allPortalData: {}, error: action.payload.message, loading: false };

        case MANAGE_LOAN_SUMMARY:
            return { ...state, error: null, loading: true };
        case MANAGE_LOAN_SUMMARY_SUCCESS:
            return { ...state, success: true, error: null, message:'', loading: false  };
        case MANAGE_LOAN_SUMMARY_FAILURE:
            return { ...state, error: action.payload.error, message:action.payload, loading: false };
        
        case UPDATE_EDITABLE_BORROWER_REQUEST_JSON:
            return { ...state, editableBorrowerRequestJson: action.payload, error: null, loading: false };
        
        case UPDATE_LOAN_DOCUMENT_LIST_REQUEST_JSON:
            return { ...state, loanDocumentListRequestJson: action.payload, error: null, loading: false };

        default:
            response =  state; break;
    }
    return response;
}