//Email validation regex
export const EMAIL_REGEX = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;

//Phone validation regex
export const PHONE_REGEX = /^(\+\d{1,2}\s?)?1?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i;

// SnackBar Messages
export const snackBarMessages = {
  submitSuccess: 'Submit successfully.',
  error: 'Some error occurred. Please try again later.',
  loanSubmissionSuccess: 'Loan application submitted successfully.',
  captchaError:'The captcha was not correct, try again...',
  loanNoError:'Loan Number is not valid.'
};