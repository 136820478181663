import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Typography from "@material-ui/core/Typography";

export class SubHeader extends Component {
    render () {
        return (
            <Fragment>
                <div className="sub-header subheader-bg">
                    <div><Typography variant="h5">
                    Welcome!
                  </Typography></div>
                    <div>
                    <Typography variant="subtitle" >
                    Please fill your loan application details
                  </Typography>
                    </div>
                
                  
                </div>
            </Fragment>
        )
    }
}

export default withRouter(SubHeader);