import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MaskedInput from "react-text-mask";

export class AuthorizedSignor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      middleName:"",
    };
  }

  handleAuthorizedChange = (event) => {
    let value=event.target.value;
    // if(event.target.id==="middleName"){
    //   this.setState({
    //     middleName:value
    //   })
    // }
    this.props.handleAuthorizedChange(event,value);
  };

  render() {
    const { firstName, middleName, lastName, email, officePhone, cellPhone} = this.props.authorizedSignorInfo;
    return (
      <Grid fullWidth container>
        <div className="detail-section loan-pg">
          <div className="heading-section">
            <div className="heading-title">
              <Typography variant="h5" gutterBottom>
                  Authorized Signor
              </Typography>
            </div>
          </div>
          <Grid fullWidth container class="loan-pg-sec">
            <Paper fullWidth elevation={2}>
              <Grid container spacing={8}>
                <Grid item xs={4}>
                  <TextField onChange={this.handleAuthorizedChange}
                    id="firstName" fullWidth label="First Name"
                    disabled={this.props.isEditMode}
                    value={firstName} variant="standard" required
                  />
                  
                  {!(!this.props.isFormTouched || (firstName !== "" && this.props.isFormTouched)) ? <Grid item xs={12}>
                      <Typography gutterBottom>
                        <span className="redTxt">First name is required</span>
                      </Typography>
                    </Grid> : null}
                </Grid>
                <Grid item xs={4}>
                  <TextField id="middleName" fullWidth label="Middle Name"
                        value={this.props.isEditMode?this.props.editAuthInfo.middleName:middleName} variant="standard"
                        disabled={this.props.isEditMode && (middleName!=='' && middleName!==null)}
                        onChange={this.handleAuthorizedChange} />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="lastName" label="Last Name" fullWidth
                    value={lastName} variant="standard" required
                    disabled={this.props.isEditMode}
                    onChange={this.handleAuthorizedChange}
                  />
                  {!(!this.props.isFormTouched || (lastName !== "" && this.props.isFormTouched)) ? <Grid item xs={12}>
                      <Typography gutterBottom>
                        <span className="redTxt">Last name is required</span>
                      </Typography>
                    </Grid> : null}
                </Grid>
              </Grid>
              <Grid container spacing={8}>
                <Grid item xs={4}>
                  <TextField
                    id="email" label="Email"
                    value={email} variant="standard"
                    onChange={this.handleAuthorizedChange}
                    fullWidth required
                    disabled={this.props.isEditMode}
                  />
                  {!(!this.props.isFormTouched || (email !== "" && this.props.isFormTouched)) ? <Grid item xs={12}>
                      <Typography gutterBottom>
                        <span className="redTxt">Email is required</span>
                      </Typography>
                    </Grid> : null}
                </Grid>
                <Grid item xs={4}>
                    <InputLabel htmlFor="officePhone">
                        Office Phone <span className="redTxt"> *</span>
                    </InputLabel>
                    <Input
                        id="officePhone" required
                        fullWidth inputComponent={ContactNumberMask}
                        value={officePhone} onChange={this.handleAuthorizedChange}
                        disabled={this.props.isEditMode}
                    />
                    {!(!this.props.isFormTouched || (officePhone !== "" && this.props.isFormTouched)) ? <Grid item xs={12}>
                      <Typography gutterBottom>
                        <span className="redTxt">Office Phone is required</span>
                      </Typography>
                    </Grid> : null}
                </Grid>
                <Grid item xs={4}>
                    <InputLabel htmlFor="cellPhone">
                        Cell Phone <span className="redTxt"> *</span>
                    </InputLabel>
                    <Input
                        id="cellPhone" value={cellPhone} required
                        disabled={this.props.isEditMode}
                        fullWidth onChange={this.handleAuthorizedChange}
                        inputComponent={ContactNumberMask}
                    />
                    {!(!this.props.isFormTouched || (cellPhone !== "" && this.props.isFormTouched)) ? <Grid item xs={12}>
                      <Typography gutterBottom>
                        <span className="redTxt">Cell Phone is required</span>
                      </Typography>
                    </Grid> : null}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </div>
      </Grid>
    );
  }
}
export default AuthorizedSignor;

function ContactNumberMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {inputRef(ref ? ref.inputElement : null);}}
      mask={["(",/[1-9]/,/\d/,/\d/,")",/\d/,/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/,]} //"02-2323234"
      placeholderChar={"_"}
    />
  );
}

