import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MaskedInput from "react-text-mask";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
export class CommercialEntityOwner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleCommercialEntityChange = (event) => {
    this.props.handleCommercialEntityChange(event);
  };

  render() {
    const { entityName, taxId} = this.props.authorizedSignorInfo;
    return (
      <Grid fullWidth container>
        <div className="detail-section loan-pg">
          <div className="heading-section">
            <div className="heading-title">
              <Typography variant="h5" gutterBottom>
                Commercial Entity Owner
              </Typography>
            </div>
          </div>
          <Grid fullWidth container class="loan-pg-sec">
            <Paper fullWidth elevation={2}>
              <Grid container spacing={8}>
                <Grid item xs={4}>
                  <TextField onChange={this.handleCommercialEntityChange}
                    id="entityName" value={entityName} label="Company Name"
                    disabled={this.props.isEditMode}
                    fullWidth required variant="standard"
                  />

                  {!(!this.props.isFormTouched || (entityName !== "" && this.props.isFormTouched)) ? <Grid item xs={12}>
                      <Typography gutterBottom>
                        <span className="redTxt">Entity name is required</span>
                      </Typography>
                    </Grid> : null}
                </Grid>
                <Grid item xs={4}>
                  <InputLabel htmlFor="taxId">Tax ID</InputLabel>
                  <Input
                    value={this.props.isEditMode?this.props.editCommEntity.taxId:taxId} variant="standard"
                    disabled={this.props.isEditMode && (taxId!=='' && taxId!==null)}
                    id="taxId"
                    fullWidth
                    onChange={this.handleCommercialEntityChange}
                    inputComponent={TaxIdFormat}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </div>
      </Grid>
    );
  }
}
export default CommercialEntityOwner;


function TaxIdFormat(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[1-9]/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} //"12-2232352"
      placeholderChar={"_"}
    />
  );
}
