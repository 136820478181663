import React, { Component, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from "@material-ui/core/Typography";
//import ConfimationAlertSVG from '../../assets/Delete-pop-up.svg';
import popupBannerSvg from '../../assets/popup-banner.svg';
class DeleteDialogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      stickyMsg: ""
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseYes = this.handleCloseYes.bind(this);
  }

  handleClose() {
    this.props.close();
    this.setState({ open: false, stickyMsg: "" });
  }

  handleCloseYes() {
    this.props.deleteCallBack();
    this.setState({ stickyMsg: "Deleted Successfully !" });
  }

  render() {
    return (
      <Fragment>
        <Dialog open={this.state.open} className="noTitleDialog custom-dialog-wrapper">
          <div className="wd500">
            <DialogContent className="confirmation-dialog-content-section">
              
                <Box className="top-section popupBgPic">
                    <img src={popupBannerSvg} alt="alert-icon" />
                </Box>
                <Box className="bottom-section">
                    <Typography variant="subtitle1" gutterBottom>
                        {this.props.msg}
                    </Typography>
                    <Box className="btn-group">
                    <Button variant="contained" color="primary" onClick={this.handleCloseYes}>
                        YES
                      </Button>
                      <Button onClick={this.handleClose}>NO</Button>
                    </Box>
                </Box>
            </DialogContent>
          </div>
        </Dialog>
      </Fragment>
    );
  }
}

export default DeleteDialogs;
