import React, { Component } from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import binIcon from '../../assets/comment_check.svg';
import binIconError from '../../assets/error_toaster.svg';

class CustomSnackbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            successMessage: ''
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentWillMount() {
        if (this.props.message && this.state.message !== this.props.message) {
            this.setState({ open: true, successMessage: this.props.message })
        }
    }

    componentDidMount = () => {
        if (this.props.message && this.state.message !== this.props.message ) {
            this.setState({ open: true, successMessage: this.props.message })
        }
        else {
            this.setState({ open: false });
        }
    }

    handleClick() {
        this.setState({ open: true });
    };

    handleClose(reason) {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false });
        if (this.state.successMessage === 'Please select atleast two items !' ||
            this.state.successMessage === 'Please select atleast one category') {
            if (this.props.parentCallback)
                this.props.parentCallback(true);
        } else {
            if (this.props.parentCallback)
                this.props.parentCallback(false);
        }
        this.setState({ successMessage: '' });
    };

    render() {
        return (
            <Snackbar
                className={`customSnackbar ${this.props.snackbarVariant}`}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={this.state.open}
                ContentProps={{
                    'aria-describedby': 'snackbarMessage-id',
                }}
                message={
                    <span id="snackbarMessage-id" className="snackbarMessage">
                        <span className="messIcon">
                            <img src={this.props.snackbarVariant === "error" ? binIconError : binIcon} alt="" />
                            </span>
                        <span>{this.state.successMessage}</span>
                    </span>}
                action={[
                    //     <Button id = 'undo' key="undo" size="large" onClick={this.handleClose}>
                    //         UNDO
                    //    </Button>,
                    <IconButton
                        id="iconBtn"
                        key="close"
                        aria-label="close"
                        color="inherit"
                        onClick={this.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
                autoHideDuration={6000}
            />
        );
    }
}

export default CustomSnackbar;
