import { connect } from 'react-redux';
import Thankyou from  '../components/thankyou';

// import {
// 	showLoader,
// 	hideLoader
// } from '../actions/loaderAction';

export const mapStateToProps = (state) => {
	return {
     
	}
}

export const mapDispatchToProps = (dispatch) => {
	return {
        
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Thankyou)
