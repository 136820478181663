import React, { Component, Fragment } from "react";
import Header from "../common/header/header";
import SubHeader from "./subHeader";
import LoaderComponent from "../common/loaderComponent";
import CustomSnackBarContainer from "../containers/customSnackBarContainer";

import BorrowerDetails from "./borrowerDetail";
import SubjectPropertyDetails from "./subjectPropertyDetail";
import PropertyAddressDetails from "./currentAndMailingAddress";
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import UploadzoneArea from '../common/upload/UploadzoneArea';
import { createDataForDropdown } from '../common/util';
import SelectDropDown from '../common/SelectDropDown/SelectDropDown';

import CommercialEntityOwner from './commercialEntityOwner'
import AuthorizedSignor from './authorizedSignor'
import ImageDropzone from '../common/upload/ImageDropzone';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import RefreshIcon from '../assets/icons/refresh.svg';
import { v4 as uuidv4 } from 'uuid';
import { Guid } from 'guid-typescript';
import { withRouter } from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';

let filesToBeDelete = [];

export class ApplicationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetList: [],
      page: 0,
      rowsPerPage: 10,
      subHeaderDetails: ["Welcome"],
      checkedA: true,
      checkedB: true,
      isSelect: 1,
      displayBorrower:false,
      displayBorrowerButton:true,
      displayDeleteButton:false,
      subjectPropertyList:[{id:1,'name':'xyz'}],
      martialStatusList:[],
      loanNumberList:[{loanNo:''}],
      isGenerateEnabled: false,
      openSnackBar: false,
      snackbarMessage: '',
      snackbarVariant: 'success',
      propertyId: 0,
      propertyType: "",
      loanId: 0,
      guid:'',
      uploadedFiles: [],
      uploadedImages: [],
      selectedLanguageRecord:{Code: 1, Description: "English", languageId: 1},
      index:0,
      //showFields:false,
      isCurrentAddressReadOnly:false,
      isMailingAddressReadOnly:false,
      captchaText:"",
      loanDetails: {
        isPropertyPrimaryResidence: "",
        propertyOwnedBy: "",
        propertyType: ""
      },
      isEditMode:true,
      loanNumber:"",
      comments:"",
      isMortgageProperty:null,
      isPriorCustomer:null,
      modeInfo:{},
      loanSummaryData:{},
      showLoader: false
    };

    // if(this.props.modeInfo.isEditMode){
    //   this.setState({
    //     modeInfo:this.props.modeInfo,
    //     loanSummaryData:this.props.loanSummaryData
    //   })
    // }

  }

  componentDidMount(){
    this.props.toggleTouched(false);
    //this.props.updateEditMode(false);
    this.getAllData("All");
    if(this.props.modeInfo.isEditMode && this.props.loanSummaryData!==undefined){
      let loanDetailsObj={
        isPropertyPrimaryResidence: this.props.loanSummaryData.propertyQuestions[0].answer,
        propertyOwnedBy: this.props.loanSummaryData.propertyQuestions[2].answer,
        propertyType: this.props.loanSummaryData.propertyType.toLocaleLowerCase().includes("residential") ? "Residential" : "Commercial"
      }
      this.setState({
        loanId:this.props.loanSummaryData.loanId,
        comments:this.props.loanSummaryData.comments,
        loanDetails:loanDetailsObj,
        loanNumber:this.props.loanSummaryData.loanNumber,
      })
      this.setDataInEditMode(this.props.loanSummaryData);
    }
    this.getCaptcha();
    if(!this.props.modeInfo.isEditMode){
      this.setState({
        loanId: uuidv4(),
        loanDetails: JSON.parse(localStorage.getItem('loanDetails'))
      });
    }
  }

  getAllData = (masterDataType, stateCode="", zipcode="", bind="") => {
    let reqObj={
      "masterDataType":masterDataType,
      "stateCode":stateCode,
      "zipCode":zipcode
    }
    this.props.getAllData(reqObj).then(data=>{
      let martialStatusList=createDataForDropdown(data.maritalStatus,'Description','description','Name','name','Id','id' );
      let stateList=createDataForDropdown(data.state,'Description','abbreviation','Name','name','Id','id' );
      let languageList=createDataForDropdown(data.language,'Description','description','Code','code','languageId','id' );
      this.setState({
        selectedLanguageRecord:languageList[0]
      });
      this.props.updateMartialStatusList(martialStatusList);
      this.props.updateStateList(stateList);
      this.props.updateLanguageList(languageList);
      let question1Obj= data.loanPropertyQuestion.filter(record => {return record.description==="Is the property your primary residence?"});
      let question2Obj= data.loanPropertyQuestion.filter(record => {return record.description==="Is the property residential or commercial?"});
      let question3Obj= data.loanPropertyQuestion.filter(record => {return record.description==="Is the property owned by a company or an individual?"});
      let propObj = (this.state.loanDetails.isPropertyPrimaryResidence ==="Yes" || this.state.loanDetails.propertyType !== "Commercial" ||
      (this.state.loanDetails.propertyType === "Commercial" && this.state.loanDetails.propertyOwnedBy === "Individual"))
      ? data.propertyType.filter(record => {return record.description.toLocaleLowerCase().includes('residential')})
      : data.propertyType.filter(record => {return record.description.toLocaleLowerCase().includes('commercial')});
      let quesAnsArr = [
        {
          "question": question1Obj[0].description,
          "questionId": question1Obj[0].code,
          "answer": this.state.loanDetails.isPropertyPrimaryResidence
        },
        {
          "question": question2Obj[0].description,
          "questionId": question2Obj[0].code,
          "answer": this.state.loanDetails.propertyType
        },
        {
          "question": question3Obj[0].description,
          "questionId": question3Obj[0].code,
          "answer": this.state.loanDetails.propertyOwnedBy
        }
      ]
      this.setState({
        propertyId: propObj[0].code,
        propertyType: propObj[0].description,
        propertyQuestions: quesAnsArr
      })
      if(this.state.loanId !== "")
        this.props.fetchUploadDetails(this.state.loanId);
    })
  }

  handleChange = (e) => {
    let nam = e.target.id;
    let val = e.target.value;
    let updatedData = this.props.requestJson;
    let newJson = { ...updatedData, [nam]: val };
    this.props.updateInitialRequestJson(newJson);
  }

  onQuestionhandleChange = (event) => {
    this.setState({ isSelect: event.target.value });
    console.log(event.target.value);
  };

  displayBorrowerButton() {
    this.setState({
      displayBorrower:true,
      displayBorrowerButton:false,
      displayDeleteButton:true,
    })
	}

  displayDeleteAction = () => {
    //at the time of delete empty the coborrower
    let coborrowerObject={
      firstName: "",
      middleName:"",
      lastName: "",
      entityName: "",
      ownerId: "",
      email: "",
      dateOfBirth: null,
      maritalStatus: "",
      maritalStatusId: 0,
      cellPhone: "",
      homePhoneNumber: "",
      officePhone: "",
      aka: "",
      ssn: "",
      taxId: "",
      grossMonthlyIncome: null,
      employer: "",
      order:0
    }
    let newJson = [...this.props.borrowerRequestJson];
    newJson[1] = { ...coborrowerObject};
    this.props.updateBorrowerRequestJson(newJson);
    this.setState({
      displayDeleteButton:false,
      displayBorrower:false,
      displayBorrowerButton:true
    })
  }

  trimProps = (value) => {
    return value.replace(/[^\d.]/g, '');
  }

  handleBorrowerChange = (e,info) => {
    let updatedData={};
    let newJson=[];
    let nam = e.target.id;
    let val = e.target.value;
    let borrowerId=Guid.create();
    let coBorrowerId=Guid.create();
    if (nam==="grossMonthlyIncome"){
      val=this.trimProps(e.target.value);
    }
    if(this.props.modeInfo.isEditMode){
      updatedData = info==="borrower"?this.props.editableBorrowerRequestJson[0]:this.props.editableBorrowerRequestJson[1];
      newJson = [...this.props.editableBorrowerRequestJson];
      if(info==="borrower"){ newJson[0] = { ...updatedData, [nam]: val}; }
      else{
        newJson[1] = { ...updatedData, [nam]: val};
      }
      this.props.updateEditableBorrowerRequestJson(newJson);
    }
    else{
      updatedData = info==="borrower"?this.props.borrowerRequestJson[0]:this.props.borrowerRequestJson[1];
      newJson = [...this.props.borrowerRequestJson];
      if(info==="borrower"){
        newJson[0] = { ...updatedData, [nam]: val,"order": 0,"ownerId": borrowerId.value };
      }else{
        newJson[1] = { ...updatedData, [nam]: val,"order": 1,"ownerId": coBorrowerId.value };
      }
      this.props.updateBorrowerRequestJson(newJson);
    }
  }

  handleDateInForm = (date,info,fieldName, borrowerAge=0) => {
    let updatedData={};
    let newJson=[];
    if(this.props.modeInfo.isEditMode){
      updatedData = this.props.editableBorrowerRequestJson[1];
      newJson = [...this.props.editableBorrowerRequestJson];
      if(fieldName === "dob"){
        newJson[1] = { ...updatedData, "dateOfBirth": date, "age":borrowerAge };
      }else{
          newJson[1] = { ...updatedData, "maritalStatusId": date.Id, "maritalStatus":date.Name, "order": 1 };
      }
      this.props.updateEditableBorrowerRequestJson(newJson);
    }else{
      updatedData = info==="borrower"?this.props.borrowerRequestJson[0]:this.props.borrowerRequestJson[1];
      newJson = [...this.props.borrowerRequestJson];
      if(fieldName === "dob"){
        if(info==="borrower"){
          newJson[0] =  { ...updatedData, "dateOfBirth": date, "age":borrowerAge };
        }else{
          newJson[1] = { ...updatedData, "dateOfBirth": date, "age":borrowerAge };
        }
      }else{
        if(info==="borrower"){
          newJson[0] =  { ...updatedData, "maritalStatusId": date.Id, "maritalStatus":date.Name, "order": 0 };
        }else{
          newJson[1] = { ...updatedData, "maritalStatusId": date.Id, "maritalStatus":date.Name, "order": 1 };
        }
      }
      this.props.updateBorrowerRequestJson(newJson);
    }
  }


  //--------HANDLE CURRENT AND MAILING ADDRESS-------------------//
  handleStateChange = (fieldName,value,secName) => {
    if (secName==="curAddress"){
      if(value!==0){
        let reqObj={
          "masterDataType":"All",
          "stateCode":value,
          "zipCode":""
        }
        this.props.getAllData(reqObj).then(data=>{
          let cityList=createDataForDropdown(data.city,'Name','name','Code','code','Id','id' );
          let countyList=createDataForDropdown(data.county,'Name','name','Code','code','Id','id' );
          this.props.updateCityList(cityList);
          this.props.updateCountyList(countyList);
          this.handleAddressChange(fieldName,value,secName);
        })
      }else{
        let updatedData = {...this.props.currentAddressRequestJson};
        let borrowerRecord=[...this.props.borrowerRequestJson];
        borrowerRecord[0].cityId=0;
        borrowerRecord[0].cityName=null;
        borrowerRecord[0].countyId=value;
        borrowerRecord[0].countyName=null;
        borrowerRecord[0].stateId=value;
        borrowerRecord[0].stateName='';
        borrowerRecord[0].stateCode='';
        updatedData.current['stateName']='';
        updatedData.current['cityName']='';
        updatedData.current['countyName']='';
        updatedData.current['stateId']=0;
        updatedData.current['cityId']=0;
        updatedData.current['countyId']=0;
        this.props.updateCityList([]);
        this.props.updateCountyList([]);
        this.props.updateBorrowerRequestJson(borrowerRecord)
        this.props.updateAddressRequestJson(updatedData);
      }
    }
    if (secName==="propAddress"){
      if(value!==0){
        let reqObj={
          "masterDataType":"All",
          "stateCode":value,
          "zipCode":""
        }
        this.props.getAllData(reqObj).then(data=>{
          let mailingCityList=createDataForDropdown(data.city,'Name','name','Code','code','Id','id' );
          let mailintCountyList=createDataForDropdown(data.county,'Name','name','Code','code','Id','id' );
          this.props.updateMailingCityList(mailingCityList);
          this.props.updateMailingCountyList(mailintCountyList);
          this.handleAddressChange(fieldName,value,secName);
        })
      }else{
        let updatedData = {...this.props.currentAddressRequestJson};
        let borrowerRecord=[...this.props.borrowerRequestJson];
        borrowerRecord[0].mailingAddressCityId=0;
        borrowerRecord[0].mailingAddressCityName=null;
        borrowerRecord[0].mailingAddressCountyId=value;
        borrowerRecord[0].mailingAddressCountyName=null;
        borrowerRecord[0].mailingAddressStateId=value;
        borrowerRecord[0].mailingAddressStateName='';
        borrowerRecord[0].mailingAddressStateCode='';
        updatedData.mailing['stateName']='';
        updatedData.mailing['cityName']='';
        updatedData.mailing['countyName']='';
        updatedData.mailing['stateId']=0;
        updatedData.mailing['cityId']=0;
        updatedData.mailing['countyId']=0;
        this.props.updateMailingCityList([]);
        this.props.updateMailingCountyList([]);
        this.props.updateBorrowerRequestJson(borrowerRecord)
        this.props.updateAddressRequestJson(updatedData);
      }
    }
  }

  handleAddressChange = (fieldName,value,secName) => {
    let updatedData = {...this.props.currentAddressRequestJson};
    let borrowerRecord=[...this.props.borrowerRequestJson];
    if(secName==='curAddress')
    {
      if(fieldName==="stateId"){
         borrowerRecord[0].stateId=value;
         updatedData.current['stateName']=this.getNameFromList(this.props.stateList, value);
         updatedData.current['stateCode']=this.getStateCodeFromList(this.props.stateList,value);
         borrowerRecord[0].stateName=this.getNameFromList(this.props.stateList, value);
         borrowerRecord[0].stateCode=this.getStateCodeFromList(this.props.stateList,value);
      }
      if(fieldName==="cityId"){
        borrowerRecord[0].cityId=value;
        updatedData.current['cityName']=value!==0?this.getNameFromList(this.props.cityList, value):'';
        borrowerRecord[0].cityName=value!==0?this.getNameFromList(this.props.cityList, value):'';
      }
      if(fieldName==="countyId"){
        borrowerRecord[0].countyId=value;
        updatedData.current['countyName']=value!==0?this.getNameFromList(this.props.countyList, value):'';
        borrowerRecord[0].countyName=value!==0?this.getNameFromList(this.props.countyList, value):'';
      }
      if(fieldName==="address"){
        borrowerRecord[0].address=value;
      }
      if(fieldName==="zip"){
        borrowerRecord[0].zip=value;
      }
      updatedData.current[fieldName]=value;
    }
    else{
      if(fieldName==="stateId"){
        borrowerRecord[0].mailingAddressStateId=value;
        updatedData.mailing['stateName']=this.getNameFromList(this.props.stateList, value);
        updatedData.mailing['stateCode']=this.getStateCodeFromList(this.props.stateList,value);
        borrowerRecord[0].mailingAddressStateName=this.getNameFromList(this.props.stateList, value);
        borrowerRecord[0].mailingStateCode=this.getStateCodeFromList(this.props.stateList,value);
     }
     if(fieldName==="cityId"){
      borrowerRecord[0].mailingAddressCityId=value;
       updatedData.mailing['cityName']=value!==0?this.getNameFromList(this.props.mailingCityList, value):'';
       borrowerRecord[0].mailingAddressCityName=value!==0?this.getNameFromList(this.props.mailingCityList, value):'';
     }
     if(fieldName==="countyId"){
      borrowerRecord[0].mailingAddressCountyId=value;
       updatedData.mailing['countyName']=value!==0?this.getNameFromList(this.props.mailingCountyList, value):'';
       borrowerRecord[0].mailingAddressCountyName=value!==0?this.getNameFromList(this.props.mailingCountyList, value):'';
     }
     if(fieldName==="address"){
      borrowerRecord[0].mailingAddressStreet=value;
    }
    if(fieldName==="zip"){
      borrowerRecord[0].mailingAddressZip=value;
    }
      updatedData.mailing[fieldName]=value;
    }
    this.props.updateBorrowerRequestJson(borrowerRecord)
    this.props.updateAddressRequestJson(updatedData);
  }

  getNameFromList = (list,id) => {
      let selectedRecord = list.find(x => x.Id=== id);
      return selectedRecord.Name;
  }
  
  getStateCodeFromList = (list,id) => {
    let selectedRecord = list.find(x => x.Id=== id);
    return selectedRecord.Description;
  } 
  //--------END OF HANDLE CURRENT AND MAILING ADDRESS-------------------//

  //--------------HANDLE SUBJECT PROPERTY DIVISION------------------------//
  handleSubjectPropertyChange = (id,value,propIndex) => {
    let updatedData = [...this.props.subjectPropertyRequestJson];
    updatedData[propIndex][id]=value;
    if(id==="cityName"){
      updatedData[propIndex]["cityId"]=null;
    }
    if(id==="zip"){
      if(value.length===5){
        let reqObj={
          "masterDataType":"All",
          "stateCode":"44", //state code for TX
          "zipCode":value
        }
        this.props.getAllData(reqObj).then(data=>{
          if(data!=null && data.zipCodeData!==null){
            let record=data.zipCodeData[0];
            updatedData[propIndex]['cityId']=record.cityId;
            updatedData[propIndex]['countyId']=record.countyId;
            updatedData[propIndex]['stateId']=record.stateId;
            updatedData[propIndex]['stateName']=record.state;
            updatedData[propIndex]['stateCode']=record.stateCode;
            updatedData[propIndex]['cityName']=record.city;
            updatedData[propIndex]['countyName']=record.county;
            updatedData[propIndex]['order']=propIndex;
            updatedData[propIndex]['zipCodeErrMsg']="";
            updatedData[propIndex]['showFields']=true;
            this.props.updateSubjectPropertyRequestJson(updatedData);
          }
          else{
            updatedData[propIndex]['cityId']=0;
            updatedData[propIndex]['countyId']=0;
            updatedData[propIndex]['stateId']=0;
            updatedData[propIndex]['stateName']="";
            updatedData[propIndex]['stateCode']="";
            updatedData[propIndex]['cityName']="";
            updatedData[propIndex]['countyName']="";
            updatedData[propIndex]['order']=propIndex;
            updatedData[propIndex]['zipCodeErrMsg']="Invalid Zip Code";
            updatedData[propIndex]['showFields']=false;
            this.props.updateSubjectPropertyRequestJson(updatedData);
          }
        })
      }
      else{
        this.props.updateSubjectPropertyRequestJson(updatedData);
      }
    }
    else{
      this.props.updateSubjectPropertyRequestJson(updatedData);
    }
    
  }

  handleSubjectPropertySwitch = (id,value,propIndex) => {
    let borrowerRecord=[...this.props.borrowerRequestJson];
    let updatedData = [...this.props.subjectPropertyRequestJson];
    let addressRecord={...this.props.currentAddressRequestJson};
    if(value===true){
      addressRecord.current=updatedData[propIndex];
      borrowerRecord[0].stateId=addressRecord.current.stateId;
      borrowerRecord[0].stateName=addressRecord.current.stateName;
      borrowerRecord[0].stateCode=addressRecord.current.stateCode;
      borrowerRecord[0].cityId=addressRecord.current.cityId;
      borrowerRecord[0].cityName=addressRecord.current.cityId===null ? null : addressRecord.current.cityName;
      borrowerRecord[0].countyId=addressRecord.current.countyId;
      borrowerRecord[0].countyName=addressRecord.current.countyName;
      borrowerRecord[0].address=addressRecord.current.address;
      borrowerRecord[0].zip=addressRecord.current.zip;
      this.setState({
        isCurrentAddressReadOnly:true
      })
      this.props.updateAddressRequestJson(addressRecord);
      this.props.updateBorrowerRequestJson(borrowerRecord)
    }else{
      let currentAddressObj={ 
        address: "",
        stateName: "",
        stateCode:"",
        stateId: 0,
        cityName: "",
        cityId: 0,
        countyName: "",
        countyId: 0,
        zip: "",
      }
      addressRecord.current=currentAddressObj;
      borrowerRecord[0].stateId=0;
      borrowerRecord[0].stateName="";
      borrowerRecord[0].stateCode="";
      borrowerRecord[0].cityId=0;
      borrowerRecord[0].cityName="";
      borrowerRecord[0].countyId=0;
      borrowerRecord[0].countyName="";
      borrowerRecord[0].address="";
      borrowerRecord[0].zip="";
      this.setState({
        isCurrentAddressReadOnly:false
      })
      this.props.updateAddressRequestJson(addressRecord);
      this.props.updateBorrowerRequestJson(borrowerRecord)
    }
  }

  handleMailingSwitchChange = (id,value,propIndex) => {
    let borrowerRecord=[...this.props.borrowerRequestJson];
    let updatedData = [...this.props.subjectPropertyRequestJson];
    let addressRecord={...this.props.currentAddressRequestJson};
    if(value===true){
      addressRecord.mailing=updatedData[propIndex];
      //addressRecord.current=updatedData[propIndex];
      borrowerRecord[0].mailingAddressStateId=addressRecord.mailing.stateId;
      borrowerRecord[0].mailingAddressStateName=addressRecord.mailing.stateName;
      borrowerRecord[0].mailingAddressStateCode=addressRecord.mailing.stateCode;
      borrowerRecord[0].mailingAddressCityId=addressRecord.mailing.cityId;
      borrowerRecord[0].mailingAddressCityName=addressRecord.mailing.cityId===null ? null : addressRecord.mailing.cityName;
      borrowerRecord[0].mailingAddressCountyId=addressRecord.mailing.countyId;
      borrowerRecord[0].mailingAddressCountyName=addressRecord.mailing.countyName;
      borrowerRecord[0].mailingAddressStreet=addressRecord.mailing.address;
      borrowerRecord[0].mailingAddressZip=addressRecord.mailing.zip;
      this.setState({
        isMailingAddressReadOnly:true
      });
      this.props.updateBorrowerRequestJson(borrowerRecord)
      this.props.updateAddressRequestJson(addressRecord);
    }else{
      let currentAddressObj={ 
        address: "",
        stateName: "",
        stateCode:"",
        stateId: 0,
        cityName: "",
        cityId: 0,
        countyName: "",
        countyId: 0,
        zip: "",
      }
      addressRecord.mailing=currentAddressObj;
      borrowerRecord[0].mailingAddressStateId=0;
      borrowerRecord[0].mailingAddressStateName="";
      borrowerRecord[0].mailingAddressStateCode="";
      borrowerRecord[0].mailingAddressCityId=0;
      borrowerRecord[0].mailingAddressCityName="";
      borrowerRecord[0].mailingAddressCountyId=0;
      borrowerRecord[0].mailingAddressCountyName="";
      borrowerRecord[0].mailingAddressStreet="";
      borrowerRecord[0].mailingAddressZip="";
      this.setState({
        isMailingAddressReadOnly:false
      })
      this.props.updateAddressRequestJson(addressRecord);
      this.props.updateBorrowerRequestJson(borrowerRecord)
    }
  }

  addProperty = () => {
    let propertyRecord={
            propertyId: uuidv4(),
            propertyImages: [],
            address: "",
            stateName: "",
            stateId: 0,
            cityName: "",
            cityId: 0,
            countyName: "",
            countyId: 0,
            zip: "",
            stateCode:"", //extra field
            order: 0, 
            zipCodeErrMsg:""
        };
    let updatedPropertyList=[...this.props.subjectPropertyRequestJson];
    updatedPropertyList.push(propertyRecord);
    this.props.updateSubjectPropertyRequestJson(updatedPropertyList);
  }

  handleDeletePropertyButton = (index) => {
    let updatedPropertyList=[...this.props.subjectPropertyRequestJson];
    updatedPropertyList.splice(index, 1);
    for(let i=0;i<updatedPropertyList.length;i++){
      updatedPropertyList[i].order=i;
    }
    this.props.updateSubjectPropertyRequestJson(updatedPropertyList);
  }
  
  //--------------END OF HANDLE SUBJECT PROPERTY DIVISION------------------------//


  //--------------HANDLE OTHER INFORMATION DIVISION------------------------//
  addAnotherLoanNumber = () => {
    let obj={loanNo:''};
    let list=[...this.state.loanNumberList];
    list.push(obj);
    this.setState({
      loanNumberList:list
    })
  }

  removeLoanNumber = (index) => {
    let list=[...this.state.loanNumberList];
    list.splice(index, 1);
    this.setState({
      loanNumberList:list
    })
  }


  handleOtherInfoChange = (e,textName="",index="") => {
    let value=e.target.value;
    let updatedData = {...this.props.otherInfoRequestJson};
    if(this.props.modeInfo.isEditMode && textName==="comments"){
          this.setState({
            comments:value
          })
    }
    if( this.props.modeInfo.isEditMode && textName === "isMortgageProperty"){
         value=value===0?"No":"Yes";
          this.setState({
            isMortgageProperty:value
          })
    }

    if( this.props.modeInfo.isEditMode && textName === "isPriorCustomer"){
      value=value===0?"No":"Yes";
       this.setState({
        isPriorCustomer:value
       })
    }

    if(!(this.props.modeInfo.isEditMode)&&(textName === "isMortgageProperty" || textName === "isPriorCustomer")){
        value=value===0?false:true;
        updatedData[textName]=value;
    }
    else if(textName === "languageId"){
      let selectedLanguage = this.props.languageList.find(x => x.languageId=== value);
      updatedData["language"]=selectedLanguage.Description;
      updatedData["languageId"]=value;
      this.setState({
        selectedLanguageRecord:selectedLanguage
      })
    }
    else if(textName === "comments" && !(this.props.modeInfo.isEditMode)){
      updatedData["comments"]=value;
    }
    else if(textName === "loanNumber"){
      let loanNumbersList=[...this.state.loanNumberList];
      loanNumbersList[index].loanNo=value;
      this.setState({
        loanNumberList:loanNumbersList
      });
    }
    // else if(textName === "loanNumber"){
    //   let loanNumbersList=[...this.state.loanNumberList];
    //   loanNumbersList[index].loanNo=value;
    //   this.setState({
    //     loanNumberList:loanNumbersList
    //   },()=>{
    //     let idsList=[];
    //     for(let i=0;i<this.state.loanNumberList.length;i++){
    //       idsList.push(this.state.loanNumberList[i].loanNo)
    //     }
    //     updatedData["priorLoanNumber"]=idsList.join(",");
    //     this.props.updateOtherInfoRequestJson(updatedData);
    //   });
    // }
    this.props.updateOtherInfoRequestJson(updatedData);
  }


  handleSubmitApplication = () => {
    // this.setState({captchaText: ''});
    // this.props.updateCaptchaText('');
    // this.getCaptcha();
    this.props.toggleTouched(true);
    let loanSummaryRecord={...this.props.loanSummaryRequestJson}
    loanSummaryRecord.loanId=this.state.loanId;
    loanSummaryRecord.propertyTypeId =this.state.propertyId; 
    loanSummaryRecord.propertyType=this.state.propertyType;
    
    //update co borrower as per request object (api)
    let updatedOwnerList=[];
    if(this.props.modeInfo.isEditMode){
      loanSummaryRecord.loanNumber=this.state.loanNumber;
      updatedOwnerList=[...this.props.editableBorrowerRequestJson];
    }else{
      updatedOwnerList=[...this.props.borrowerRequestJson];
      if (updatedOwnerList[1].firstName===""){
        updatedOwnerList = updatedOwnerList.slice(0,-1);
      }
    }
    loanSummaryRecord.ownerList=[...updatedOwnerList];

    //update propertylist as per request object (api)
    loanSummaryRecord.propertyList=[...this.props.subjectPropertyRequestJson]

    //update other info as per request object (api)
    let otherInfo={...this.props.otherInfoRequestJson};
    
    if(otherInfo.isMortgageProperty!==null){
      otherInfo.isMortgageProperty=(otherInfo.isMortgageProperty==="Yes"||otherInfo.isMortgageProperty===true)?true:false
    }

    if(otherInfo.isPriorCustomer!==null){
      otherInfo.isPriorCustomer=(otherInfo.isPriorCustomer==="Yes"||otherInfo.isPriorCustomer===true)?true:false

    }

    let stateIsMortageProperty=null;
    let stateIsPriorCustomer=null;
    if(this.state.isMortgageProperty!==null){
      stateIsMortageProperty=this.state.isMortgageProperty==="Yes"?true:false;
    }
    if(this.state.isPriorCustomer!==null){
      stateIsPriorCustomer=this.state.isPriorCustomer==="Yes"?true:false;
    }
    //let otherInfo = {...this.props.otherInfoRequestJson};
    if(this.state.loanNumberList.length>0){
      let idsList=[];
      for(let i=0;i<this.state.loanNumberList.length;i++){
        idsList.push(this.state.loanNumberList[i].loanNo)
      }
      otherInfo["priorLoanNumber"]=idsList.join(",");
      if(otherInfo["priorLoanNumber"]===""){
        otherInfo["priorLoanNumber"]=null;
      }
      this.props.updateOtherInfoRequestJson(otherInfo);
    }
    loanSummaryRecord["isMortgageProperty"]=this.props.modeInfo.isEditMode?stateIsMortageProperty:otherInfo.isMortgageProperty;
    loanSummaryRecord["language"]=otherInfo.language===null?"English":otherInfo.language;
    loanSummaryRecord["languageId"]=otherInfo.languageId===0?1:otherInfo.languageId;
    loanSummaryRecord["isPriorCustomer"]=this.props.modeInfo.isEditMode?stateIsPriorCustomer:otherInfo.isPriorCustomer;
    loanSummaryRecord["priorLoanNumber"]=otherInfo.priorLoanNumber;
    loanSummaryRecord["comments"]=this.props.modeInfo.isEditMode?this.state.comments:otherInfo.comments;

    //PropertyType, PropertyId & Questions/Answers
    loanSummaryRecord["propertyType"]=this.state.propertyType;
    loanSummaryRecord["propertyId"]=this.state.propertyId;
    loanSummaryRecord["propertyQuestions"]=this.state.propertyQuestions;
    this.props.updateLoanSummaryRequestJson(loanSummaryRecord);
    let isValid=this.checkLoanSummaryValidation(loanSummaryRecord) && this.state.captchaText !== "";
    if(!isValid) {this.getCaptcha(); this.setState({captchaText: ""}) }
    if(isValid===true && this.state.captchaText!==""){
      this.props.manageLoanSummary(loanSummaryRecord,this.props.captchaText, this.props.captchaImageData.captchaData,this.props.modeInfo.isEditMode)
      .then(responseData => {
        this.props.history.push({
          pathname: "/taxease/thankyou",
        });
      })
    }
    
  }

  checkLoanSummaryValidation = (formDetails) => {
    let isValid=true;

    if(formDetails.isPriorCustomer===true && formDetails.priorLoanNumber===''){
      isValid=false;
    }
    if(formDetails.propertyTypeId===1){
      if(formDetails.ownerList[0].homePhoneNumber==='' || formDetails.ownerList[0].dateOfBirth===null ||
         formDetails.ownerList[0].maritalStatus==='' || formDetails.ownerList[0].maritalStatusId===0){
        isValid=false;
      }
      return isValid;
    }   

    if(formDetails.propertyTypeId===4){
      if(formDetails.ownerList[0].entityName==='' || formDetails.ownerList[0].officePhone===''){
        isValid=false;
      }
      return isValid;
    }   

    if(formDetails.propertyTypeId===1 || formDetails.propertyTypeId===4){
      if(formDetails.ownerList[0].firstName==='' || formDetails.ownerList[0].lastName==='' || formDetails.ownerList[0].email==='' ||
         formDetails.ownerList[0].cellPhone==='' ||
        formDetails.ownerList[0].address==='' || formDetails.ownerList[0].stateId===0 || formDetails.ownerList[0].stateName==='' ||
        formDetails.ownerList[0].cityId===0 || formDetails.ownerList[0].cityName==='' || formDetails.ownerList[0].countyId===0 ||
        formDetails.ownerList[0].countyName==='' || formDetails.ownerList[0].mailingAddressStateId===0 || formDetails.ownerList[0].mailingAddressStateName==='' ||
        formDetails.ownerList[0].mailingAddressCityId===0 || formDetails.ownerList[0].mailingAddressCityName==='' || formDetails.ownerList[0].mailingAddressCountyId===0 ||
        formDetails.ownerList[0].mailingAddressCountyName==='' || formDetails.ownerList[0].mailingAddressStreet===''){
        isValid=false;
      }

      for(let i=0;i<=formDetails.propertyList.length;i++){
        if ( formDetails.propertyList[i].address ==='' || 
             formDetails.propertyList[i].cityId ===0 || formDetails.propertyList[i].cityName ==='' ||
             formDetails.propertyList[i].countyId ===0 || formDetails.propertyList[i].countyName ==='' ||
             formDetails.propertyList[i].stateId ===0 || formDetails.propertyList[i].stateName ==='' ||
             formDetails.propertyList[i].zip ==='' || formDetails.propertyList[i].stateCode ===''){
              isValid=false;
              break;
        }
      }
      return isValid;
    }
  }

  handleReset = () => {
    let borrowerRequestObject= [
      {
          firstName: "", middleName:"", lastName: "", entityName: "", ownerId: "",
          email: "",dateOfBirth: null, maritalStatus: "", maritalStatusId: 0, cellPhone: "",
          homePhoneNumber: "", officePhone: "", aka: "",ssn: "", taxId: "",grossMonthlyIncome: null,
          employer: "", order:0, mailingAddressStreet: "", mailingAddressStateName: "", mailingAddressStateId: 0,
          mailingAddressCityName: "", mailingAddressCityId: 0, mailingAddressCountyName: "",mailingAddressCountyId: 0,
          mailingAddressZip: "", address: "", stateName: "",stateId: 0, cityName: "",cityId: 0, countyName: "",
          countyId: 0, zip: "",
      },
      {
          firstName: "", middleName:"", lastName: "", entityName: "",ownerId: "", email: "",dateOfBirth: null, maritalStatus: "",
          maritalStatusId: 0, cellPhone: "", homePhoneNumber: "", officePhone: "", aka: "", ssn: "", taxId: "",
          grossMonthlyIncome: null, employer: "", order:0
      }];
    
      let currentAddressRequestObject= {
        current:{ address: "", stateName: "", stateId: 0, cityName: "", cityId: 0, countyName: "",countyId: 0, zip: ""},
        mailing:{ address: "", stateName: "", stateId: 0, cityName: "", cityId: 0, countyName: "",countyId: 0, zip: ""}
      }

      let subjectPropertyRequestObject=[
        {
            propertyId: uuidv4(),
            propertyImages: [
              { id: "", imageName: "", bucketName: "", imageUrl: "", imageData: "",isDeleted: false, fileName: "", imageType: ""
              }
            ],
            address: "", stateName: "", stateId: 0, cityName: "", cityId: 0, countyName: "",
            countyId: 0, zip: "", stateCode:"", order: 0, zipCodeErrMsg:""
        }];
      
      let otherInfoRequestObject = {
        propertyTypeId:0, propertyType: "", isMortgageProperty: null, language: "",
        languageId: 0, isPriorCustomer: null, priorLoanNumber: "", comments: "",
      }
      this.props.updateBorrowerRequestJson(borrowerRequestObject);
      this.props.updateAddressRequestJson(currentAddressRequestObject);
      this.props.updateSubjectPropertyRequestJson(subjectPropertyRequestObject);
      this.props.updateOtherInfoRequestJson(otherInfoRequestObject);
      this.setState({captchaText:""});
      window.location.reload();
      this.props.toggleTouched(false);
  }

  getCaptcha = () => {
    this.props.getCaptchaImage();
  }

  setCaptchaText(e) {
    this.setState({
      captchaText:e.target.value
    })
    this.props.updateCaptchaText(e.target.value);
  }

  getFilesCallback = data => {
    this.setState({ files: data });
    return true;
  }

  loaderCallBackFunc = (status) =>{
    this.setState({showLoader : status});
  }

  isFileUploadedFun = (status) =>{
    //isFileUploaded = status
  }

  filesToDeleteCallBack = (filesArray) =>{
    filesToBeDelete.push(filesArray);
    this.props.deleteUploadedDocument({ documentFiles: filesToBeDelete }).then(res => {
      //this.commonFunCalls(newDocument,documentArray);
    }).catch(err => {
      this.setState({showLoader : false});
      this.props.setSnackbar('error', 'Something went wrong!');
    })
  }

  enableUploadButton = (childData) =>{
    this.setState({uploadBtnStatus : childData})
  }

  setSnackbar = (variant, message) => {
    this.setState({
      snackbarMessage: message,
      snackbarVariant: variant
    })
    setTimeout(() => {
      this.setState({
        snackbarMessage: '',
        snackbarVariant : '',
      })
    }, 2000)
    return true;
  };

  updateUploadedFiles = (url, file) => {
    let files = [...this.state.uploadedFiles];
    files.push({url: url, file: file});
    this.setState({
      uploadedFiles: files
    })
  }

  updateUploadedImages = (url, file) => {
    let files = [...this.state.uploadedImages];
    files.push({url: url, file: file});
    this.setState({
      uploadedImages: files
    })
  }

  //---------HANDLE AUTHORIZED SIGNOR----------------------//

  handleAuthorizedChange = (e) => {
    let fieldName = e.target.id;
    let val = e.target.value;
    let borrowerId=Guid.create();
    let updatedData={};
    let newJson=[];
    if(this.props.modeInfo.isEditMode){
      updatedData = this.props.editableBorrowerRequestJson[0];
      newJson = [...this.props.editableBorrowerRequestJson];
      newJson[0] = { ...updatedData, [fieldName]: val };
      this.props.updateEditableBorrowerRequestJson(newJson);
    }else{
      updatedData = this.props.borrowerRequestJson[0]
      newJson = [...this.props.borrowerRequestJson];
      newJson[0] = { ...updatedData, [fieldName]: val,"order": 0,"ownerId": borrowerId.value };
      this.props.updateBorrowerRequestJson(newJson);
    }
  }

  handleCommercialEntityChange = (e) => {
    let nam = e.target.id;
    let val = e.target.value;
    let borrowerId=Guid.create();
    let updatedData={};
    let newJson=[];
    if(this.props.modeInfo.isEditMode){
      updatedData = this.props.editableBorrowerRequestJson[0];
      newJson = [...this.props.editableBorrowerRequestJson];
      newJson[0] = { ...updatedData, [nam]: val };
      this.props.updateEditableBorrowerRequestJson(newJson);
    }else{
      updatedData = this.props.borrowerRequestJson[0]
      newJson = [...this.props.borrowerRequestJson];
      newJson[0] = { ...updatedData, [nam]: val,"order": 0,"ownerId": borrowerId.value };
      this.props.updateBorrowerRequestJson(newJson);
    }
  }

  //---------END OF AUTHORIZED SIGNOR----------------------//

  setDataInEditMode = (response) => {
    let borrowerRecord=[...response.ownerList];
    if(borrowerRecord.length>1 && borrowerRecord[0].firstName!==""){
      this.setState({
        displayBorrower:true,
        displayBorrowerButton:false,
        displayDeleteButton:true,
      })
    }
    this.props.updateBorrowerRequestJson(borrowerRecord);
    this.props.updateEditableBorrowerRequestJson(borrowerRecord);
    let subjectPropertyRequestJson=[...response.propertyList];
    let propertyListWithShowFields=subjectPropertyRequestJson.map(obj=> ({ ...obj, showFields: true }))
    
    this.props.updateSubjectPropertyRequestJson(propertyListWithShowFields);

    //updated current and mailing address in edit mode
    let currentAddressObj={...this.props.currentAddressRequestJson}
    currentAddressObj.current.address= borrowerRecord[0].address;
    currentAddressObj.current.stateName= borrowerRecord[0].stateName;
    currentAddressObj.current.stateId= borrowerRecord[0].stateId;
    currentAddressObj.current.stateCode= borrowerRecord[0].stateCode;
    currentAddressObj.current.cityName= borrowerRecord[0].cityName;
    currentAddressObj.current.cityId= borrowerRecord[0].cityId;
    currentAddressObj.current.countyName= borrowerRecord[0].countyName;
    currentAddressObj.current.countyId= borrowerRecord[0].countyId;
    currentAddressObj.current.zip= borrowerRecord[0].zip;

    currentAddressObj.mailing.address= borrowerRecord[0].mailingAddressStreet;
    currentAddressObj.mailing.stateName= borrowerRecord[0].mailingAddressStateName;
    currentAddressObj.mailing.stateId= borrowerRecord[0].mailingAddressStateId;
    currentAddressObj.mailing.stateCode= borrowerRecord[0].mailingAddressStateCode;
    currentAddressObj.mailing.cityName= borrowerRecord[0].mailingAddressCityName;
    currentAddressObj.mailing.cityId= borrowerRecord[0].mailingAddressCityId;
    currentAddressObj.mailing.countyName= borrowerRecord[0].mailingAddressCountyName;
    currentAddressObj.mailing.countyId= borrowerRecord[0].mailingAddressCountyId;
    currentAddressObj.mailing.zip= borrowerRecord[0].mailingAddressZip;
    this.props.updateAddressRequestJson(currentAddressObj);

    let otherInfoObject={...this.props.otherInfoRequestJson};
    if(response.isMortgageProperty!==null){
      otherInfoObject['isMortgageProperty']=response.isMortgageProperty?"Yes":"No";
      this.setState({
        isMortgageProperty:response.isMortgageProperty?"Yes":"No"
      })
    }
    if(response.isPriorCustomer!==null){
      otherInfoObject['isPriorCustomer']=response.isPriorCustomer?"Yes":"No";
      this.setState({
        isPriorCustomer:response.isPriorCustomer?"Yes":"No"
      })
    }
    // else{
    //   otherInfoObject['isPriorCustomer']="No";
    //   this.setState({
    //     isPriorCustomer:"No"
    //   })
    // }
    otherInfoObject['language']=response.language
    otherInfoObject['propertyType']=response.propertyType
    otherInfoObject['priorLoanNumber']=response.priorLoanNumber;

    if(response.priorLoanNumber!==null){
      let loanList=response.priorLoanNumber.split(',');
      let updatedLoanList=[];
      for(let i=0;i<loanList.length;i++){
        let obj={loanNo:loanList[i]};
        updatedLoanList.push(obj);
      }
      this.setState({
        loanNumberList:updatedLoanList
      })
    }
    otherInfoObject['comments']=response.comments
    this.props.updateOtherInfoRequestJson(otherInfoObject);

    let documentList=[...response.loanDocumentList];
    this.props.updateLoanDocumentListRequestJson(documentList)
  }

  render() {
    const {comments, isPriorCustomer, isMortgageProperty, language, priorLoanNumber} = this.props.otherInfoRequestJson;
    return (
      <Fragment>
        <Header></Header>
        <SubHeader></SubHeader>
        <div className="application-detail-container loan-pg-inner-wrapper">
          {this.state.loanDetails !== null && this.state.loanDetails.propertyType === "Commercial" && this.state.loanDetails.propertyOwnedBy === "Company"
            ? <div><CommercialEntityOwner {...this.props}
               isEditMode={this.props.modeInfo.isEditMode}
              authorizedSignorInfo={this.props.borrowerRequestJson[0]}
              editCommEntity={this.props.editableBorrowerRequestJson[0]}
              handleCommercialEntityChange={(event)=>this.handleCommercialEntityChange(event)}
            />
            <AuthorizedSignor {...this.props}
              editAuthInfo={this.props.editableBorrowerRequestJson[0]}
              isEditMode={this.props.modeInfo.isEditMode}
              authorizedSignorInfo={this.props.borrowerRequestJson[0]} 
              handleAuthorizedChange={(event)=>this.handleAuthorizedChange(event)}
            /></div>
            : <BorrowerDetails 
                {...this.props}
                key={this.props.modeInfo.isEditMode}
                isEditMode={this.props.modeInfo.isEditMode}
                info={'borrower'} 
                heading={'Borrower'}
                borrowerInfo={this.props.borrowerRequestJson[0]} 
                editBorrowerInfo={this.props.editableBorrowerRequestJson[0]}
                displayBorrower2={() => this.displayBorrowerButton()} 
                handleBorrowerChange={(event,info)=>this.handleBorrowerChange(event,info)} 
                handleDateInForm={(date,info,fieldName,age)=>this.handleDateInForm(date,info,fieldName,age)} 
                displayBorrowerButton={this.state.displayBorrowerButton}  
                displayDeleteButton={false}
                martialStatusList={this.props.martialStatusList}
              />}
        
        {this.state.displayBorrower ? 
            <BorrowerDetails 
                {...this.props}
                info={'coborrower'} 
                heading={'Co-Borrower'}
                isEditMode={this.props.modeInfo.isEditMode}
                borrowerInfo={this.props.borrowerRequestJson[1]} 
                editBorrowerInfo={this.props.editableBorrowerRequestJson[1]}
                handleBorrowerChange={(event,info)=>this.handleBorrowerChange(event,info)}
                handleDateInForm={(date,info,fieldName,age)=>this.handleDateInForm(date,info,fieldName,age)}
                displayBorrowerButton={this.state.displayBorrowerButton}
                displayDeleteButton={this.state.displayDeleteButton}
                displayDeleteAction={() => this.displayDeleteAction()} 
                martialStatusList={this.props.martialStatusList}
            /> : null 
        
          }
          {
            this.props.subjectPropertyRequestJson.length > 0 ? this.props.subjectPropertyRequestJson.map((item,index) => {
              return (
                <Grid fullWidth container>
                  <SubjectPropertyDetails 
                    {...this.props}
                    propertyInfo={item}
                    key={`subjectProperty_${this.props.propertyIndex}`}
                    propertyIndex={index}
                    isEditMode={this.props.modeInfo.isEditMode}
                    displayProperty={() => this.addProperty()} 
                    handleDeletePropertyButton={(indexVal) => this.handleDeletePropertyButton(indexVal)}
                    handleSubjectPropertyChange={(id,value,propIndex)=>this.handleSubjectPropertyChange(id,value,propIndex)}
                    handleSubjectPropertySwitch={(id,value,propIndex)=>this.handleSubjectPropertySwitch(id,value,propIndex)}
                    handleMailingSwitchChange={(id,value,propIndex)=>this.handleMailingSwitchChange(id,value,propIndex)}
                  />
                </Grid>
              )
            }) : null
          }
          <Grid fullWidth container>
             <PropertyAddressDetails {...this.props}
              sectionName={'curAddress'}
              heading="Current Address"
              key={`currentAdd_${this.props.propertyIndex}`}
              isEditMode={this.props.modeInfo.isEditMode}
              isCurrentAddressReadOnly={this.state.isCurrentAddressReadOnly}
              addressInfo={this.props.currentAddressRequestJson.current}
              stateList={this.props.stateList}
              cityList={this.props.cityList}
              countyList={this.props.countyList}
              handleAddressChange={(fieldName,value,sectionName)=>this.handleAddressChange(fieldName,value,sectionName)}
              handleStateChange={(fieldName,value,secName)=>this.handleStateChange(fieldName,value,secName)}
             />
          </Grid>

          <Grid fullWidth container>
              <PropertyAddressDetails {...this.props}
                sectionName={'propAddress'}
                heading="Mailing Address"
                key={`mailingAdd_${this.props.propertyIndex}`}
                isEditMode={this.props.modeInfo.isEditMode}
                addressInfo={this.props.currentAddressRequestJson.mailing}
                isMailingAddressReadOnly={this.state.isMailingAddressReadOnly}
                stateList={this.props.stateList}
                cityList={this.props.mailingCityList}
                countyList={this.props.mailingCountyList}
                handleAddressChange={(fieldName,value,sectionName)=>this.handleAddressChange(fieldName,value,sectionName)}
                handleStateChange={(fieldName,value,secName)=>this.handleStateChange(fieldName,value,secName)}
              />
          </Grid>
          
          <Grid fullWidth container>
            <div className="detail-section loan-pg padd-10">
              <div class="heading-section">
                <div class="heading-title">
                  <Typography variant="h5" gutterBottom>
                    Other Information
                  </Typography>
                </div>
              </div>
              <Grid fullWidth container class="loan-pg-sec">
                <Paper fullWidth elevation={2}>
                  <Grid container spacing={8}>

                    <Grid item xs={4}>
                    {
                      this.props.modeInfo.isEditMode && isMortgageProperty!==null?
                        <TextField
                          label="Is there a mortgage on the property?" 
                          value={isMortgageProperty}
                          disabled={true}
                          fullWidth
                        /> :
                        <SelectDropDown 
                          id="isMortgageProperty" 
                          component="address" 
                          label="Is there a mortgage on the property?" 
                          fieldName='isMortgageProperty' 
                          initialValue={''} 
                          dropDownDataList={[{isMortgageProperty: 'Yes',extraField:1, isMortgagePropertyId: 1 },{isMortgageProperty: 'No',extraField:0,isMortgagePropertyId: 0}]} 
                          setSelectedValue={(e) => this.handleOtherInfoChange(e,"isMortgageProperty")} />
                    }
                    </Grid>

                    <Grid item xs={4}>
                      {
                        this.props.modeInfo.isEditMode ? 
                          <TextField
                            label="Language" 
                            value={language}
                            disabled={true}
                            fullWidth
                          /> :
                          <SelectDropDown 
                            id="languageId" 
                            component="address" 
                            label="Language" 
                            fieldName='languageId' 
                            initialValue={{ languageId: this.state.selectedLanguageRecord.languageId}}
                            dropDownDataList={this.props.languageList!==undefined && this.props.languageList.length>0 ? this.props.languageList:[]} 
                            setSelectedValue={(e) => this.handleOtherInfoChange(e,"languageId")} />
                      }
                    </Grid>
                  </Grid>

                  <Grid container spacing={8}>
                    <Grid item xs={4}>
                    {
                      this.props.modeInfo.isEditMode && isPriorCustomer!==null ? 
                        <TextField
                          label="Are you a prior customer?" 
                          value={isPriorCustomer} fullWidth
                          disabled={true}
                        />: 
                        <SelectDropDown 
                          id="isPriorCustomer" 
                          component="address" 
                          label="Are you a prior customer?" 
                          fieldName='isPriorCustomerId' 
                          initialValue={''} 
                          dropDownDataList={[{isPriorCustomer: 'Yes',extraField:1, isPriorCustomerId: 1 },{isPriorCustomer: 'No',extraField:0,isPriorCustomerId: 0}]} 
                          setSelectedValue={(e) => this.handleOtherInfoChange(e,"isPriorCustomer")} />
                    }
                    </Grid>
                  </Grid>
                  {this.state.isSelect === 1 && (isPriorCustomer===true || isPriorCustomer==="Yes" || this.state.isPriorCustomer==="Yes") ? (
                    <Grid container spacing={4}>
                    {
                      this.state.loanNumberList.map((item,index) => {
                        return (
                          <div class="add-parent-sec">
                            <Grid item xs={4}>
                              <TextField
                                value={this.state.loanNumberList[index].loanNo}
                                onChange={(e) => this.handleOtherInfoChange(e,"loanNumber",index)}
                                fullWidth
                                key={`loanNumber_${index}`}
                                id="standard-required"
                                label="Add Loan Number"
                                disabled={this.props.modeInfo.isEditMode && priorLoanNumber!==null}
                                required
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {index+1}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              {!(!this.props.isFormTouched || (this.state.loanNumberList[index].loanNo !== "" && this.props.isFormTouched)) ? <Grid item xs={12}>
                                <Typography gutterBottom>
                                  <span className="redTxt">Loan number is required</span>
                                </Typography>
                              </Grid> : null}
                            </Grid>
                            <Grid item xs={4}>
                              {index === 0 ?
                              <div class="add-sec">
                                <Fab disabled={this.state.loanNumberList[index].loanNo==="" || (this.props.modeInfo.isEditMode && priorLoanNumber!==null)} size="small" color="primary" aria-label="add">
                                  <AddIcon onClick={this.addAnotherLoanNumber} />
                                </Fab>
                                <Button disabled={this.state.loanNumberList[index].loanNo==="" || (this.props.modeInfo.isEditMode && priorLoanNumber!==null)} onClick={this.addAnotherLoanNumber}>Add Another Loan</Button>
                                </div> :
                                <div class="add-sec">
                                  <Tooltip title="Delete" arrow>
                                  <Fab disabled={this.props.modeInfo.isEditMode && priorLoanNumber!==null} size="small" color="primary" aria-label="delete">
                                    <DeleteIcon onClick={() => this.removeLoanNumber(index)} />
                                  </Fab>
                                  </Tooltip>
                                </div>}
                            </Grid>
                          </div>)})
                    }
                    </Grid>) : ("")}

                  <Grid container spacing={8}>
                    <Grid item xs={8}>
                      <TextField
                        id="comments" multiline fullWidth
                        label="Comments" maxrows={4}
                        value={this.props.modeInfo.isEditMode?this.state.comments:comments}
                        onChange={(e) => this.handleOtherInfoChange(e,"comments")}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </div>
          </Grid>

          <Grid fullWidth container>
            <div className="detail-section loan-pg padd-10">
              <div class="heading-section">
                <div class="heading-title">
                  <Typography variant="h5" gutterBottom>
                    Upload Pictures
                  </Typography>
                </div>
              </div>
              <Grid fullWidth container class="loan-pg-sec">
                <Paper fullWidth elevation={2}>
                  <Grid container spacing={8}>
                    <Grid item xs={12} className="no-bottom-spcs">
                      <Typography variant="subtitle1"  gutterBottom>
                        Upload subject property images:
                      </Typography>
                    </Grid>

                    <Grid item xs={12} className="img-list-sec">
                      <div className="upload-img-sec">
                      <ImageDropzone
                          //readonly={this.props.readOnly || isViewMode} 
                          isEditMode={this.props.modeInfo.isEditMode}  //for edit mode
                          imageUploadCallBackFunction={this.imageUploadCallBackFunction} 
                          imageUploadFlag={this.state.imageUploadFlag} 
                          imageList={this.props.subjectPropertyRequestJson[0].propertyImages} //for edit mode
                          {...this.props} 
                          uploadFileCallBackFun={this.uploadFileCallBackFunction} 
                          resetCancelBtnClick={this.resetCancelBtnClick} 
                          resetAddOwnerBtnClick={this.resetAddOwnerBtnClick} 
                          taxId={this.state.taxId} 
                          addPropertyBtn={this.state.addPropertyBtn} 
                          cancelBtnClick={this.state.cancelBtnClick} 
                          propertyId={this.propertyId} 
                          loanId={this.state.loanId}
                          setSnackbar={this.setSnackbar}
                          updateUploadedImages={this.updateUploadedImages}
                          loaderCallBackFunc = {this.loaderCallBackFunc}
                      />
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </div>
          </Grid>

          <Grid fullWidth container>
            <div className="detail-section loan-pg padd-10">
              <div class="heading-section">
                <div class="heading-title">
                  <Typography variant="h5" gutterBottom>
                    Upload Document
                  </Typography>
                </div>
              </div>
              <Grid fullWidth container class="loan-pg-sec">
                <Paper fullWidth elevation={2}>
                  <Grid container spacing={8}>
                    <Grid item xs={12} className="doc-list-sec">
                      <UploadzoneArea 
                        isEditMode={this.props.modeInfo.isEditMode} //at edit mode
                        className="upload-doc-list-cls" 
                        getFilesCallback={this.getFilesCallback}
                        document={document}
                        fileName={document.fileName}
                        fileId={document.uploadId}
                        file={this.file}
                        loanId={this.state.loanId}
                        loanDocumentList={this.props.loanDocumentListRequestJson} //at edit mode
                        {...this.props}
                        enableUploadButton = {this.enableUploadButton}
                        loaderCallBackFunc = {this.loaderCallBackFunc}
                        isFileUploadedFun = {this.isFileUploadedFun}
                        filesToDeleteCallBack = {this.filesToDeleteCallBack}
                        setSnackbar={this.setSnackbar}
                        updateUploadedFiles={this.updateUploadedFiles}
                        uploadedFiles={this.state.uploadedFiles}
                      />
                  </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </div>
          </Grid>

          <Grid fullWidth container>
            <div className="detail-section loan-pg padd-10">
              <div class="heading-section">
                <div class="heading-title">
                  <Typography variant="h5" gutterBottom>
                    Application Submission
                  </Typography>
                </div>
              </div>
              <Grid fullWidth container class="loan-pg-sec">
                <Paper fullWidth elevation={2}>
                  <Grid container spacing={8}>
                    <Grid item xs={12} className="captcha-sec-title">
                      <Typography variant="subtitle1" gutterBottom>
                        Fill the captcha code in the box below
                      </Typography>
                    </Grid>
                    <Grid item xs={4} className="grid-row cus-captcha-cls">
                        <TextField 
                        className="captcha-field" 
                        id="captcha" 
                        required
                        name="captcha" error={this.props.error}
                        label="Enter Captcha" 
                        value={this.state.captchaText}
                        onChange={(e) => this.setCaptchaText(e)}/>

                       {!(!this.props.isFormTouched || (this.state.captchaText !== "" && this.props.isFormTouched)) ? <Grid item xs={4}>
                        <div className="captcha-msg-disp">
                          <span className="redTxt">Captcha is required</span>
                        </div>
                      </Grid> : null}

                        <FormControl className='captcha-box'>
                            <img alt='' src={this.props.captchaImageData.newCaptchaImage} ></img>
                        </FormControl>
                        <Box className='icon-box'>
                            <Tooltip enterTouchDelay={10} leaveTouchDelay={2000} title="Refresh Captcha" arrow>
                                <img alt='' src={RefreshIcon} onClick={this.getCaptcha}></img>
                            </Tooltip>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className="cus-btn-grp">
                      <Button variant="contained" color="primary" onClick={this.handleSubmitApplication}>
                        SUBMIT APPLICATION
                      </Button>
                      <Button onClick={this.handleReset}>RESET</Button>
                      <div>
                          <span className="redTxt">Please add your total tax due estimate to the Comment Section above.</span>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </div>
          </Grid>
        </div>
        <LoaderComponent loading={this.props.loading || this.state.showLoader} />
        <CustomSnackBarContainer />
      </Fragment>
    );
  }
}


export default withRouter(ApplicationDetails);
