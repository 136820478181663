//contains all https request
import axios from 'axios';
import {getRefreshToken} from '../common/requireAuth';
import FileSaver from 'file-saver';
//import logService from './logService';
import { v4 as uuidv4 } from 'uuid';

axios.interceptors.request.use(request => {
  //logService.addAPIRequestLog(request);
  return request
})

axios.interceptors.response.use( (response) => {
    // Return a successful response back to the calling service
    //logService.addAPIResponseLog(response);
    return response;
    }, (error) => {
    // Return any error which is not due to authentication back to the calling service

    if (error.response.status === 400) {
      //logService.addAPIErrorLog(error);
      //if(error.response)
      return new Promise((resolve, reject) => {
        reject(error.response.data.error);
      if(!error.response.data.errorMessage) {
        var base_url = window.location.origin;
        window.location.href = base_url;
      }
    
        // var host = window.location.host;
        // var pathArray = window.location.pathname.split( '/' );
      });
    }

    if (error.response.status !== 401) {
      //logService.addAPIErrorLog(error);
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    // Logout user if token refresh didn't work or user is disabled
    if (error.config.url === '/api/token/refresh' || error.response.message === 'Account is disabled.') {
      //TokenStorage.clear();
      //router.push({ name: 'root' });
      //logService.addAPIErrorLog(error);
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    // Try request again with new token
    return getRefreshToken()
      .then((token) => {
        // New request with new token
        const config = error.config;
        config.headers['token'] = token;

        return new Promise((resolve, reject) => {
          axios.request(config).then(response => {
            resolve(response);
          }).catch((err) => {
            //logService.addAPIErrorLog(error);
            reject(err);
          })
        });

      })
      .catch((err) => {
        //logService.addAPIErrorLog(error);
      	Promise.reject(err);
      });
  });


/**
* action for http request for get
* @param {*} config
*/
export function doActionGet(config, RedirectURL) {
  if (config) {
    return axios({
      url: `${RedirectURL}/` + config.url,
      method: 'GET',
      headers: {
        token: getRefreshToken(),
        'x-correlation-id': uuidv4()
      }
    });
  }
}

export function doActionGetWithCaptcha(config, RedirectURL, captchaText, captchaData) {
  if(config) {
    return axios({
      url: `${RedirectURL}/` + config.url,
      method: 'GET',
      headers: {
        captcha: captchaText,
        captchaData: captchaData,
        token: getRefreshToken(),
        'x-correlation-id': uuidv4()
      }
    });
  }
}

export function doActionPostWithCaptcha(config,RedirectURL, captchaText, captchaData, corId = undefined) {
  if (config) {
      return axios({
          url: `${RedirectURL}/` + config.url,
          method: 'POST',
          data: config.data,
          headers: {
            captcha: captchaText,
            captchaData: captchaData,
            token: getRefreshToken(),
            'Content-Type': 'application/json',
            'x-correlation-id': corId !== undefined ? corId : uuidv4()
          }           
      });
  }
}


/**
* action for http request for post
* @param {*} config
*/
export function doActionPost(config,RedirectURL, corId = undefined) {
    if (config) {
        return axios({
            url: `${RedirectURL}/` + config.url,
            method: 'POST',
            data: config.data,
            headers: {
              token: getRefreshToken(),
              'Content-Type': 'application/json',
              'x-correlation-id': corId !== undefined ? corId : uuidv4()
            }           
        });
    }
}

/**
* action for http request for put
* @param {*} config
*/
export function doActionPut(config,RedirectURL, corId = undefined) {
    if (config) {
        return axios({
            url: `${RedirectURL}/` + config.url,
            method: 'PUT',
            data: config.data,
            headers: {
              token: getRefreshToken(),
              'Content-Type': 'application/json',
              'x-correlation-id': corId !== undefined ? corId : uuidv4()
            } 
        });
    }
}

export function doActionPutWithCaptcha(config,RedirectURL, captchaText, captchaData, corId = undefined) {
  if (config) {
      return axios({
          url: `${RedirectURL}/` + config.url,
          method: 'PUT',
          data: config.data,
          headers: {
            captcha: captchaText,
            captchaData: captchaData,
            token: getRefreshToken(),
            'Content-Type': 'application/json',
            'x-correlation-id': corId !== undefined ? corId : uuidv4()
          }    
      });
  }
}

/**
* action for http request for post
* @param {*} config
*/
export function doActionPatch(config,RedirectURL, corId = undefined) {
    if (config) {
        return axios({
            url: `${RedirectURL}/` + config.url,
            method: 'PATCH',
            data: config.data,
            headers: {
              token: getRefreshToken(),
              'Content-Type': 'application/json',
              'x-correlation-id': corId !== undefined ? corId : uuidv4()
            }
        });
    }
}

/**
* action for http request for delete
* @param {*} config
*/
export function doActionDelete(config,RedirectURL, corId = undefined) {
    if (config) {
        return axios({
            url: `${RedirectURL}/` + config.url,
            method: 'DELETE',
            data: config.data,
            headers: {
              token: getRefreshToken(),
              'Content-Type': 'application/json',
              'x-correlation-id': corId !== undefined ? corId : uuidv4()
            }
        });
    }
}


export function doActionGetDownload(config, fileName) {
	if (config) {
		axios.get(config, {
			responseType: 'blob',
		}).then(response => {
            //const filename = fileName ? fileName : config.Path.replace(/^.*[\\\/]/, '');
            if(response !== undefined) {
                FileSaver.saveAs(response.data, fileName);
            }
			return response
		});
	}
}

/**
 * action for http request to hit upload url of s3 bucket
 */
 export function doActionHitUploadUrl(configUrl, corId = undefined) {
	if (configUrl) {
		return axios({
			url: configUrl.uploadUrl,
			method: 'PUT',
			data: configUrl.file,
			headers: {
				'Content-Type': '',
				"Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                'x-correlation-id': corId !== undefined ? corId : uuidv4()
			}
		})
	}
}
