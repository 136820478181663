import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import LogoSvg from "../../assets/logo.svg";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

export class Header extends Component {
  backToHome = () => {
    this.props.history.push({
      pathname: "/taxease/",
    });
  };

 


  render() {


    return (
      <AppBar 
        className="header"
        position="sticky"
      >
        <Toolbar className="tool-bar">
          <Box className="main-logo">
            <img
              onClick={this.backToHome}
              className="logo-img"
              src={LogoSvg}
              alt="Logo"
            />
            <Typography variant="h6">Loan Application Portal</Typography>
          </Box>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withRouter(Header);
