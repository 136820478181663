import { connect } from 'react-redux';
import ApplicationDetails from  '../components/applicationDetails';

import {
	showLoader,
	hideLoader
} from '../actions/loaderAction';

import { snackBarMessages } from '../const/commonConst';

import {
	showSnackbar,
	errorSnackbar
} from '../actions/customSnackBarAction';

import {
	updateAddressRequestJson,
	updateSubjectPropertyRequestJson,
	updateBorrowerRequestJson,
	updateOtherInfoRequestJson,
	updateLoanSummaryRequestJson,

	updateMartialStatusList,
	updateStateList,
	updateLanguageList,
	updateCityList,
	updateCountyList,
	updateMailingCityList,
	updateMailingCountyList,
	toggleTouched,
	getAllData,
	getAllDataSuccess,
	getAllDataFailure,
	getCaptchaImage,
	getCaptchaImageSuccess,
	getCaptchaImageFailure,
	updateCaptchaText,
	manageLoanSummary,
	manageLoanSummarySuccess,
	manageLoanSummaryFailure,
	fetchDownloadDocumentUrl,
	fetchDownloadDocumentUrlSuccess,
	fetchDownloadDocumentUrlFailure,
	fetchUploadDocumentUrl,
	fetchUploadDocumentUrlSuccess,
	fetchUploadDocumentUrlFailure,
	deleteUploadedDocument,
	deleteUploadedDocumentSuccess,
	deleteUploadedDocumentFailure,
	fetchUploadDetails,
	fetchUploadDetailsSuccess,
	fetchUploadDetailsFailure,
	postImageDetails,
	postImageDetailsSuccess,
	postImageDetailsFailure,

	updateEditableBorrowerRequestJson,
	updateLoanDocumentListRequestJson

} from '../actions/applicationDetailsAction';

export const mapStateToProps = (state) => {
	
	return {
		currentAddressRequestJson: state.applicationDetails.currentAddressRequestJson,
		subjectPropertyRequestJson:state.applicationDetails.subjectPropertyRequestJson,
		otherInfoRequestJson:state.applicationDetails.otherInfoRequestJson,
		loanSummaryRequestJson:state.applicationDetails.loanSummaryRequestJson,
		borrowerRequestJson:state.applicationDetails.borrowerRequestJson,
		martialStatusList: state.applicationDetails.martialStatusList,
		stateList: state.applicationDetails.stateList,
		cityList: state.applicationDetails.cityList,
		countyList: state.applicationDetails.countyList,
		mailingCityList: state.applicationDetails.mailingCityList,
		mailingCountyList: state.applicationDetails.mailingCountyList,
		allPortalData:state.applicationDetails.allPortalData,
		languageList:state.applicationDetails.languageList,
		isFormTouched: state.applicationDetails.isFormTouched,
		captchaImageData: state.applicationDetails.captchaImageData,
		captchaText:state.applicationDetails.captchaText,
		loanDetails: state.dashboard.loanDetails,
		uploadDetails: state.applicationDetails.uploadDetails,
		loading: state.loader.status,
		error:state.applicationDetails.error,
		message:state.applicationDetails.message,
		loanSummaryData:state.dashboard.applicationDetails.entity,
		modeInfo:state.dashboard.modeInfo,
		editableBorrowerRequestJson:state.applicationDetails.editableBorrowerRequestJson,
		loanDocumentListRequestJson:state.applicationDetails.loanDocumentListRequestJson,
	}
}

export const mapDispatchToProps = (dispatch) => {
	return {
		updateAddressRequestJson: (data) => {
			dispatch(updateAddressRequestJson(data))
		},
		updateSubjectPropertyRequestJson: (data) => {
			dispatch(updateSubjectPropertyRequestJson(data))
		},
		updateBorrowerRequestJson: (data) => {
			dispatch(updateBorrowerRequestJson(data))
		},
		updateMartialStatusList: (data) => {
			dispatch(updateMartialStatusList(data))
		},
		updateOtherInfoRequestJson : (data) => {
			dispatch(updateOtherInfoRequestJson(data))
		},
		updateLoanSummaryRequestJson : (data) => {
			dispatch(updateLoanSummaryRequestJson(data))
		},
		updateStateList: (data) => {
			dispatch(updateStateList(data))
		},
		updateLanguageList: (data) => {
			dispatch(updateLanguageList(data))
		},
		updateCityList: (data) => {
			dispatch(updateCityList(data))
		},
		updateCountyList: (data) => {
			dispatch(updateCountyList(data))
		},
		updateMailingCityList: (data) => {
			dispatch(updateMailingCityList(data))
		},
		updateMailingCountyList: (data) => {
			dispatch(updateMailingCountyList(data))
		},

		getAllData: (reqObj) => {
			return new Promise((resolve, reject) => {
				try {
					dispatch(showLoader())
					dispatch(getAllData(reqObj))
						.then((response) => {
							dispatch(getAllDataSuccess(response.payload.data.entity))
							resolve(response.payload.data.entity)
							dispatch(hideLoader())
						})
						.catch((error) => {
							dispatch(getAllDataFailure(error))
							dispatch(hideLoader())
						})
				} catch (error) {
					reject(error)
				}
			})
		},
		toggleTouched: (flag) => {
			dispatch(toggleTouched(flag));
        },
		
		getCaptchaImage: () => {
			return new Promise((resolve, reject) => {
				try {
					dispatch(showLoader())
					dispatch(getCaptchaImage())
						.then((response) => {
							dispatch(getCaptchaImageSuccess(response.payload.data))
							resolve(response.payload.data)
							dispatch(hideLoader())
						})
						.catch((error) => {
							dispatch(getCaptchaImageFailure(error))
							dispatch(hideLoader())
						})
				} catch (error) {
					reject(error)
				}
			},
		)},
		updateCaptchaText: (data) => {
			dispatch(updateCaptchaText(data));
		},
		manageLoanSummary: (request,captchaText,captchaData, isEditMode) => {
			return new Promise((resolve, reject) => {
				try {
					dispatch(showLoader());
					dispatch(manageLoanSummary(request,captchaText,captchaData, isEditMode))
						.then((response) => {
							dispatch(manageLoanSummarySuccess(response.payload.data))
							dispatch(showSnackbar(snackBarMessages.loanSubmissionSuccess))
							resolve(response.payload.data)
							dispatch(hideLoader())
						})
						.catch((error) => {
							dispatch(manageLoanSummaryFailure(error))
							if(error==="invalidCaptcha"){
								dispatch(errorSnackbar(snackBarMessages.captchaError))
							}else{
								dispatch(errorSnackbar(snackBarMessages.error))
							}
							dispatch(hideLoader())
						})
				} catch (error) {
					reject(error)
				}
			})
		},
		fetchUploadDocumentUrl: reqObj => {
            return new Promise((resolve, reject) => {
                try {
                    dispatch(fetchUploadDocumentUrl(reqObj))
                        .then(response => {
                            dispatch(
                                fetchUploadDocumentUrlSuccess(response.payload)
                            );
                            //dispatch(showLoader(false));
                            resolve(response.payload);
                        })
                        .catch(error => {
                            dispatch(fetchUploadDocumentUrlFailure(error));
                            //dispatch(showLoader(false));
                        });
                } catch (error) {
                    reject(error);
                }
            });
        },
        fetchDownloadDocumentUrl: (finalUrl, path) => {
            return new Promise((resolve, reject) => {
                try {
                    dispatch(fetchDownloadDocumentUrl(finalUrl, path))
                        .then(response => {
                            dispatch(
                                fetchDownloadDocumentUrlSuccess(response.payload)
                            );
                            //dispatch(showLoader(false));
                            resolve(response.payload);
                        })
                        .catch(error => {
                            dispatch(fetchDownloadDocumentUrlFailure(error));
                            //dispatch(showLoader(false));
                        });
                } catch (error) {
                    reject(error);
                }
            });
        },
		deleteUploadedDocument: (reqJson) => {
            return new Promise((resolve, reject) => {
                try {
                    dispatch(deleteUploadedDocument(reqJson))
                        .then(response => {
                            dispatch(
                                deleteUploadedDocumentSuccess(response.payload)
                            );
                            //dispatch(showLoader(false));
                            resolve(response.payload);
                        })
                        .catch(error => {
                            dispatch(deleteUploadedDocumentFailure(error));
                            //dispatch(showLoader(false));
                            reject(error);
                        });
                } catch (error) {
                    reject(error);
                }
            });
        },
		fetchUploadDetails: (loanId) => {
            return new Promise((resolve, reject) => {
                try {
                    dispatch(fetchUploadDetails(loanId))
                        .then(response => {
                            dispatch(
                                fetchUploadDetailsSuccess(response.payload)
                            );
                            //dispatch(showLoader(false));
                            resolve(response.payload);
                        })
                        .catch(error => {
                            dispatch(fetchUploadDetailsFailure(error));
                            //dispatch(showLoader(false));
                        });
                } catch (error) {
                    reject(error);
                }
            });
        },
		postImageDetails: (reqObj) => {
			return new Promise((resolve, reject) => {
				try {
					//dispatch(showLoader())
					dispatch(postImageDetails(reqObj))
						.then((response) => {
							dispatch(postImageDetailsSuccess(response.payload.data.entity))
							resolve(response.payload.data.entity)
							//dispatch(hideLoader())
						})
						.catch((error) => {
							dispatch(postImageDetailsFailure(error))
							//dispatch(hideLoader())
						})
				} catch (error) {
					reject(error)
				}
			})
		},

		updateEditableBorrowerRequestJson: (data) => {
			dispatch(updateEditableBorrowerRequestJson(data))
		},

		updateLoanDocumentListRequestJson: (data) => {
			dispatch(updateLoanDocumentListRequestJson(data))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDetails)
